import Vue from 'vue'
import VueRouter from 'vue-router'
import NewEmployee from '../views/employees/new'
import SearchEmployee from '../views/employees/search'
import MyProfile from '../views/employees/profile/my_profile'
import MySuggestions from '../views/employees/suggestion_box'
import ReviewSuggestions from '../views/engagement/review_suggestions'
import Transfer from '../views/employees/transfer'
import Schedule from '../views/employees/schedule'
import EmployeeProfile from '../views/employees/profile/employee_profile'
import Dashboard from '../views/dashboard/dashboard'
import Login from '../views/components/Login/login'
import MailNewHires from '../views/components/Mail/new_hires'
import MailList from '../views/components/Mail/mail_list'
import Resources from '../views/components/Forms/resources'
import Management from '../views/components/Management/permissions'
import my_feedbacks from '../views/feedbacks/my_feedback'
import give_feedback from '../views/feedbacks/give_feedback'
import feedback_management from '../views/feedbacks/feedback_management'
import feedback_create from '../views/feedbacks/feedback_create'
import billing_report from '../views/reports/components/billing_component'
import IncidencesManagement from '../views/components/Incidences/IncidencesView'
import ClockerMyHours from '../views/components/Clocker/agent/myhours'
import ClockerMyHoursReview from '../views/components/Clocker/agent/agentHourReview' // DISCONTINUED
import ClockerMyHoursReviewDetail from '../views/components/Clocker/agent/agentHourDetail' // DISCONTINUED
import PayrollWatchmenDashboard from '../views/components/Payroll/watchmen/watchmenPayroll'
import PayrollAdjustmentCorrections from '../views/components/Payroll/watchmen/adjustmentsPayroll'
import ClockerWatchmenDashboard from '../views/components/Clocker/watchmen/watchmenDashboard'
import ClockerAdjustmentCorrections from '../views/components/Clocker/watchmen/adjustments'
import ClockerTraining from '../views/components/Forms/clocker_training'
import CODTraining from '../views/components/Forms/cod_training'
import SpamTraining from '../views/components/Forms/spam_training'
import ClockerExam from '../views/components/Forms/clocker_exam'
import ClockerExamSup from '../views/components/Forms/clocker_supervisor_exam'
import ClockerResetPwd from '../views/components/Management/clocker/reset_password'
import ClockerReviewHours from '../views/components/Clocker/supervisor/reviewhours'
import ClockerDetailedDay from '../views/components/Clocker/supervisor/detailedDay' // DISCONTINUED
import ClockerManagementTool from '../views/components/Clocker/clocker_management'
import PayrolHoursReviewAManager from '../views/components/Clocker/accountManager/AMHourReviewTemplate'
import ClockerAMRHDetailedDay from '../views/components/Clocker/accountManager/AMHourReviewDetailedDay'
import OrgCreative from "@/views/pages/Resources/OrgCreative";
import SupervisorHoursReview from "@/views/pages/Resources/SupervisorHoursReview";
import AMHoursReview from "@/views/pages/Resources/AMHoursReview";
import AgentHoursReview from "@/views/pages/Resources/AgentHoursReview";
import EMSReview from "@/views/pages/Resources/EMSReview";
import ClockerReview from "@/views/pages/Resources/ClockerReview";
import OrgCore from "@/views/pages/Resources/OrgCore";
import TicketingSystem from "@/views/pages/Resources/TicketingSystem";
import SurveyManagement from '../views/components/Management/surveys/survey_management'
import TicketsUserView from '../views/components/Tickets/userTicketView'
import TicketsAdminView from '../views/components/Tickets/adminTicketView'
import TicketsModView from '../views/components/Tickets/modTicketView'
import UploadSchedules from '../views/schedules/uploadCSV'
import EditSchedule from '../views/schedules/edit'
import EditScheduleDate from '../views/schedules/views/scheduleDateView'
import CandidatesPage from '../views/recruitment/pages/candidates/Index'
import RegisterCandidate from '../views/recruitment/pages/candidates/Create'
import EditCandidate from '../views/recruitment/pages/candidates/Edit'
import PurchaseOrders from '../views/recruitment/pages/purchase-orders/Index'
import NewPurchaseOrder from '../views/recruitment/pages/purchase-orders/Create'
import EditPurchaseOrder from '../views/recruitment/pages/purchase-orders/Edit'
import Trainings from '../views/recruitment/pages/trainings/Index'
import NewTraining from '../views/recruitment/pages/trainings/Create'
import EditTraining from '../views/recruitment/pages/trainings/Edit'
import ManageTrainingAttendance from '../views/recruitment/pages/trainings/Attendance'
import TrainingsDaily from '../views/recruitment/pages/trainings/daily/index'
import TrainingsSurveys from '../views/recruitment/pages/trainings/Surveys'
import TrainingsCertifications from '../views/recruitment/pages/trainings/Certifications'
import TrainingsExams from '../views/recruitment/pages/trainings/Exams'
import Notifications from '../views/notifications/layout'
import firstLogin from '../views/employees/firstLogin'
import formDashboard from '../views/formbuilder/views/dashboard'
import formCreate from '../views/formbuilder/views/creator'
import WestmedEmails from '../views/components/campaigns/westmed/WestmedEmails'
import RSASearch from '../views/components/campaigns/RSA/RSA_Search'
import Positions from '../views/positions/pages/index'
import NewPosition from '../views/positions/pages/create'
import ExitSurvey from '../views/employees/exitSurvey'
import EditPosition from '../views/positions/pages/edit'
import MaintenanceForm from '../views/formbuilder/views/maintenanceFormPage'
import PayrolHoursReviewEmpCW from '@/modules/hours-management/pages/currentWeek'
import PayrolHoursReviewEmpLW from '@/modules/hours-management/pages/previousWeek'
import SpecialPayroll from '../views/components/Payroll/special/specialPayroll'
import SpecialPayrollManagement from '../views/components/Payroll/special/specialPayrollManagement'
import dashboardManager from '../views/dashboard/manager'
import NotFound from '../views/Utilities/Fallback'
import newsFeedManager from '../views/newsfeed/manager/index'
import ApprovedDaysManager from '../views/components/Payroll/management/approvedDays'
import RolesManager from '../modules/hours-management/roles-management/pages/index'
import DetailedHours from '../views/components/Clocker/hours/detailedHours'
import PayrolHoursReviewSupervisor from '../modules/hm/SupervisorHoursReview'
import Inventory_NewItem from '../views/inventory/new'
import Inventory from '../views/inventory/search'
import Inventory_Edit from '../views/inventory/Forms/edit'
import Inventory_AssignItem from '../views/inventory/Forms/assignation'
import Inventory_Upload_Leter from '../views/inventory/Forms/upload'
import Inventory_ReturnItem from '../views/inventory/return'
import Inventory_EvaluateItem from '../views/inventory/Forms/evaluate'
import TrainingResources from '@/modules/resources/trainings/pages/Index'
import HarassmentTraining from '@/views/pages/Resources/SexualHarassmentPrevention'
import TimeManagement from '@/views/pages/Resources/TimeManagement'
import PciCompliance from '@/views/pages/Resources/PciCompliance'
import ComunicationTraining from '@/views/pages/Resources/ComunicationTraining'
import WellnessTraining from '@/views/pages/Resources/WellnessTraining'
import AssertiveTraining from '@/views/pages/Resources/AssertiveTraining'
import EmotionalIntelligence from '@/views/pages/Resources/EmotionalIntelligence'
import StressManagement from '@/views/pages/Resources/StressManagement'
import editProfile from '@/views/components/Modal/edit_employee'
import RiseCourse from '../modules/resources/trainings/pages/Course'
import AttritionRisk from '@/views/recruitment/pages/trainings/AttritionRisk'
import StaffAttendance from '@/views/recruitment/pages/trainings/StaffAttendance'
import Activities from '@/views/recruitment/pages/trainings/Activities'
import IncidencesRequests from '@/views/components/Incidences/Requests/IncidencesRequestsPage'
import TerminationForm from '@/views/components/Incidences/Requests/Termination'
import MessageManager from '../views/messages/manager'
import CreateMessage from '../views/messages/create'
import ViewMessage from '../views/messages/view'
import MessageCenter from '../views/messages/center'
import SecurityTraining from '@/views/pages/Resources/SecurityCompliance'
import Teams from '../views/employees/teams/index'
import TeamsCreate from '../views/employees/teams/create'
import Msl from '../views/employees/msl/index'
import MslForm from '../views/employees/msl/form'
import MslDetails from '../views/employees/msl/details'
import MslEdit from '../views/employees/msl/form'
import Surveys from '../views/surveys/index'
import SurveysForm from '../views/surveys/form'
import SurveysView from '../views/surveys/view'
import SurveysViewSatisfaction      from '../views/surveys/viewSatisfactionSurvey'
import InventoryEmployee from '../views/inventory/employee'
import Terminations from '../views/employees/terminations/index'
import ComingSoon from '../views/Utilities/ComingSoon'
import DocumentView from '../views/documents/employee/document_view'
import DocumentManagement from '../views/documents/management/document_search'
import DocumentDetails from '../views/documents/management/document_details'
import Headcount from '../views/headcount/Headcount'
import MedicalSurvey from '../views/surveys/medical/viewMedicalSurvey.vue'
import MedicalSurveyReview from '../views/surveys/medical/MedicalSurveyReview.vue'
import TicketQueueManagement from '../views/tickets/management/TicketQueueManagement.vue'
import OrganizationManagement from '../views/organization/OrganizationManagement.vue'

Vue.use(VueRouter)

let routes = [
  { path: '/', component: Login },
  { path: '/test', component: TrainingResources, name: 'feature.test' },
  { path: '/', name: 'dashboard', component: Login },
  { path: '/management/roles/:id', component: RolesManager },
  { path: '/employees', component: SearchEmployee, name: 'emp.search' },
  { path: '/employees/new', component: NewEmployee, name: 'emp.create' },
  { path: '/employee/firstlogin', component: firstLogin, name: 'emp.firstlogin' },
  { path: '/employees/resources', component: Resources, name: 'emp.training' },
  { path: '/employees/transfer', component: Transfer, name: 'emp.transfer' },
  { path: '/employees/schedule', component: Schedule, name: 'emp.schedule' },
  {
    path: '/employees/my-profile',
    component: MyProfile,
    children: [
      { path: '', name: 'myProfile.personal' },
      { path: 'professional', name: 'myProfile.professional' },
      { path: 'emergency', name: 'myProfile.emergency' },
      { path: 'documents', name: 'myProfile.documents' },
      { path: 'surveys', name: 'myProfile.surveys' },
      { path: 'password-vault', name: 'myProfile.passwordVault' },
    ]
  },
  { path: '/employees/mysuggestionbox', component: MySuggestions, name: 'emp.suggestions' },
  { path: '/employees/reviewsuggestionbox', component: ReviewSuggestions, name: 'emp.suggestions.rev' },
  { path: '/employees/clocker/myhours', component: ClockerMyHours, name: 'emp.clocker' },
  { path: '/employees/clocker/myhours/view/1', component: ClockerMyHoursReview, name: 'mainView' }, // DISCONTINUED
  { path: '/employees/clocker/myhours/view/2', component: ClockerMyHoursReviewDetail, name: 'myHoursDetails', props: true }, //DISCONTINUED
  {
    path: '/employees/profile/:id',
    component: EmployeeProfile,
    children: [
      { path: '', name: 'profileEmployee.personal' },
      { path: 'professional', name: 'profileEmployee.professional' },
      { path: 'emergency', name: 'profileEmployee.emergency' },
      { path: 'documents', name: 'profileEmployee.documents' },
      { path: 'actions', name: 'profileEmployee.actions' },
      { path: 'items', name: 'profileEmployee.items' },
    ]
  },
  { path: '/mail/newhires', component: MailNewHires, name: 'mail.new-hires' },
  { path: '/management/mail/lists', component: MailList },
  { path: '/management/clocker/users/resetpwd', component: ClockerResetPwd, name: 'clocker.pwd.reset' },
  { path: '/management/clocker', component: ClockerManagementTool, name: 'clocker.manage' },
  { path: '/clocker/accountManager/reviewHours/detailedHours', component: ClockerAMRHDetailedDay, name: 'AMHRDetailedDay' }, // DISCONTINUED
  { path: '/clocker/supervisor/approvalHours', component: ClockerReviewHours, name: 'clocker.supervisor.approval' },
  { path: '/clocker/watchmen/adjustmentRequests/dashboard', component: ClockerWatchmenDashboard, name: 'clocker.watchmen.dashboard' },
  { path: '/clocker/watchmen/adjustmentRequests/adjustments', component: ClockerAdjustmentCorrections, name: 'clocker.adjustments' },
  { path: '/clocker/supervisor/:empId/:nowParam/detailedDay', component: ClockerDetailedDay, name: 'detailedDay' }, // DISCONTINUED
  { path: '/payroll/hours-review/supervisor', component: PayrolHoursReviewSupervisor, name: 'clocker.supervisor.hours' },
  { path: '/payroll/hours-review/account-manager/', component: PayrolHoursReviewAManager, 'name': 'clocker.manager.hours' },
  { path: '/payroll/hours-review/employee/current-week/:tab', component: PayrolHoursReviewEmpCW, name: 'hoursReview.current' },
  { path: '/payroll/hours-review/employee/previous-week/:tab', component: PayrolHoursReviewEmpLW, name: 'hoursReview.previous' },
  { path: '/payroll/hours-review/:usuario/:empid/:date', component: DetailedHours, name: 'DetailedHours' },
  { path: '/payroll/hours-review/employee/my-profile/:date', component: DetailedHours, name: 'MyDetailedHours' },
  { path: '/payroll/watchmen/adjustmentRequests/dashboard', component: PayrollWatchmenDashboard, name: 'watchmen.payroll.dashboard' },
  { path: '/payroll/watchmen/adjustmentRequests/adjustmentsPayroll', component: PayrollAdjustmentCorrections, name: 'payroll.adjustments' },
  { path: '/resources/courses/:slug', component: RiseCourse, name: 'course.rise' },
  { path: '/resources/courses/clocker', component: ClockerTraining, name: 'course.clocker' },
  { path: '/resources/courses/cod', component: CODTraining, name: 'course.cod' },
  { path: '/resources/courses/spam', component: SpamTraining, name: 'course.spam' },
  { path: '/resources/exam/clocker', component: ClockerExam, name: 'exam.clocker' },
  { path: '/resources/exam/clocker/supervisor', component: ClockerExamSup, name: 'exam.clocker.supervisor' },
  { path: '/resources/training/creative-organization-policies', component: OrgCreative, name: 'course.creative.org' },
  { path: '/resources/training/core-organization-policies', component: OrgCore, name: 'course.core.org' },
  { path: '/resources/training/supervisor-hours-review', component: SupervisorHoursReview, name: 'course.supervisor.hoursreview' },
  { path: '/resources/training/am-hours-review', component: AMHoursReview, name: 'course.am.hoursreview' },
  { path: '/resources/training/hours-review', component: AgentHoursReview, name: 'course.agent.hoursreview' },
  { path: '/resources/training/ems-review', component: EMSReview, name: 'course.emsreview' },
  { path: '/resources/training/clocker/review', component: ClockerReview, name: 'course.clocker.review' },
  { path: '/resources/training/ticketing-system', component: TicketingSystem, name: 'course.ticketingsystem' },
  { path: '/resources/training/sexual-harassment-training', component: HarassmentTraining, name: 'course.harassment' },
  { path: '/resources/training/time-management', component: TimeManagement, name: 'course.time' },
  { path: '/resources/training/pci-dss', component: PciCompliance, name: 'course.compliance' },
  { path: '/resources/training/comunication-training', component: ComunicationTraining, name: 'course.comunication' },
  { path: '/resources/training/wellness-training', component: WellnessTraining, name: 'course.wellness' },
  { path: '/resources/training/assertive-communication', component: AssertiveTraining, name: 'course.assertive' },
  { path: '/resources/training/emotional-intelligence', component: EmotionalIntelligence, name: 'course.intelligence' },
  { path: '/resources/training/stress-management', component: StressManagement, name: 'course.stress' },
  { path: '/resources/training/security-compliance', component: SecurityTraining, name: 'course.security' },
  { path: '/management/permissions', component: Management },
  { path: '/feedbacks/my', component: my_feedbacks, name: 'feedback' },
  { path: '/feedbacks/new', component: give_feedback, name: 'feedback.new' },
  { path: '/feedbacks/management', component: feedback_management, name: 'feedback.management' },
  { path: '/feedbacks/create', component: feedback_create, name: 'feedback.create' },
  { path: '/dashboard', component: Dashboard, name: 'base.dashboard' },
  { path: '/management/survey/list', component: SurveyManagement, name: 'survey.management' },
  { path: '/tickets/my_tickets', component: TicketsUserView, name: 'ticket.mytickets' },
  { path: '/tickets/tickets_assignation', component: TicketsAdminView, name: 'ticket.ticketsassignation' },
  { path: '/tickets/tickets_review', component: TicketsModView, name: 'ticket.ticketsreview' },
  { path: '/schedules/uploadCSV', component: UploadSchedules, name: 'schedules.upload' },
  { path: '/schedules/edit/:empID', component: EditSchedule, name: 'schedules.edit' },
  { path: '/schedules/edit/:empid/:date', component: EditScheduleDate, name: 'schedules.edit.date' },
  { path: '/recruitment/candidates', component: CandidatesPage, name: 'recruitment.candidates' },
  { path: '/recruitment/candidates/new', component: RegisterCandidate, name: 'candidates.create' },
  { path: '/recruitment/candidates/:id', component: EditCandidate, name: 'recruitment.candidate.edit' },
  { path: '/recruitment/purchase-orders', component: PurchaseOrders, name: 'recruitment.po' },
  { path: '/recruitment/purchase-orders/new', component: NewPurchaseOrder, name: 'recruitment.po.create' },
  { path: '/recruitment/purchase-orders/:id', component: EditPurchaseOrder, name: 'recruitment.po.edit' },
  { path: '/recruitment/trainings', component: Trainings, name: 'trainings.index' },
  { path: '/recruitment/trainings/surveys', component: TrainingsSurveys, name: 'trainings.surveys' },
  { path: '/recruitment/trainings/new', component: NewTraining, name: 'trainings.create' },
  { path: '/recruitment/trainings/:id/certifications', component: TrainingsCertifications, name: 'recruitment.trainings.certifications' },
  { path: '/recruitment/trainings/:id/exams', component: TrainingsExams, name: 'recruitment.trainings.exams' },
  { path: '/recruitment/trainings/:id', component: EditTraining, name: 'recruitment.trainings.edit' },
  { path: '/recruitment/trainings/:id/attendance', component: ManageTrainingAttendance, name: 'recruitment.trainings.attendance' },
  { path: '/recruitment/trainings/:id/attrition-risk', component: AttritionRisk, name: 'recruitment.trainings.attrition' },
  { path: '/recruitment/trainings/:id/staff-attendance', component: StaffAttendance, name: 'recruitment.trainings.staff' },
  { path: '/recruitment/trainings/:id/activities', component: Activities, name: 'recruitment.trainings.activities' },
  {
    path: '/recruitment/trainings/:id/daily',
    component: TrainingsDaily,
    children: [
      { path: '', name: 'daily.attendance' },
      { path: 'training_hours', name: 'daily.training_hours' },
      { path: 'attrition_risk', name: 'daily.attrition_risk' },

      { path: 'class_note', name: 'daily.class_note' },
      { path: 'staff_attendance', name: 'daily.staff_attendance' },
      { path: 'class_activities', name: 'daily.class_activities' },

      { path: 'trainee_notes', name: 'daily.trainee_notes' },
      { path: 'trainee_commitments', name: 'daily.trainee_commitments' },
      { path: 'trainee_observations', name: 'daily.trainee_observations' },
    ]
  },
  { path: '/developoment/blankspace', component: Notifications, name: 'development.space' },
  { path: '/employees/incidences', component: IncidencesManagement, name: 'incidences.management' },
  { path: '/open-positions', component: Positions, name: 'positions.index' },
  { path: '/open-positions/new', component: NewPosition, name: 'positions.create' },
  { path: '/open-positions/:id', component: EditPosition, name: 'positions.edit' },
  { path: '/campaigns/westmed/emails', component: WestmedEmails, name: 'westmed.emails' },
  { path: '/campaigns/rsa/search', component: RSASearch, name: 'rsa.search' },
  { path: '/employee/predismissal', component: ExitSurvey, name: 'employee.predismissal' },
  { path: '/payroll/specialpayroll', component: SpecialPayroll, name: 'payroll.specialpayroll' },
  { path: '/payroll/specialpayroll/management', component: SpecialPayrollManagement, name: 'payroll.specialpayrollmanagement' },
  { path: '/utilities/form_builder', component: formDashboard, name: 'utilities.formbuilder' },
  { path: '/utilities/manager/dashboard', component: dashboardManager, name: 'utilities.manager.dashboard' },
  { path: '/utilities/manager/newsfeed', component: newsFeedManager, name: 'utilities.manager.newsfeed' },
  { path: '/forms/create', component: formCreate, name: 'forms.create' },
  { path: '/forms/maintenace', component: MaintenanceForm, name: 'forms.maintenance' },
  { path: '/reports/billing', component: billing_report, name: 'reports.billing' },
  { path: '/payroll/approved-days', component: ApprovedDaysManager, name: 'payroll.approvedays' },
  { path: '/inventory/new/', component: Inventory_NewItem, name: 'inventory.new' },
  { path: '/inventory/search', component: Inventory, name: 'inventory.search' },
  { path: '/inventory/edit/:id', component: Inventory_Edit, name: 'inventory.edit' },
  { path: '/inventory/assign/:id', component: Inventory_AssignItem, name: 'inventory.assigitem' },
  { path: '/inventory/upload-letter/:id', component: Inventory_Upload_Leter, name: 'inventory.upload' },
  { path: '/inventory/return/:id', component: Inventory_ReturnItem, name: 'inventory.return' },
  { path: '/inventory/evaluate/:id', component: Inventory_EvaluateItem, name: 'inventory.evaluate' },
  { path: '/incidences/create/:recid/:id', component: IncidencesRequests, name: 'incidences.create' },
  { path: '/incidences/create/:recid/:id/termination', component: TerminationForm, name: 'incidences.create.termination' },
  { path: '/employees/:id/edit', component: editProfile, name: 'profile.edit' },
  { path: '/employees/my-profile/edit', component: editProfile, name: 'myprofile.edit' },
  { path: '/messages/manager', component: MessageManager, name: 'msg.manager' },
  { path: '/messages/center', component: MessageCenter, name: 'msg.center' },
  { path: '/messages/create', component: CreateMessage, name: 'msg.create' },
  { path: '/messages/view/:id', component: ViewMessage, name: 'msg.view' },
  { path: '/employees/teams', component: Teams, name: 'teams.index' },
  { path: '/employees/teams/create', component: TeamsCreate, name: 'teams.create' },
  { path: '/employees/msl', component: Msl, name: 'msl.index' },
  { path: '/employees/msl/create/:recid/:id/medical_leave', component: MslForm, name: 'msl.create' },
  { path: '/employees/msl/:id/details/', component: MslDetails, name: 'msl.details' },
  { path: '/employees/msl/:idmsl/:id/edit/', component: MslEdit, name: 'msl.edit' },
  { path: '/employees/inventory/:id', component: InventoryEmployee, name: 'inventory.employee' },
  { path: '/surveys', component: Surveys, name: 'surveys.index' },
  { path: '/surveys/create/:type', component: SurveysForm, name: 'surveys.create' },
  { path: '/surveys/edit/:id', component: SurveysForm, name: 'surveys.edit' },
  { path: '/surveys/view/:id', component: SurveysView, name: 'surveys.view' },
  { path: '/employees/profile/:rec_id/documents/:doc_type', component: DocumentView, name: 'documents.view' },
  { path: '/documents/requests', component: DocumentManagement, name: 'documents.management' },
  { path: '/documents/requests/:id', component: DocumentDetails, name: 'documents.detailed' },
  { path: '/employees/terminations', component: Terminations, name: 'terminations.index' },
  { path: '/comingsoon', component: ComingSoon, name: 'utilities.comingsoon' },
  { path: '/surveys/satisfaction', component: SurveysViewSatisfaction, name:'surveys.viewSatisfaction'},
  { path: '/headcount', component: Headcount, name: 'headcount.management' },
  { path: '/surveys/medical', component: MedicalSurvey, name:'surveys.viewMedicalSurvey'},
  { path: '/surveys/answers/medical/:id', component: MedicalSurveyReview, name:'surveys.medical.answers'},
  { path: '*', component: NotFound },
  { path: '/tickets/management', component: TicketQueueManagement, name:'tickets.management.queue'},
  { path: '/organization/management', component: OrganizationManagement, name: 'organization.management' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(sessionStorage.getItem('token'));
  const empStatus = JSON.parse(sessionStorage.getItem('status'));

  if (authRequired && !loggedIn) {
    return next('/');
  } else if (authRequired && loggedIn && empStatus === 'Predismissal' && to.path !== '/employee/predismissal') {
    next({ name: 'employee.predismissal' });
  }
  next();
})

export default router
