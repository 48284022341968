<template>
    <div class="w-full">
        <div class="ml-2 relative flex flex-col h-50 items-center justify-center" v-if="!loading">
                <div title="Trainer" class="flex flex-row">
                    <span title="Training Campaign" class="cursor-pointer uppercase">{{training.campaign}} &nbsp;</span>
                    -
                    <span title="Training Department" class="cursor-pointer uppercase">&nbsp;{{training.department}}&nbsp;</span>
                    - 
                    <span title="Training LOB" class="cursor-pointer uppercase">
                      &nbsp;{{training.lob}} &nbsp;
                    </span>
                </div>
                <div class="flex flex-row items-center justify-center text-gray-700 w-full">
                    <div title="Training period" class="cursor-pointer">
                        <span class="text-xs ml-1">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                            </svg>
                            {{date_start}} -  {{date_end}}
                        </span>
                    </div>
                    <div title="Training hours" class="cursor-pointer ml-2">
                        <span class="text-xs ml-1">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                            </svg>
                            {{time_start}} -  {{time_end}}
                        </span>
                    </div>
                    <div title="Location" class="cursor-pointer ml-2">
                        <span class="text-xs ml-1">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                            </svg>
                            {{training.location}}
                        </span>
                    </div>
                    <div title="Trainer" class="cursor-pointer ml-2">
                        <span class="text-xs ml-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
                            </svg>
                            {{training['trainer_empID']}}
                        </span>
                    </div>
                    <div title="Trainees_limit" class="cursor-pointer ml-2">
                        <span class="text-xs ml-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                            </svg>
                            {{training['trainees_number']}}
                        </span>
                    </div>
                </div>
                 
        </div>
        <div v-else>
            Loading components 
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
            </svg>
        </div>
    </div>
   
</template>

<script>
// Pinia > Vuex
import {mapActions, mapGetters} from 'vuex'
export default {
    data(){
        return{
            loading:true
        }
    },
    methods:{
        ...mapActions(['fetchTrainingDetailsById'])
    },
    computed:{
        ...mapGetters([
            "anyError",
            "training",
            "date_start",
            "date_end",
            "time_start",
            "time_end"
        ]),
    },
    mounted(){
        this.fetchTrainingDetailsById(this.$route.params.id).then(()=>{
            if(this.anyError){
                return this.$fire({
                    type:'warning',
                    title:'The training is not available or has been deleted. If you think this is an error please contact an administrator.'
                }).then(()=>this.$router.push({name:'trainings.index'}))
            }
            this.loading = false;
       });
    },
    created(){
        
    }
}
</script>

<style>
</style>