<template>
<!-- This is the modal in the Account Manager Hours Review with detailed hours DISCONTINUED -->
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Report Missing Event</h3>
        </div>
      </div>
    </div>
    <div :class="{ hide: !isLoading }" class="row">
      <div class="col text-center">
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <div :class="{ hide: isLoading}" >
      <div class="row">
        <div class="col">
          <label> Current User: <b>{{ this.empId }}</b></label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label> Date: <b>{{ this.eventDate | moment("YYYY-MM-DD") }}</b></label>
        </div>
      </div>
      <div class="row">
        <div class="col mb-1">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Event to Report:</label>
            <select class="custom-select d-block w-100" v-model="newEvent.eventSelected" required>
                <option :value="null" hidden>Choose an event...</option>
                <optgroup v-for="(elements, key, index) in getAllEventsPerCampaign" :key="index" :label="key" >
                    <option v-for="(event,index) in elements" :value="event._break" :key="index">{{event._break}}</option>
                </optgroup>
            </select>
          </div>
        </div>
      </div>
      <div v-if="this.newEvent.eventSelected == 'Untracked Shift'">
        <div class="row">
          <div class="col mb-1">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Graveyard Shift Duration:</label>
              <vue-timepicker v-model="newEvent.graveyardShiftDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-1">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Standard Shift Duration:</label>
              <vue-timepicker v-model="newEvent.standardShiftDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col mb-1">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Graveyard Break Duration:</label>
              <vue-timepicker v-model="newEvent.graveyardBreakDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-1">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Standard Break Duration:</label>
              <vue-timepicker v-model="newEvent.standardBreakDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-1">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Graveyard Lunch Duration:</label>
              <vue-timepicker v-model="newEvent.graveyardLunchDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-1">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Standard Lunch Duration:</label>
              <vue-timepicker v-model="newEvent.standardLunchDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
          </div>
        </div>
      </div>
      <div v-else> 
        <div class="row">
          <div class="col mb-1">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Graveyard Duration:</label>
              <vue-timepicker v-model="newEvent.graveyardDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-1">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Standard Duration:</label>
              <vue-timepicker v-model="newEvent.standardDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
          </div>
        </div>
      </div>  
      <div class="row">
        <div class="col">
          <button @click="addMissingEvent" class="py-2 px-3 rounded-full bg-blue-500 text-white" :disabled="sending">
            <loading v-if="sending"/>
            <span v-else>Save Request</span>
          </button>
          <!-- <buttontype="button" @click="alertSample" class="bg-gray-200 px-6 py-2 rounded">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from 'vue2-timepicker'
import Loading from '@/views/mockups/loading'
export default {
  components: { VueTimepicker, Loading },
  data() {
    return {
      sending: false,
      time: null,
      isLoading: true,
      
      newEvent:{
        eventSelected: null,
        reason: 'missed',
        otherReason:  'N/A',
        graveyardDuration: null,
        standardDuration: null
      },
      newMissingShift: {
        eventSelected: null,
        reason: 'missed',
        otherReason:  'N/A',

      },
      allEvents: [],
    };
  },
  props: {
    empId: String,
    eventDate: String,
  },
  computed: {
    ...mapState(["clockerUsers","AccountManagerClocker"]),
    ...mapGetters([
        "getAllEventsPerCampaign",
    ]),
  },
  created(){
    this.loadRequestInfo();
  },
  methods: {
    ...mapActions([ 
      'fetchAllEvents',
      'storeNewPayrollRequest',
      'storeNewMissingShift',
      ]),
    addMissingEvent: function(){
      let eventType = this.newEvent.eventSelected;
      if(eventType == null){
        this.$fire({
          type: "warning",
          title: "Oops! Event not selected",
          showConfirmButton: true,
        });
      }else{
        if(eventType == 'Untracked Shift'){
          this.saveMissingShift();
        }else{
          this.saveMissingEvent();
        }
     
      }
    },

    alertSample:function(){
      this.$fire({
            type: "warning",
            title: "Something went wrong!",
            text: "We couln't find an important part to process your request, please report to the team",
            showConfirmButton: true
          });
    },

    saveMissingEvent: function(){
      if(this.newEvent.graveyardDuration == null && this.newEvent.standardDuration == null){
        this.$fire({
          type: "warning",
          title: "Oops! Time not specified",
          showConfirmButton: true,
        });
      }else{
        let objectData = {
          EmpID:        this.empId,
          eventDate:    this.eventDate,
          eventType:    this.newEvent.eventSelected,
          gvyDuration:  this.newEvent.graveyardDuration ?? { HH: "00", mm: "00" },
          stdDuration:  this.newEvent.standardDuration  ?? { HH: "00", mm: "00" },
          reason:       this.newEvent.reason,
          otherReason:  this.newEvent.otherReason,
          PayrollID:    '',
        }

        let gvyminutes = (objectData.gvyDuration.mm * 1) + (objectData.gvyDuration.HH * 60);
        let stdminutes = (objectData.stdDuration.mm * 1) + (objectData.stdDuration.HH * 60);
        let totalminutes = gvyminutes + stdminutes;
        
        if(totalminutes < 5){
          this.$fire({
            type: "warning",
            title: "Requests must not be less than 5 minutes",
            showConfirmButton: true,
          });
        }else{
          this.sending = true;
          this.storeNewPayrollRequest(objectData).then(()=>{
            this.$fire({
              type: "success",
              title: "The issue has been reported successfully",
              showConfirmButton: false,
              timer: 1600,
            }).then(()=>{
                this.$emit('closeMissingModal', true);
              });
            });
        }
      }
    },
    saveMissingShift: function(){

      if(this.newEvent.graveyardShiftDuration == null && this.newEvent.standardShiftDuration == null){
        this.$fire({
          type: "warning",
          title: "Oops!, please set a shift duration",
          showConfirmButton: true,
        });
      }

      if(this.newEvent.graveyardBreakDuration == null && this.newEvent.standardBreakDuration == null){
        this.$fire({
          type: "warning",
          title: "Oops!, please set a break duration",
          showConfirmButton: true,
        });
      }

      if(this.newEvent.graveyardLunchDuration == null && this.newEvent.standardLunchDuration == null){
        this.$fire({
          type: "warning",
          title: "Oops!, please set a lunch duration",
          showConfirmButton: true,
        });
      }

      let objectData = {
        EmpID:            this.empId,
        eventDate:        this.eventDate,
        eventType:        this.newEvent.eventSelected,
        reason:           this.newEvent.reason,
        otherReason:      this.newEvent.otherReason,
        gvyShiftDuration: this.newEvent.graveyardShiftDuration  ?? { HH: "00", mm: "00" },
        stdShiftDuration: this.newEvent.standardShiftDuration   ?? { HH: "00", mm: "00" },
        gvyBreakDuration: this.newEvent.graveyardBreakDuration  ?? { HH: "00", mm: "00" },
        stdBreakDuration: this.newEvent.standardBreakDuration   ?? { HH: "00", mm: "00" },
        gvyLunchDuration: this.newEvent.graveyardLunchDuration  ?? { HH: "00", mm: "00" },
        stdLunchDuration: this.newEvent.standardLunchDuration   ?? { HH: "00", mm: "00" },
        PayrollID:        '',
      }

      let gvyminutes = (objectData.gvyShiftDuration.mm * 1) + (objectData.gvyShiftDuration.HH * 60);
      let stdminutes = (objectData.stdShiftDuration.mm * 1) +  (objectData.stdShiftDuration.HH * 60);
      let totalminutes = gvyminutes + stdminutes;

      if(totalminutes < 5){
        this.$fire({
          type: "warning",
          title: "Requests must not be less than 5 minutes",
          showConfirmButton: true,
        });
      }else{
        this.sending = true;
        this.storeNewMissingShift(objectData).then(()=>{
          this.$fire({
            type: "success",
            title: "The issue has been reported successfully",
            showConfirmButton: false,
            timer: 1600,
          }).then(()=>{
            this.$emit('closeMissingModal', true);
          });
        }).catch(()=>{
          this.$fire({
            type: "warning",
            title: "Something went wrong!",
            text: "We couln't find an important part to process your request, please report to the team",
            showConfirmButton: true
          });
        })
      }

    },
    loadRequestInfo: function(){
      this.fetchAllEvents(this.empId).then(() =>{
        this.isLoading = false;
      });
    }
  },
};
</script>
<style lang="scss">

</style>