<template>
  <div ref="ModalNewRequest" id="ModalNewRequest" class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Report a Payroll Issue</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <label>
          Current User: <b>{{ this.objectData.emp_id }}</b>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <label v-if="this.objectData.Event_date">Date: <b>{{ this.objectData.Event_date | moment("YYYY-MM-DD") }}</b></label>
        <label v-else>Date: <b>{{ this.objectData.created_date | moment("YYYY-MM-DD") }}</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <label v-if="this.objectData.Event">Event: <b>{{ this.objectData.Event }}</b></label>
        <label v-else>Event: <b>{{this.objectData.event}}</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Reason:</label>
          <select v-model="adjustment.reason" class="form-control" id="exampleFormControlSelect1">
            <option value="null">Select a Reason</option>
            <option value="misuse">Agent tool misuse</option>
            <option value="internet">Internet issue</option>
            <option value="clocker">Issue with clocker</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="adjustment.reason == 'other'" class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">
            <a class="text-danger">*</a>Write the reason:
          </label>
          <input class="form-control" type="text" v-model="adjustment.otherReason" placeholder="Write the reason" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Graveyard Duration:</label>
          <vue-timepicker v-model="adjustment.graveyardDuration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
      </div>
    </div>   
    <div class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Standard Duration:</label>
          <vue-timepicker v-model="adjustment.starndarduration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button @click="sendRequest" class="py-2 px-3 rounded-full bg-blue-500 text-white" :disabled="sending">
          <loading v-if="sending"/>
          <span v-else>Save Adjustment</span>
        </button>
        <!-- <button type="button" class="bg-gray-200 px-6 py-2 rounded">Cancel</button> -->
      </div>
    </div>
  </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker'
import Loading from '@/views/mockups/loading'
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  components: { VueTimepicker, Loading },
  data() {
    return {
      sending: false,
      time: null,
      adjustment: {
        reason: null,
      },
    };
  },
  props: {
    objectData: Object,
  },
  computed: {
   ...mapState(['AccountManagerClocker']),
   ...mapGetters([
    ]),
  },
  methods: {
    ...mapActions(['storeNewPayrollRequest']),
    sendRequest: function () {
      if(this.adjustment.reason == null)
      {
        this.$fire({
            type: "warning",
            title: "Oops! Reason not selected",
            showConfirmButton: true,
              });
      }else{
        if(this.adjustment.graveyardDuration == null && this.adjustment.starndarduration == null){
          this.$fire({
              type: "warning",
              title: "Oops! Time not specified",
              showConfirmButton: true,
                });
        }else{
          //Ajustes para detailedDay
          let eventDate = this.objectData.Event_date ?? this.objectData.created_date;
          let eventType = this.objectData.Event ?? this.objectData.event;

          let newRequestData = {
            EmpID: this.objectData.emp_id,
            eventDate: eventDate,
            eventType: eventType,
            gvyDuration: this.adjustment.graveyardDuration ?? { HH: "00", mm: "00" },
            stdDuration:  this.adjustment.starndarduration ?? { HH: "00", mm: "00" },
            reason: this.adjustment.reason,
            otherReason: this.adjustment.otherReason,
            PayrollID: this.objectData.Payroll_id,
          }
          // Validation not less than 5 mins
          let totalSeconds = (this.objectData.stdhours * 3600) + (this.objectData.gravhours * 3600);
          let totalAdjustment = (newRequestData.gvyDuration.HH * 3600 + newRequestData.gvyDuration.mm * 60) + (newRequestData.stdDuration.HH * 3600 + newRequestData.stdDuration.mm * 60);
          let diff = totalSeconds - totalAdjustment;

          if(diff < 300 && diff > -300){
            this.$fire({
                  type: "warning",
                  title: "Difference must not be less than 5 minutes",
                  showConfirmButton: true,
                    });
          }else{
            this.sending = true;
            this.storeNewPayrollRequest(newRequestData).then(()=>{
               this.$fire({
                   type: "success",
                   title: "The issue has been reported successfully",
                   showConfirmButton: false,
                   timer: 1600,
                 }).then(()=>{
                    this.$emit('closeAdjustmentModal', true);
                  });
            });
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
  @import '~vue2-timepicker/dist/VueTimepicker.css';
  .display-time {
    border: none !important;
    cursor: pointer !important;
    height: auto !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>