<template>
  <div class="container-fluid">
    <!-- Adjustments & corrections, correction modal, /clocker/watchmen/adjustmentRequests/adjustments -->
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Corrections</h3>
        </div>
      </div>
    </div>
    <div>
      <loading :active.sync="isLoaderActive" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
      <div class="row">
        <div class="col text-center">
          <label for="exampleFormControlSelect1">Event to Report:</label>
          <select :disabled="disableEvent" class="custom-select d-block w-100" v-model="eventSelected">
            <option hidden :value="null">Choose an event...</option>
            <optgroup v-for="(elements, key, index) in getAllEventsPerCampaign" :key="index" :label="key" >
                <option v-for="(event,index) in elements" :value="event._break" :key="index">{{event._break}}</option>
            </optgroup>
          </select>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col">
            <b-progress height="2rem" :precision="2" class="mt-2" :max="maxDuration" show-value>
              <b-progress-bar :value="this.newTotalTime" variant="warning">
                <b style="color:black">Total Duration: {{this.newTotalTime}} Hours</b>
              </b-progress-bar>
            </b-progress>
          </div>
        </div>
        <div class="row mt-3 mb-3">
          <div class="col">
            <label>New Start Time</label>
            <vue-timepicker v-if="!timerDisabled" v-model="newStartTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            <vue-timepicker v-else disabled v-model="newStartTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
          </div>
          <div class="col">
            <label>New End Time</label>
            <vue-timepicker v-if="!timerDisabled" v-model="newEndTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            <vue-timepicker v-else disabled v-model="newEndTime" class="form-control" format="HH:mm" close-on-complete ></vue-timepicker>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <b-form-textarea :disabled="disableComment" v-model="eventComment" placeholder="Please add a comment..." rows="3" max-rows="3" ></b-form-textarea>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <button type="button" @click="calculateTime" class="bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded">Calculate</button>
          </div>
          <div class="col">
            <button
              v-if="canFix"
              type="button"
              @click="fixTime"
              class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded"
            >Fix Time</button>
            <button v-else disabled type="button" class="bg-greem-600 hover:bg-green-700 text-white px-6 py-2 rounded">Fix Time</button>
          </div>
          <div class="col">
            <button type="button" @click="clearTime" class="bg-gray-200 px-6 py-2 rounded">Clear</button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              v-if="canSave"
              type="button"
              @click="saveTime"
              class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded"
            >Save</button>
            <button v-else type="button" disabled class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <!-- <button type="button" class="bg-gray-200 px-6 py-2 rounded">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import Loading          from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: { VueTimepicker,Loading },
  data() {
    return {
      isLoading: true,
      maxDuration: null,
      newStartTime: null,
      newEndTime: null,
      newTotalTime: 0,
      newDuration: 0,
      canSave: false,
      timerDisabled: false,
      canFix: false,
      eventSelected: null,
      disableEvent: false,
      disableComment: false,
      eventComment: null,
      eventsPerCampaign: null,

      //Loader Data
      isLoaderActive: false,
      fullPage: true,
      color: "#F2F2F2",
      bgcolor: "#000000",
      loader: 'dots',
    };
  },
  props: {
    EmployeeID: String,
    eventDate: String,
  },
  computed: {
    ...mapState(["clockerUsers"]),
    ...mapGetters(["getInfoByApprovalID", "getAllEventsPerCampaign"]),
  },
  created(){
    this.fetchAllEvents(this.EmployeeID).then(() =>{
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions(["saveNewEvent", "fetchAllEvents"]),
    saveTime: function () {
      this.isLoaderActive = true;
      let dataObj = {
        EmpID:          this.EmployeeID,
        eventDate:      this.eventDate,
        eventName:      this.eventSelected,
        newStartTime:   this.newStartTime,
        newEndTime:     this.newEndTime,
        eventDuration:  this.newDuration,
        eventComment:   this.eventComment,
      };
      this.saveNewEvent(dataObj).then((res)=>{
        this.isLoaderActive = false;
        if (res.status == 200){
            this.$fire({
                type: 'success',
                title: 'The correction has been created successfully',
                showConfirmButton: false,
                timer: 1500
              }).then(()=>{ 
                  this.$emit('closeModal', true);                
              }); 
          }
      }).catch(()=>{
        this.$fire({
          type: 'danger',
          title: 'Something went wrong',
          showConfirmButton: false,
          timer: 1500
        })
      });
    },
    calculateTime: function () {
      // To Create a moment object only for time we need to add a fake Date
      let startTime = moment(
        this.newStartTime.HH + ":" + this.newStartTime.mm + ":00",
        "HH:mm:ss a"
      );
      let endTime = moment(
        this.newEndTime.HH + ":" + this.newEndTime.mm + ":00",
        "HH:mm:ss a"
      );
      var mins = moment.duration(endTime.diff(startTime));
      let useEvent = this.eventSelected;
      let useComment = this.eventComment;

      if (useEvent == null){
          this.$fire({
          type: "error",
          title: "Oops! Select a valid event",
          showConfirmButton: true,
        });
      }
      else{
        if(useComment == null) {
          this.$fire({
          type: "error",
          title: "Comment is mandatory",
          showConfirmButton: true,
        });
      }else{
          if (mins._milliseconds <= 0) {
        this.$fire({
          type: "error",
          title: "Oops! The start date need to be greater than the end hour",
          showConfirmButton: true,
        });
      } else {
        this.canFix = true;
        this.newTotalTime =
          Math.round((mins._milliseconds / 3600000 + Number.EPSILON) * 100) /
          100;
        this.maxDuration = this.newTotalTime;
        this.newDuration = mins._milliseconds / 1000;
              }
          }
        }
    },
    fixTime: function () {
      this.timerDisabled = true;
      this.canSave = true;
      this.disableEvent = true;
      this.disableComment = true;
    },
    clearTime: function () {
      this.newStartTime = { HH: "", mm: "" };
      this.newEndTime = { HH: "", mm: "" };
      this.newTotalTime = null;
      this.timerDisabled = false;
      this.canSave = false;
      this.disableEvent = false;
      this.disableComment = false;
      this.eventComment = null;
    },
  },
};
</script>
<style lang="scss">
</style>