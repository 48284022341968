<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Adjustments</h3>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoaderActive" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
    <div class="row">
      <div class="col text-center">
          <label> Event type:</label>
          <div>
            <b>{{this.dataEvent.Event}}</b>
          </div>
      </div>
    </div>
    <div class="row mt-3 mb-3 ">
      <div style="text-align:center" class="col">
        <label>Start Date</label>
        <div>
          <strong>{{this.dataEvent.Event_start_time | moment('DD/MM/YYYY HH:mm') }}</strong>
        </div>
      </div>
      <div style="text-align:center" class="col">
        <label>End Date</label>
        <div>
          <strong>{{this.dataEvent.Event_end_time | moment('DD/MM/YYYY HH:mm') }}</strong>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <b-progress height="2rem" :precision="2" class="mt-2" :max="maxDuration" show-value>
            <b-progress-bar :value="durationNormal" variant="primary"><b>Duration: {{durationNormal}} Hours</b></b-progress-bar>
          </b-progress>
          <b-progress height="2rem" :precision="2" class="mt-2" :max="maxDuration" show-value>
            <b-progress-bar :value="this.newTotalTime" variant="warning"><b style="color:black">New Duration: {{this.newTotalTime}} Hours</b></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col">
          <label>New Start Time</label>
          <vue-timepicker v-if="!timerDisabled"  v-model="newStartTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
          <vue-timepicker v-else disabled v-model="newStartTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
        <div class="col">
          <label>New End Time</label>
          <vue-timepicker  v-if="!timerDisabled" v-model="newEndTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
          <vue-timepicker v-else disabled v-model="newEndTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <b-form-textarea :disabled="disableComment" v-model="eventComment" placeholder="Please add a comment..." rows="3" max-rows="3" ></b-form-textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <button type="button" @click="calculateTime" class="bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded">Calculate</button>
        </div>
        <div class="col">
          <button v-if="canFix" type="button" @click="fixTime" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded">Fix Time</button>
          <button v-else disabled type="button" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded">Fix Time</button>
        </div>
        <div class="col">
          <button type="button" @click="clearTime" class="bg-gray-200 px-6 py-2 rounded">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button v-if="canSave" type="button" @click="saveTime" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
          <button v-else type="button" disabled class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <!-- <button type="button"  class="bg-gray-200 px-6 py-2 rounded">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from 'vue2-timepicker'
import moment from "moment";
import Loading          from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {VueTimepicker, Loading},
  data() {
    return {
      maxDuration:    Math.round(( (this.dataEvent.Event_Duration / 3600) + Number.EPSILON) * 100) / 100,
      durationNormal: Math.round(( (this.dataEvent.Event_Duration / 3600) + Number.EPSILON) * 100) / 100,
      removeTime:     null,
      addTime:        null,
      newStartTime:   null,
      newEndTime:     null,
      newTotalTime:   0,
      newDuration:    0,
      canSave:        false,
      timerDisabled:  false,
      canFix:         false,
      eventComment:   null,
      disableComment: false,

      //Loader Data
      isLoaderActive: false,
      fullPage: true,
      color: "#F2F2F2",
      bgcolor: "#000000",
      loader: 'dots',
    };
  },
  props: {
    dataEvent: Object,
  },
  computed: {
    ...mapState(["clockerUsers"]),
    ...mapGetters(["getInfoByApprovalID"]),
  },
  methods: {
    ...mapActions(['saveCorrection']),
    saveTime: function () {
      this.isLoaderActive = true;
      let dataObj = {
        RecId :         this.dataEvent.rec_id,
        newStartTime:   this.newStartTime,
        newEndTime:     this.newEndTime,
        newDuration:    this.newDuration,
        adjustmentComment: this.eventComment,
      }
      this.saveCorrection(dataObj).then( (response)=>{
        this.isLoaderActive = false;
        if (response.status == 200){
            this.$fire({
                type: 'success',
                title: 'The adjustment has been applied successfully',
                showConfirmButton: false,
                timer: 1500
              }).then(()=>{ 
                  this.$router.go(0);
              }); 
          }
      });
    },
    calculateTime: function () {
      // To Create a moment object only for time we need to add a fake Date
      let startTime     = moment(this.newStartTime.HH + ':' + this.newStartTime.mm  + ':00', "HH:mm:ss a");
      let endTime       = moment(this.newEndTime.HH   + ':' + this.newEndTime.mm    + ':00' , "HH:mm:ss a");
      var mins          = moment.duration(endTime.diff(startTime));
      let useComment    = this.eventComment;


      if( mins._milliseconds < 0 ){
        this.$fire({
            type: "error",
            title: "Oops! The start date need to be greater than the end hour",
            showConfirmButton: true,
        });
      }else{
        if(useComment == null) {
          this.$fire({
          type: "error",
          title: "Comment is mandatory",
          showConfirmButton: true,
        });
      }else{
        this.canFix = true;
        this.newTotalTime = Math.round(( (mins._milliseconds/3600000) + Number.EPSILON) * 100 ) / 100
        this.newDuration  = mins._milliseconds/1000;
            }
      }
    },
    fixTime: function(){
      this.timerDisabled = true;
      this.canSave       = true;
      this.disableComment = true;
    },
    clearTime: function () {
      this.newStartTime = { HH: "", mm: "" };
      this.newEndTime  =  { HH: "", mm: "" };
      this.newTotalTime = null;
      this.timerDisabled = false;
      this.canSave      = false;
      this.disableComment = false;
      this.eventComment = null;
    },
  },
};
</script>
<style lang="scss">
</style>