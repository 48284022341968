<template>
  <div class="fixed z-50" v-if="show">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity ease-in-out duration-300 backdrop-blur-sm"></div>
    <div class="fixed inset-0 w-screen">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>