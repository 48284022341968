<template>
<!-- This is the modal in the Supervisor Hours Review with detailed hours DISCONTINUED -->
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Report Missing Event</h3>
          proof:{{newEvent.proof}}
        </div>
      </div>
    </div>
    <div :class="{ hide: !isLoading }" class="row">
      <div class="col text-center">
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <div :class="{ hide: isLoading}" >
      <div class="row">
        <div class="col">
          <label> Current User: <b>{{ this.empId }}</b></label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label> Date: <b>{{ this.eventDate | moment("YYYY-MM-DD") }}</b></label>
        </div>
      </div>
      <div class="row">
        <div class="col mb-1">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Event to Report:</label>
            <select class="custom-select d-block w-100" v-model="newEvent.eventSelected" required>
                <option :value="null" hidden>Choose an event...</option>
                <optgroup v-for="(elements, key, index) in getAllEventsPerCampaign" :key="index" :label="key" >
                    <option v-for="(event,index) in elements" :value="event._break" :key="index">{{event._break}}</option>
                </optgroup>
            </select>
          </div>
        </div>
      </div>
    <div class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Total Event Duration:</label>
          <vue-timepicker v-model="newEvent.starndarduration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
      </div>
    </div>
      <div class="">
        <label class="inline-flex items-center">
          <span class="mr-2">Include proof: </span>
          <ToggleButton :active="requestProof" @toggle="clearProof" @fileSelected="setFileName" :id="1"/>
        </label>
      </div>      
      <div class="w-full">
          <div v-if="requestProof" class="w-full mb-2">
            <drag-and-drop @clear="resetFileModal" :key="modalResetKey" @fileSelected="setFileName"/>
          </div>
      <div v-else class="w-full pl-4 py-2">
         <label for="fileName" class="ml-1 py-1 text-gray-800">No proof reason:</label>
        <textarea class="resize-none w-full mx-auto  rounded-md border-dashed border-2 border-gray-300 p-2" rows="4" required v-model="newEvent.noProofReason">        
        </textarea>
      </div>
      </div>     
    </div>
      <div class="row">
        <div class="col">
          <button type="button" @click="registerEvent" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
          <!--<button type="button" class="bg-gray-200 px-6 py-2 rounded">Cancel</button>-->
        </div>
      </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from 'vue2-timepicker'
import DragAndDrop from '@/views/Utilities/SupervisorDragAndDrop'
import ToggleButton from '@/modules/hours-management/roles-management/components/toggle'
export default {
  components: { VueTimepicker, DragAndDrop, ToggleButton },
  data() {
    return {
      time: null,
      isLoading: true,
      newEvent:{
        eventSelected: null,
        reason: null,
        graveyardDuration: null,
        starndarduration: null,
        proof:null,     
        fileName:null,
        noProofReason:null
      },
      allEvents: [],
      requestProof: true,
      modalResetKey:0,
      file:null,
    };
  },
  props: {
    empId: String,
    eventDate: String,
    approvalId: Number,
  },
  computed: {
    ...mapState(["clockerUsers","AccountManagerClocker"]),
    ...mapGetters([
        "getAllEventsPerCampaign",
    ]),
    mustProvideEvidence:function () {
      return !!this.requestProof;
    },
    fileNameSet:function(){
      return this.fileName !== null &&  this.fileName !== ""
    },
    fileName:function(){
      return this.newEvent.proof;
    }
  },
  created(){
    this.loadRequestInfo();
  },
  methods: {
    ...mapActions([ 
      'fetchAllEvents',
      'storeNewPayrollRequest',
      'uploadImgRequestProof',
      ]),
    registerEvent: async function(){
      if(this.newEvent.proof == null && this.newEvent.noProofReason == null){
          return this.$fire({
              type: "warning",
              title: "Oops! Provide a proof or a no proof reason",
              showConfirmButton: true,
          });
      }
      
      if(this.newEvent.eventSelected == null){
        return this.$fire({
            type: "warning",
            title: "Oops! Event not selected",
            showConfirmButton: true,
        });
      }
      if(this.newEvent.graveyardDuration == null && this.newEvent.starndarduration == null)
      {
        return this.$fire({
              type: "warning",
              title: "Oops! Time not specified",
              showConfirmButton: true,
          });
      }
    
      let objectData = {
        EmpID: this.empId,
        eventDate:  this.eventDate,
        eventType:  this.newEvent.eventSelected,
        gvyDuration: this.newEvent.graveyardDuration ?? { HH: "00", mm: "00" },
        stdDuration:  this.newEvent.starndarduration ?? { HH: "00", mm: "00" },
        reason: 'missed',
        otherReason: 'N/A',
        PayrollID: '',
        ApprovalID: this.approvalId,
        noProofReason : this.newEvent.noProofReason,
        Request_type: 'correction',
      }

      let gvyminutes = (objectData.gvyDuration.mm * 1) + (objectData.gvyDuration.HH * 60);
      let stdminutes = (objectData.stdDuration.mm * 1) + (objectData.stdDuration.HH * 60);
      let totalminutes = gvyminutes + stdminutes;

      if(totalminutes < 5){
        return this.$fire({
          type: "warning",
          title: "Requests must not be less than 5 minutes",
          showConfirmButton: true,
        });
      }
       
        if(this.newEvent.proof instanceof  File){
            try{
                let form = new FormData()
                form.append("image", this.newEvent.proof);
                const response = await this.uploadImgRequestProof(form);
                objectData.proof_id= response.data.img;
            }catch(error){
              return this.$fire({
                type: "warning",
                title: "Requests must not be less than 5 minutes",
                showConfirmButton: true,
              });
            }
           
        }
           
       
    
        this.storeNewPayrollRequest(objectData).then(()=>{
          this.$fire({
              type: "success",
              title: "The issue has been reported successfully",
              showConfirmButton: false,
              timer: 1600,
          }).then(()=>{
              this.$router.go(-1)
          });
      });
      
    

    },
    loadRequestInfo: function(){
      this.fetchAllEvents(this.empId).then(() =>{
        this.isLoading = false;
      });
    },
     resetFileModal(){
       this.modalResetKey ++;
     },
     setFileName(file){
       this.newEvent.proof = file;
     },
     clearProof(){
       this.newEvent.proof = null;
       this.newEvent.noProofReason =null;
       this.requestProof = !this.requestProof;
     }
  },
};
</script>
<style lang="scss">

</style>