<template>
<div class="main">
  <b-container fluid>

    <b-row class="mb-2">
      <b-col>
        <h1 class="text-6xl font-bold text-left leading-tightmr-auto inline inline-block pt-1  flex-1">
          {{this.locatedIn}}
        </h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <nav aria-label="Page navigation example">
          <ul class="pagination pagination-sm">
            <li :class="{ 'page-item not-active': week == '1', 'page-item': week == '0' }"><a :class="{ 'page-link selected': week == '1', 'page-link': week == '0' }" href="#" @click="PreviousWeek()">Previous Week</a></li>
            <li :class="{ 'page-item not-active': week == '0', 'page-item': week == '1' }"><a :class="{ 'page-link selected': week == '0', 'page-link': week == '1' }" href="#" @click="CurrentWeek()">Current Week</a></li>
          </ul>
        </nav>
      </b-col>
    </b-row>

      <b-row> 
        <b-col align-self="center">
          <div>
              <myhourstable :week="week" :key="componentKey"></myhourstable> 
          </div>
        </b-col>
      </b-row>

  </b-container>
</div>
</template>
<script>
import myhourstable     from '@/views/components/Clocker/tables/myHoursTable';
export default {
  name: '',
  components: {  myhourstable },
  data(){
    return {
      locatedIn: 'Current Week',
      week: 0,
      componentKey: 0,
    }
  },
  methods:{
      PreviousWeek(){
        this.week = 1;
        this.locatedIn = 'Previous Week';
        this.componentKey += 1;
      },
      CurrentWeek(){
        this.week = 0;
        this.locatedIn = 'Current Week';
        this.componentKey += 1;
      },
  },
  mounted() {
    let title = 'Estimated Payable Hours';
    this.$emit('set-title',title);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pagination {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
}
.not-active {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    z-index: 3;
}
.selected{
    z-index: 3;
    color: rgb(242, 242, 242);
    background-color: #2070c5;
    border-color: #2070c5;
}
</style>
