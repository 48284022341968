<template>
    <div class="w-9/12 m-auto">
        <div v-if="isLoading" class="row">
            <div class="col text-center">
                <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
                <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>
        <div v-else class="bg-white p-3 rounded-md my-3">
            <button class="bg-gray-700 text-white p-2 rounded-md flex mb-3" @click.prevent="$router.go(-1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
            </button>
            <form @submit.prevent="editInfoSubmit()">
                <div class="flex flex-col w-full">
                    <div class="flex text-left ml-3 font-sans text-md font-semibold">
                        Fill out the form bellow
                    </div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Phone1')">
                            <the-mask class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full" v-model="getEditableInfo['Phone1']"  placeholder="Write here..." :mask="['(###) ###-####', '(###) ###-####']" />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 uppercase">Cellphone</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Phone2')">
                            <the-mask class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full" v-model="getEditableInfo['Phone2']" placeholder="Write here..." :mask="['(###) ###-####', '(###) ###-####']" />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 uppercase">Home Phone</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Birthday')">
                            <input type="date" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full" v-model="getEditableInfo['Birthday']" placeholder="yyyy-mm-dd"/>
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Birthday</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Personal_email')">
                            <input type="email" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full lowercase" v-model="getEditableInfo['Personal_email']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Personal Email</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Email')">
                            <input type="email" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full lowercase" v-model="getEditableInfo['Email']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Work Email</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('CURP')">
                            <input type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full uppercase" v-model="getEditableInfo['CURP']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">CURP</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('SSN')">
                            <input type="number" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full" v-model="getEditableInfo['SSN']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">SSN</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('RFC')">
                            <input type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full uppercase" v-model="getEditableInfo['RFC']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">RFC</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Gender')">
                            <select class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 py-1 w-full uppercase" v-model="getEditableInfo['Gender']">
                                <option value="null" hidden>Select...</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Gender</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Education')">
                            <select class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 py-1 w-full uppercase" v-model="getEditableInfo['Education']">
                                <option value="null" hidden>Select...</option>
                                <option value="Primary School">Primary School/Primaria</option>
                                <option value="Junior High School">Junior High School/Secundaria</option>
                                <option value="High School">High School/Preparatoria</option>
                                <option value="Undergraduate Studies">Undergraduate Studies/Licenciatura</option>
                                <option value="Postgraduate Studies">Postgraduate Studies/Postgrados</option>
                            </select>
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Education</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Marital_Status')">
                            <select class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 py-1 w-full uppercase" v-model="getEditableInfo['Marital_Status']">
                                <option value="null" hidden>Select...</option>
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Domestic Partnership">Domestic Partnership</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Separated">Separated</option>
                                <option value="Widowed">Widowed</option>
                            </select>
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Marital Status</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Kids')">
                            <input type="number" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full uppercase" v-model="getEditableInfo['Kids']" placeholder="Write here..." min="0" />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">How Many Children do you have? (Zero if none)</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Clinic')">
                            <input type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full uppercase" v-model="getEditableInfo['Clinic']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Clinic</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Contact_Name')">
                            <input type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full uppercase" v-model="getEditableInfo['Contact_Name']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Emergency Contact Name</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Contact_Phone')">
                            <the-mask type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full" v-model="getEditableInfo['Contact_Phone']" placeholder="Write here..." :mask="['(###) ###-####', '(###) ###-####']" />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Emergency Contact Phone Number</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Contact_Email')">
                            <input type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full lowercase" v-model="getEditableInfo['Contact_Email']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Emergency Contact Email</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Benef_Name')">
                            <input type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full uppercase" v-model="getEditableInfo['Benef_Name']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Benefactor Name</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Benef_Phone')">
                            <the-mask type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full" v-model="getEditableInfo['Benef_Phone']" placeholder="Write here..." :mask="['(###) ###-####', '(###) ###-####']" />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Benefactor Phone Number</span>
                        </div>

                        <div class="px-3 pt-3 pb-1 relative" :hidden="!IS_VISIBLE('Benef_Email')">
                            <input type="text" class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 w-full lowercase" v-model="getEditableInfo['Benef_Email']" placeholder="Write here..." />
                            <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-3 whitespace-nowrap uppercase">Benefactor Email</span>
                        </div>
                    </div>
                    
                    <div class="flex text-left ml-3 mt-3 font-sans text-md font-semibold">
                        Please look up your address, select from the list the address closest to where you live, once you have selected, click on "set address".
                    </div>
                    <div class="flex flex-row text-left justify-content-center">
                        <div class="flex flex-col flex-1">
                            <div class="flex flex-row relative mx-3 mb-2" label="Address:" description="*Search your address">
                                <GmapAutocomplete class="border-b-2 hover:border-blue-400 focus:border-blue-400 px-2 pt-3 w-full" @place_changed='setPlace' placeholder="Write your address here" />
                                <span class="text-xxs text-gray-400 mr-auto absolute top-1 left-0 whitespace-nowrap">Address</span>
                                <button class="bg-blue-500 hover:bg-blue-900 text-xs uppercase text-white rounded-lg p-2 px-3 m-1 ml-3 whitespace-nowrap" @click='addMarker'>Set address</button>
                            </div>
                            <GmapMap :center='center' :zoom='16' style='width:100%;  height: 300px;'>
                                <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" @click="center=m.position" />
                            </GmapMap>
                        </div>
                    </div>
                    <div class="flex text-left ml-3 font-sans text-xs text-red-500">
                        * Note: Changes will not be applied until you hit  the "save" button at the bottom of the screen.
                    </div>
                </div>
                
                <div class="mt-4 flex">
                    <button class="mx-auto bg-red-700 hover:bg-red-800 text-xs uppercase text-white rounded-lg p-2 px-5 font-semibold" type="submit">Save</button>
                </div>

            </form>
        </div>
    </div>  
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { TheMask } from "vue-the-mask";
export default {
    components: {
        TheMask 
    },
    data(){
        return{
            center: { 
                lat: 25.6737936, 
                lng: -100.3931177 
            },
            markers: [],
            places: [],
            isLoading: true,
            my_profile_fillable:[
                'Birthday',
                'Education',
                'Marital_Status',
                'Kids',
                'Phone1',
                'Phone2',
                'Personal_email',
                'Email',
                'Address',
                'Clinic',
                'Contact_Name',
                'Contact_Email',
                'Contact_Phone',
                'Benef_Name',
                'Benef_Email',
                'Benef_Phone'
            ],
            edit_profile_fillable:[
                'CURP',
                'SSN',
                'RFC',
                'Birthday',
                'Education',
                'Gender',
                'Marital_Status',
                'Kids',
                'Phone1',
                'Phone2',
                'Personal_email',
                'Email',
                'Address',
                'Clinic',
                'Contact_Name',
                'Contact_Email',
                'Contact_Phone',
                'Benef_Name',
                'Benef_Email',
                'Benef_Phone'
            ]
        }
    },
    computed:{
        ...mapState([
            "employees",
        ]),
        ...mapGetters([
            "infoEmployee",
            "getEditableInfo",
            "stateList"
        ]),

        ADMIN_VIEW (){
            return !!this.$route.params.id;
        },

        USER_VIEW (){
            return !this.$route.params.id;
        },

    },
    methods:{
        ...mapActions([
            "sendEdit",
            "fetchCitiesByState",
            "fetchStates"
        ]),

        IS_VISIBLE(label){
            return (this.USER_VIEW && this.my_profile_fillable.includes(label)) || (this.ADMIN_VIEW && this.edit_profile_fillable.includes(label));
        },
        
        setPlace(place) {
            this.getEditableInfo['GoogleMapsLocation'] = place;
        },

        addMarker(){
            event.preventDefault();
            if (this.getEditableInfo['GoogleMapsLocation']) {
                const marker = {
                    lat: this.getEditableInfo['GoogleMapsLocation']?.geometry?.location?.lat(),
                    lng: this.getEditableInfo['GoogleMapsLocation']?.geometry?.location?.lng(),
                };
                this.markers.push({ 
                    position: marker 
                });
                this.places.push(this.getEditableInfo['GoogleMapsLocation']);
                this.center = marker;

                this.pluckElements();
            }
        },

        pluckElements: function(){
            const address_components = this.getEditableInfo['GoogleMapsLocation'].address_components;
            let components = {};
            address_components.map((value) => {
                value.types.map((value2) => {
                components[value2] = value.long_name;
                if (value2==='country')
                    components.country_id = value.short_name;
                if (value2==='administrative_area_level_1')
                    components.state_code = value.short_name;
                })
            })

            let street_complete = null;
            if(typeof components.route !== "undefined" && typeof components.street_number !== "undefined"){
                street_complete = components.route + " " + components.street_number;
            }else if(typeof components.route !== "undefined"){
                street_complete = components.route;
            }else{
                street_complete = this.getEditableInfo['GoogleMapsLocation'].name;
            }
            this.getEditableInfo['Address1'] = street_complete;
            this.getEditableInfo['Address2'] = components.sublocality;
            this.getEditableInfo['Zip'] = components.postal_code;
            this.getEditableInfo['State'] = components.administrative_area_level_1;
            this.getEditableInfo['City'] = components.locality;
            this.getEditableInfo['Latitud'] = this.getEditableInfo['GoogleMapsLocation']?.geometry?.location?.lat();
            this.getEditableInfo['Longitud'] = this.getEditableInfo['GoogleMapsLocation']?.geometry?.location?.lng();
        },
        
        editInfoSubmit(){
            event.preventDefault();
            this.isLoading = true;
            let infoEdit = {
                employeeId: this.infoEmployee.EmpID,
                profile: Object.entries(this.getEditableInfo),
            };
            this.sendEdit(infoEdit).then((response)=>{
                this.isLoading = false;
                if(response.status == 200){
                    this.$fire({
                        type: 'success',
                        title: 'Changes saved',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(()=>{
                        this.$router.go(-1);               
                    });
                }
            }).catch((response)=>{
                this.fire({
                    type: 'error',
                    title: 'Something went wrong',
                    text: response,
                    showConfirmButton: true
                })
            });
        },
    },
    created() {
        if(this.infoEmployee?.length <= 0) this.$router.go(-1);
        this.isLoading = false;
    }
}
</script>
<style scoped>

.text-xxs{
    font-size: 0.5rem;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
::placeholder { /* Recent browsers */
    text-transform: none;
}

</style>