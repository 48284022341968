<template>
  <div class="clocker z-30">
    <vs-sidebar class="clockerSidebar absolute inset-0" :click-not-close="notClose" position-right parent="body" v-model="active">
      <button class="absolute top-2 left-2 bg-gray-200 px-1 rounded-full text-2xl z-10" @click="active = false" title="Hide Clocker">
        <i class="bi bi-x"></i>
      </button>
      <h1 class="absolute -bottom-2 left-0 right-0 text-center text-6xl m-0 font-bold tracking-tighter	text-gray-100">CLOCKER</h1>
      <div class="text-center">
          <h1 class="ctime text-6xl font-thin mt-3 mb-0">{{currentStringTime}}</h1>
          <h4 class="text-xl font-black">{{currentDate}}</h4>
      </div>
      <div v-if="(infoLoaded && currentActivity)">
        <div v-if="clockInLoading" class="mb-3">
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>      
        </div>
        <div v-else>
          <div class="text-xl flex justify-center mt-4">
            <button :disabled="currentActivity != 'None'" :class="currentActivity != 'None' ? 'bg-green-200':'bg-green-500'" class="text-white px-2 py-1 rounded-l drop-shadow-sm" v-b-modal="'startShift'">
              <i class="bi bi-stopwatch"></i>
              <span class="text-sm ml-1">Clock In</span>
            </button>
            <b-modal ref="startNewShiftModal" :id="'startShift'" @hide="refreshActivities" hide-footer hide-header>
              <startNewShiftModal @closeClockInModal="closeClockModal"></startNewShiftModal>
            </b-modal>
            <button :disabled="currentActivity == 'None'" @click="clockOut()" :class="currentActivity == 'None' ? 'bg-red-200':'bg-red-500'" class="text-white px-2 py-1 rounded-r drop-shadow-sm">
              <i class="bi bi-slash-circle"></i>
              <span class="text-sm ml-1">Clock Out</span>
            </button>
          </div>
          <div class="text-xl flex justify-center mt-2 mb-4">
            <button :disabled="currentActivity=='None' && currentActivity != 'shift'" :class="currentActivity=='None' ? 'bg-blue-200':'bg-blue-500'" class="text-white px-2 py-1 rounded-l drop-shadow-sm" v-b-modal="'startActivity'">
              <i class="bi bi-pause-circle"></i>
              <span class="text-sm ml-1">Start Activity</span>
            </button>
            <b-modal ref="startNewActivityModal" :id="'startActivity'" @hide="refreshActivities" hide-footer hide-header>
              <startNewActivityModal @toggle="closeActivityModal"></startNewActivityModal>
            </b-modal>
            <button :disabled="(currentActivity == 'None' || currentActivity == 'shift')" :class="currentActivity=='None' || currentActivity == 'shift' ? 'bg-orange-200':'bg-orange-400'" class="text-white px-2 py-1 rounded-r drop-shadow-sm" @click="endActivity()">
              <i class="bi bi-x-circle"></i>
              <span class="text-sm ml-1">End Activity</span>
            </button>
          </div>
        </div>
        <div class="bg-gray-100 text-center px-3 py-2 rounded-lg mb-3">
          <component :key="'shift' + currentShiftKey" :is="'currentShiftComponent'"></component>
        </div>
        <div class="bg-gray-100 text-center px-3 py-2 rounded-lg mb-3">
          <component :key="'current' + currentActivityKey" :is="'currentActivityComponent'"></component>
          <hr class="my-2">
          <component :key="'ongoing' + ongoingActivityKey" :is="'ongoingActivityComponent'"></component>
        </div>
        <div class="text-center">
          <button v-b-modal="'todayActivities'" class="bg-c-primary py-2 px-3 rounded text-white text-sm"><i class="bi bi-list-check mr-2"></i>Today's Activity</button>
        </div>
        <b-modal ref="newActivityModal" @closeStartActModal='closeModal' size="lg"  :id="'todayActivities'"  @hide="refreshActivities" hide-footer hide-header>
          <todaysActivityModal></todaysActivityModal>
        </b-modal>
      </div>
      <div v-else class="absolute inset-0 bg-white grid content-center">
        <div class="text-center">
            <p class="text-sm text-gray-400 leading-none mb-2">We are loading<br/>all our components...</p>
            <!-- Do not delete, something on app.vue -->
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
      </div>
    </vs-sidebar>
  </div>
</template>
<script>
  import { mapActions, mapGetters, mapState } from "vuex";
  import currentShiftComponent      from '@/views/clocker/components/currentShift'
  import currentActivityComponent   from '@/views/clocker/components/currentActivity'
  import ongoingActivityComponent   from '@/views/clocker/components/ongoingActivity'
  import todaysActivityModal        from '@/views/clocker/modals/todaysActivity'
  import startNewActivityModal      from '@/views/clocker/modals/startNewActivity'
  import startNewShiftModal         from '@/views/clocker/modals/startNewShift'
  import auth                       from '@/config/user'
  export default {
    components: {
      todaysActivityModal,
      startNewShiftModal,
      startNewActivityModal,
      currentShiftComponent,
      ongoingActivityComponent,
      currentActivityComponent
    },
    data(){
      return{
        currentShiftKey: 10,
        currentActivityKey: 20,
        ongoingActivityKey: 30,
        notClose: true,
        week: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
        infoLoaded: false,
        clockInDisabled: false,
        clockOutDisabled: false,
        startActivityDisabled: false,
        endActivityDisabled: false,
        ActDuration: null,
        clockInLoading: false,
        currentActivity: 'None',
        currentDate: '',
        currentTime: null,
        currentStringTime: null,
      };
    },
    computed: {
      ...mapState([
          "clockerUtility",
          "clockerUsers",
          "schedules",
      ]),
      ...mapGetters([
          "getCurrentActivity",
          "getCurrentShiftObject",
          "getStatusRequest",
          "getModalVisibility",
          "getNextEvent",
          "getCurrentTime",
      ]),
      activity:{
        get(){
          return this.getCurrentActivity;
        },
        set(val){
          this.currentActivity = val;
        }
      },
      active:{
        get(){
          return this.getModalVisibility;
        },
        set(value){
          this.$store.commit('setModalVisibility',value);
        }
      }
    },
    watch:{
      getCurrentActivity: function(val){
        this.currentActivity = val.currentActivity;
      },
      active:function(newVal){
        if(newVal){
            this.currentShiftKey++;
            this.currentActivityKey++;
            this.ongoingActivityKey++;
        }
      }
    },
    methods:{
        ...mapActions([
            'loadCurrentActivity',
            'loadCurrentShiftObject',
            'clockInAgent',
            'endNewActivity',
            'clockOutAgent',
            'setModalVisibility',
            'fetchNextEvent',
            'getTimeFromServer',
        ]),

        zeroPadding: function(num, digit) {
          var zero = '';
          for(var i = 0; i < digit; i++) { 
            zero += '0'; 
          }
          return (zero + num).slice(-digit);
        },

        updateTime: function(){
          try{
            var cd = this.currentTime;          
            cd.setSeconds( cd.getSeconds() + 1 );
            this.currentStringTime = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
          }catch(error){
            console.warn('function updateTime is not working');
          }
        },

        checkNextEvent: function(){
          let requestData = {
            'date':   this.currentDate,
            'time':   this.currentTime,
            'empID':  auth.currentUser.EmpID,
          }
          this.fetchNextEvent(requestData).then( (response) =>{
            if(response.data.displayNotification == true){
              let nextEvent = response.data.event;
              this.$toasted.show("This is a reminder: The event " +nextEvent + " will start soon, please open your clocker and attend the event.", {
                theme: "toasted-primary", 
                icon : 'report',
                position: "bottom-right", 
                duration : null,
                action : {
                  text : 'Ok!!',
                  onClick : (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                },
              });
            }
          });
        },

        clockOut: function(){
          this.$confirm(
            "Press 'ok' if you want to continue", 
            "You're about to clock out", 
            "warning"
          ).then((result) => {
            if (result){
              this.infoLoaded = false;
              let obj = {
                EmpID: auth.currentUser.EmpID
              };
              this.clockOutAgent(obj).then(()=>{
                this.$fire({
                  type: "success",
                  title: "The shift has been ended",
                  showConfirmButton: false,
                  timer: 1300,
                }).then(()=>{
                  this.refreshActivities();
                });
              });
            }
          })
        },

        refreshActivities: function(){
          this.currentShiftKey++;
          this.currentActivityKey++;
          this.ongoingActivityKey++;
          this.infoLoaded = true;
        },

        endActivity: function(){
          this.$confirm("Do you want to continue?", "You're about to end an activity", "warning").then((result) => {
            if (result){
              this.infoLoaded = false;
              let objData = { 
                EmpID: auth.currentUser.EmpID, 
                url: window.location.pathname 
              }
              this.endNewActivity(objData).then(()=>{
                this.$fire({
                  type: "success",
                  title: "Activity Ended Successfully",
                  showConfirmButton: false,
                  timer: 1300,
                });
                this.refreshActivities().then(()=>{
                  this.infoLoaded = true;
                });
              });
            }
          })
        },

        closeActivityModal: function(){
          this.$refs['startNewActivityModal'].hide()
        },
        
        closeClockModal: function(){
          this.$refs['startNewShiftModal'].hide()
        },

        closeModal: function(){
          this.$refs['newActivityModal'].hide()
        },
        
        getBrowserInfo: function() {
          var ua= navigator.userAgent, tem, 
          M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
          if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
          }
          if(M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
          }
          M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
          if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
          return M.join(' ');
        }
    },
    mounted: function () {
      this.getTimeFromServer().then(()=>{
        let testDate = new Date(this.getCurrentTime.timeFromServer);
        let cd = new Date();
        this.currentDate = this.zeroPadding(cd.getDate(), 2) + '/' + this.zeroPadding(cd.getMonth()+1, 2) + '/' + this.zeroPadding(cd.getFullYear(), 4) + ' - ' + this.week[cd.getDay()];
        testDate.setHours(this.getCurrentTime.hours);
        testDate.setMinutes(this.getCurrentTime.minutes);
        testDate.setSeconds(this.getCurrentTime.seconds);
        this.currentTime = new Date(testDate);
        setInterval(this.updateTime, 1000);
      });

      this.loadCurrentShiftObject().then(() => {
        this.currentShiftDuration = this.getCurrentShiftObject.currentShiftDuration;
      });

      this.loadCurrentActivity().then(() => {
        this.currentActivity = this.getCurrentActivity.currentActivity;
        this.infoLoaded = true;
      });
      setInterval(this.checkNextEvent, 300000);
    }
  }
</script>
<style>
  .vs-sidebar.vs-sidebar-position-right {
    min-width: 300px !important;
    width: auto !important;
    max-width: none !important;
    height: auto !important;
    padding: 40px 25px 60px;
    top: 5% !important;
    right: 30px !important;
    bottom: 5% !important;
    border-radius: 20px !important;
    -webkit-box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
    box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 200 !important;
  }
  .vs-sidebar--background {
    z-index: 100 !important;
  }
</style>