<template>
    <div>
        <div v-if="isReady" >
            <p class="ctitle mb-0 text-gray-500">Current Shift Duration</p> 
            <strong class="ctitle">{{ this.currentDuration !== "None" ? this.currentDuration : 'No active shifts' }}</strong>
        </div>
        <div v-else>
            <p class="ctitle mb-0 text-gray-400">Loading Current Shift...</p>
            <span class="loader-mini"></span>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters, mapState } from "vuex";
    export default {
    data(){
        return{
            currentDuration: null,
            isReady: false,
        };
    },
    computed: {
        ...mapState([
            "clockerUtility",
        ]),
        ...mapGetters([
            "getCurrentShiftObject",
        ]),
    },
    methods:{
        ...mapActions(['loadCurrentShiftObject']),
        cshiftDuration: function(){
            let currentTime = this.currentDuration.split(":");
            let d0 = new Date();
            let d1 = new Date(d0.getFullYear(),d0.getMonth(),d0.getDay(),currentTime[0],currentTime[1],currentTime[2]);
            d1.setSeconds(d1.getSeconds() + 1);
            this.currentDuration = d1.toLocaleTimeString('en-GB');
        },
    },
    created: function () {
        this.loadCurrentShiftObject().then(()=>{
            var shiftObject = this.getCurrentShiftObject;
            this.currentDuration = shiftObject.currentShiftDuration;
            if(this.currentDuration !== "None"){
                setInterval(this.cshiftDuration, 1000);
            }
            this.isReady = true;
        });
    }
}
</script>