<template>

    <div>
        <div v-if="!showTables && errors" class="mt-4">
            <not-found media="not_found" >
                <template slot="headline">
                        We couldn't find the information you're searching.
                </template>  
                <template slot="subline">
                    Please inform this error to a system administrator
                </template>  
            </not-found>
        </div>

        <div v-if="showTables && !errors" class="w-full h-full px-3 pt-1 pb-4 overflow-y-hidden">

            <div class="h-1/4">
                <h4 class="text-left pt-3 ml-3 h-1/6">Urgent Emails</h4> 
                <component :key="KeyRender" :AllEmails="this.ThisAllEmails" @Rerender="forceRerender" :is="'urgentEmails'"      class="h-5/6 shadow-sm border-2 rounded-md"></component>
            </div>

            <div class="h-1/4">
                <h4 class="text-left pt-3 ml-3 h-1/6">Important Emails</h4>
                <component :key="KeyRender" :AllEmails="this.ThisAllEmails" @Rerender="forceRerender" :is="'importantEmail'"   class="h-5/6 shadow-sm border-2 rounded-md"></component>
            </div>
            
            <div class="h-1/4">
                <h4 class="text-left pt-3 ml-3 h-1/6">Moderate Emails</h4>
                <component :key="KeyRender" :AllEmails="this.ThisAllEmails" @Rerender="forceRerender" :is="'moderateEmail'"      class="h-5/6 shadow-sm border-2 rounded-md"></component>
            </div>

            <div class="h-1/4">
                <h4 class="text-left pt-3 ml-3 h-1/6">Normal Emails</h4>
                <component :key="KeyRender" :AllEmails="this.ThisAllEmails" @Rerender="forceRerender" :is="'normalEmail'"      class="h-5/6 shadow-sm border-2 rounded-md"></component>
            </div>

        </div>

        <div v-if="!showTables && !errors" class="mt-4 justify-content-center flex">
            <div class="p-2">
                Loading Information...
            </div>
            <span><svg class="w-10 h-10 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"></path></svg></span>
        </div>
    </div>

</template>
<script>
import { mapGetters, mapState, mapActions} from "vuex";
import urgentEmails     from '@/views/components/campaigns/westmed/components/UrgentEmails'
import importantEmail   from '@/views/components/campaigns/westmed/components/ImportantEmails'
import moderateEmail    from '@/views/components/campaigns/westmed/components/ModerateEmails'
import normalEmail      from '@/views/components/campaigns/westmed/components/NormalEmails'
import NotFound from '@/views/mockups/404'
export default {
    components: { 
            urgentEmails,
            importantEmail,
            moderateEmail,
            normalEmail,
            NotFound
        },
    data() {
        return {
            errors: false,
            KeyRender: 0,
            showTables: false,
            ThisAllEmails: []
        }
    },
    computed: {
        ...mapState([
            'campaignStore'
        ]),
        ...mapGetters([
            'getAllEmails',
            'getKeywords'
        ])
    },

    methods: {
        ...mapActions([
            'fetchWestmedEmails',
            'fetchKeyWords'
        ]),

        forceRerender:function(){
            // this.showTables = false;
            this.fetchWestmedEmails().then(()=>{
                this.ThisAllEmails = this.getAllEmails;
                [].forEach.call(this.ThisAllEmails, element => {
                    for(var i = 0; i < this.getKeywords.length; i += 1) {
                        if(element.Subject.toLowerCase().includes(this.getKeywords[i].Subject.toLowerCase())) {
                            element.emailType = this.getKeywords[i].Priority_Level;
                        }
                    }
                });
                this.showTables = true;
                this.KeyRender++;
            })
        },

    },

    created(){
        this.fetchKeyWords().then(() => {
            setInterval(this.forceRerender, 300000);
            this.forceRerender();
        }).catch(() => {
            this.errors = true;
        });
    },

    mounted() {
        let title = 'Westmed Emails'
        this.$emit('set-title',title);
    }
}

</script>

<style>

</style>