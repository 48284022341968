<template>
    <div class="ctitle" v-if="isReady" >
        <p class="ctitle mb-0 text-gray-500 text-sm">Current Activity</p>
        <strong class="ctitle">{{ this.currentActivity !== "None" ? this.currentActivity : 'No activities ongoing' }}</strong>
    </div>
    <div v-else>
        <p class="ctitle mb-0 text-gray-400 text-sm">Loading Current Activity...</p>
        <span class="loader-mini"></span>
    </div>
</template>
<script>
    import { mapActions, mapGetters, mapState } from "vuex";
    export default {
    data(){
        return{
            currentActivity: null,
            isReady: false,
        };
    },
    computed: {
        ...mapState([
            "clockerUtility",
        ]),
        ...mapGetters([
            "getCurrentActivity",
        ]),
    },
    methods:{
        ...mapActions(['loadCurrentActivity']),
    },
    created: function () {
        this.loadCurrentActivity().then(()=>{
            var currentActivityObj = this.getCurrentActivity;
            this.currentActivity = currentActivityObj.currentActivity;
            this.isReady = true;
        });
    }
}
</script>