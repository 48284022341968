<template>
    <div class="todayActivity px-4 py-8">
        <h1 class="text-4xl font-thin mb-3 text-center">Today's Activity</h1>
        <div v-if="isLoading" class="text-center">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
        <div v-else>
             <table class="w-full rounded-sm shadow-sm overflow-hidden">
                 <thead>
                    <tr class="bg-c-primary text-white uppercase">
                        <th class="text-sm uppercase font-semibold px-3 py-2" scope="col">Event</th>
                        <th class="text-sm uppercase font-semibold px-3 py-2" scope="col">Start Time</th>
                        <th class="text-sm uppercase font-semibold px-3 py-2" scope="col">End Time</th>
                        <th class="text-sm uppercase font-semibold px-3 py-2" scope="col">Duration</th>
                    </tr>
                </thead>
                <tbody v-if="this.getTodayActivity">
                    <tr :id="index" v-for="(currentRow, index) in getTodayActivity" :key="index" class="border-t border-slate-200 hover:bg-gray-100">
                        <td class="text-base capitalize px-3 py-2">{{currentRow.Event}}</td>
                        <td class="text-base capitalize px-3 py-2">{{currentRow.Start | moment("YYYY-MM-DD HH:mm:ss") }}</td>
                        <td class="text-base capitalize px-3 py-2">{{currentRow.End | moment("YYYY-MM-DD HH:mm:ss")}}</td>
                        <td class="text-base capitalize px-3 py-2">{{currentRow.Duration }}</td>
                    </tr>
                </tbody>
                <tbody v-else><tr><td colspan="4">No records found.</td></tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'

export default {
    data(){
        return{
            isLoading: true,
        }
    },
    computed:{
        ...mapState(['clockerUtility']),
        ...mapGetters(['getTodayActivity'])
    },
    props:{
        profileInfo: Object,
    },
    methods:{
        ...mapActions([
            'loadTodayActivities'
        ]),
    },
    created() {
        this.loadTodayActivities().then(()=>{
            this.isLoading = false;
        });
    }
}
</script>
<style scoped>
#todayActivities___BV_modal_outer_{
    overflow: hidden;
    z-index: 20001 !important; 
}
</style>