<template>
  <div class="mx-auto">
    <div v-if="isLoading">
      <p class="font-italic font-weight-bold mx-auto">
        "We are loading all our components..."
      </p>
      <loading />
    </div>

    <div
      v-if="this.isLoading == false"
      class="bg-red-200/80 border-[1px] border-red-300 rounded-lg py-3 px-4 text-red-800 mx-auto w-96"
    >
      Note: Fields with * are required.
    </div>

    <form
      @submit.prevent="submit"
      class="space-y-3"
      v-if="this.isLoading == false"
    >
      <div
        class="bg-blue-200/80 border-[1px] border-blue-300 rounded-lg p-4 text-blue-800"
      >
        Personal Information
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>First Name:
        </label>
        <div class="w-2/3">
          <input
            type="text"
            v-model="employee.name"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            :class="{ 'border-red-500': submitted && $v.employee.name.$error }"
            placeholder="First Name"
          />
          <div
            v-if="submitted && !$v.employee.name.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            First Name is required.
          </div>
          <div
            v-if="submitted && !$v.employee.name.alpha"
            class="text-red-500 text-xs text-left mt-1"
          >
            First Name must be only letters.
          </div>
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Middle Name:</label>
        <div class="w-2/3">
          <input
            type="text"
            v-model="employee.middle_name"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder="Middle Name"
          />
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>First Last Name:
        </label>
        <div class="w-2/3">
          <input
            type="text"
            v-model="employee.first_lastName"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder="First Last Name"
            :class="{
              'border-red-500': submitted && $v.employee.first_lastName.$error,
            }"
          />
          <div
            v-if="submitted && !$v.employee.first_lastName.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            First Last Name is required
          </div>
          <div
            v-if="submitted && !$v.employee.first_lastName.alphaWspace"
            class="text-red-500 text-xs text-left mt-1"
          >
            First Last Name must be only letters
          </div>
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Second Last Name:</label>
        <div class="w-2/3">
          <input
            type="text"
            v-model="employee.second_lastName"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder="Second Last Name"
          />
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Birthday Date:</label>
        <div class="w-2/3">
          <datepicker
            v-model="employee.birthday"
            :format="customFormatter"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder=" dd/mm/aaaa"
            name="birthday"
          ></datepicker>
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Personal Email:</label>
        <div class="w-2/3">
          <input
            type="text"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            id="emailUser"
            placeholder="john.doe@mail.com"
            v-model="employee.email"
          />
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Gender:</label>
        <div class="w-2/3">
          <select
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            id="genderUser"
            v-model="employee.gender"
          >
            <option value="null" hidden>Choose a Gender...</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Phone 1:</label>
        <div class="w-2/3">
          <the-mask
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            v-model="employee.phoneOne"
            name="phoneOneEmployee"
            placeholder="Phone 1"
            :mask="['(###) ###-####', '(###) ###-####']"
          />
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Phone 2:</label>
        <div class="w-2/3">
          <the-mask
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            name="phoneTwoEmployee"
            v-model="employee.phoneTwo"
            placeholder="Phone 2"
            :mask="['(###) ###-####', '(###) ###-####']"
          />
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Address 1:</label>
        <div class="w-2/3">
          <input
            type="text"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder="Address 1"
            v-model="employee.addressOne"
          />
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Address 2:</label>
        <div class="w-2/3">
          <input
            type="text"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder="Address 2"
            v-model="employee.addressTwo"
          />
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">State:</label>
        <div class="w-2/3">
          <select
            v-model="employee.stateSelected"
            @change="loadCities"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
          >
            <option value="null" hidden>Choose a state...</option>
            <option
              v-for="state in stateList"
              :value="state"
              :key="state.stateName"
            >
              {{ state.stateName }}
            </option>
          </select>
        </div>
      </div>
      <!-- City select -->
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">City:</label>
        <div v-if="showLoadingCity" class="w-2/3 flex flex-col">
          <loading />
          <strong class="mx-auto">Loading cities...</strong>
        </div>
        <div v-if="selectState" class="w-2/3">
          <label>Select a state first</label>
        </div>
        <div v-if="showSelectCity" class="w-2/3">
          <select
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            id="cityUser"
            v-model="employee.citySelected"
          >
            <option value="null" hidden>Choose a city...</option>
            <option
              v-for="city in getCities"
              :value="city"
              :key="city.cityName"
            >
              {{ city.cityName }}
            </option>
          </select>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Zip:</label>
        <div class="w-2/3">
          <the-mask
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            name="zipUser"
            placeholder="Zip"
            :mask="['#####']"
            v-model="employee.zip"
          />
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Is Bilingual?:</label>
        <div class="w-2/3">
          <select
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            id="isBilingualUser"
            v-model="employee.isBilingual"
          >
            <option value="null" hidden>Choose an option...</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Marital Status:</label>
        <div class="w-2/3">
          <select
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            id="MaritalStatus"
            v-model="employee.maritalStatus"
          >
            <option value="null" hidden>Choose an option...</option>
            <option value="Single">Single</option>
            <option value="Married">Married</option>
            <option value="Domestic Partnership">Domestic Partnership</option>
            <option value="Divorced">Divorced</option>
            <option value="Separated">Separated</option>
            <option value="Widowed">Widowed</option>
          </select>
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Education:</label>
        <div class="w-2/3">
          <select
            v-model="employee.education"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
          >
            <option value="null" hidden>Choose an option...</option>
            <option value="elementary">Elementary</option>
            <option value="secondary">Secondary</option>
            <option value="high_school">High School</option>
            <option value="undegraduate">Undegraduate</option>
            <option value="graduate">Graduate</option>
            <option value="posgraduate">Posgraduate</option>
          </select>
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">Specialty:</label>
        <div class="w-2/3">
          <input
            type="text"
            v-model="employee.specialty"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder="Specialty..."
          />
        </div>
      </div>
      <hr />

      <div
        class="bg-blue-200/80 border-[1px] border-blue-300 rounded-lg p-4 text-blue-800"
      >
        Job Information
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>Location:
        </label>
        <div class="w-2/3">
          <select
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            :class="{
              'border-red-500': submitted && $v.employee.location.$error,
            }"
            id="locationUser"
            v-model="employee.location"
          >
            <option value="null" hidden>Choose a Location...</option>
            <option value="AGS">AGS</option>
            <option value="MTY">MTY</option>
            <option value="QRO">QRO</option>
          </select>
          <div
            v-if="submitted && !$v.employee.location.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a location
          </div>
        </div>
      </div>
      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>Department:
        </label>
        <div class="w-2/3">
          <select
            v-model="employee.departmentSelected"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            :class="{
              'border-red-500':
                submitted && $v.employee.departmentSelected.$error,
            }"
            @change="loadCampaigns"
          >
            <option value="null" hidden>Choose a department...</option>
            <option
              v-for="department in getDepartments"
              :key="department.id"
              :value="department"
            >
              {{ department.label }}
            </option>
          </select>
          <div
            v-if="submitted && !$v.employee.departmentSelected.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a department
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>Campaign:
        </label>
        <div v-if="DisplayLoadingCampaign" class="w-2/3 flex flex-col">
          <loading />
          <strong class="mx-auto"> Loading campaigns...</strong>
        </div>
        <div v-if="DisplayLabelCampaign" class="w-2/3 text-left">
          <label>Please, select a department first...</label>
        </div>
        <div v-if="DisplayCampaignSelect" class="w-2/3">
          <select
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            :class="{
              'border-red-500': submitted && $v.employee.campaign.$error,
            }"
            v-model="employee.campaign"
            @change="loadLobs"
          >
            <option value="null" hidden>Choose a campaign...</option>
            <option
              v-for="campaign in getCampaigns"
              :key="campaign.id"
              :value="campaign"
            >
              {{ campaign.label }}
            </option>
          </select>
          <div
            v-if="submitted && !$v.employee.campaign.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a campaign
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left"
          ><a class="text-red-500">*</a>Title:</label
        >
        <div v-if="DisplayLoadingTitle" class="w-2/3 flex flex-col">
          <loading />
          <strong class="mx-auto"> Loading titles...</strong>
        </div>
        <div v-if="DisplayLabelTitle" class="w-2/3 text-left">
          <label>Please, select a department first...</label>
        </div>
        <div v-if="DisplayTitleSelect" class="w-2/3">
          <select
            v-model="employee.titleSelected"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            :class="{
              'border-red-500': submitted && $v.employee.titleSelected.$error,
            }"
          >
            <option value="null" hidden>Choose a title...</option>
            <option v-for="title in getTitles" :key="title.id" :value="title">
              {{ title.label }}
            </option>
          </select>
          <div
            v-if="submitted && !$v.employee.titleSelected.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a title
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>LOB (Line of Business):
        </label>
        <div v-if="DisplayLoadingLOB" class="w-2/3 flex flex-col">
          <loading />
          <strong class="mx-auto"> Loading LOBs...</strong>
        </div>
        <div v-if="DisplayLabelLOB" class="w-2/3 text-left">
          <label>Please, select a campaign first...</label>
        </div>
        <div v-if="DisplayLOBSelect" class="w-2/3">
          <select
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            :class="{ 'border-red-500': submitted && $v.employee.lob.$error }"
            v-model="employee.lob"
          >
            <option value="null" hidden>Choose a line of business...</option>
            <option v-for="lob in getLob" :key="lob.id" :value="lob">
              {{ lob.label }}
            </option>
          </select>
          <div
            v-if="submitted && !$v.employee.lob.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a lob
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>Report To:
        </label>
        <div v-if="DisplayLoadingReport" class="w-2/3 flex flex-col">
          <loading />
          <strong class="mx-auto"> Loading Report to...</strong>
        </div>
        <div v-if="DisplayLabelReport" class="w-2/3 text-left">
          <label>Please, select a campaign first...</label>
        </div>
        <div v-if="DisplayReportSelect" class="w-2/3">
          <select
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            :class="{
              'border-red-500': submitted && $v.employee.reportTo.$error,
            }"
            v-model="employee.reportTo"
          >
            <option value="null" hidden>
              Choose the Immediate Supervisor...
            </option>
            <option
              v-for="reportTo in getReportTo"
              :key="reportTo.Team_id"
              :value="reportTo.Emp_id"
            >
              {{ reportTo.Emp_id }}
            </option>
          </select>
          <div
            v-if="submitted && !$v.employee.reportTo.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a team.
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>Hired Date:
        </label>
        <div class="w-2/3">
          <datepicker
            :class="{
              'border-red-500': submitted && $v.employee.hired_date.$error,
            }"
            v-model="employee.hired_date"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            :format="customFormatter"
            placeholder=" dd/mm/aaaa"
            name="hiredDateUser"
          ></datepicker>
          <div
            v-if="submitted && !$v.employee.hired_date.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a hired date.
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>Certification Date:
        </label>
        <div class="w-2/3">
          <datepicker
            :class="{
              'border-red-500': submitted && $v.employee.cert_date.$error,
            }"
            v-model="employee.cert_date"
            :format="customFormatter"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder=" dd/mm/aaaa"
            name="certDateUser"
          ></datepicker>
          <div
            v-if="submitted && !$v.employee.cert_date.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a certification date.
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left">
          <a class="text-red-500">*</a>Start Date:
        </label>
        <div class="w-2/3">
          <datepicker
            :class="{
              'border-red-500': submitted && $v.employee.start_date.$error,
            }"
            v-model="employee.start_date"
            :format="customFormatter"
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            placeholder=" dd/mm/aaaa"
            name="startDateUser"
          ></datepicker>
          <div
            v-if="submitted && !$v.employee.start_date.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            Please, select a start date.
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <label class="w-1/3 my-auto text-left error has-error">
          <a class="text-red-500">*</a>PO #:</label
        >
        <div class="w-2/3">
          <input
            type="number"
            v-model="employee.po_id"
            placeholder="Write the Purchase Order Number..."
            class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
            id="notesUser"
            :class="{ 'border-red-500': submitted && $v.employee.po_id.$error }"
          />
          <div
            v-if="submitted && !$v.employee.po_id.required"
            class="text-red-500 text-xs text-left mt-1"
          >
            PO Number is required.
          </div>
        </div>
      </div>

      <div class="space-y-3">
        <hr />
        <div
          class="bg-blue-200/80 border-[1px] border-blue-300 rounded-lg p-4 text-blue-800"
        >
          Schedule Information (Optional)
        </div>
        <div class="flex space-x-2">
          <div>
            <label class="sr-only" for="inlineFormInput">Name</label>
            <select
              v-model="dowToAdd"
              class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
              placeholder=" dd/mm/aaaa"
              id="dayOfWeek"
            >
              <option value="null" hidden>Select a DOW</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
          <div>
            <label class="sr-only" for="inlineFormInputGroup">Event</label>
            <div class="input-group mb-2">
              <select
                v-model="eventToAdd"
                class="bg-white rounded-lg p-2 border-[1px] border-gray-300 block w-full"
                placeholder=" dd/mm/aaaa"
                id="newScheduleRow"
              >
                <option value="null" hidden>Select a type event</option>
                <option value="Shift">Shift</option>
                <option value="Break">Break</option>
                <option value="Lunch">Lunch</option>
              </select>
            </div>
          </div>
          <div>
            <button
              type="button"
              @click="addScheduleRow"
              class="bg-red-500 text-white px-6 py-2 rounded"
            >
              New row
            </button>
          </div>
        </div>
        <vue-timepicker
          class="bg-white rounded-lg p-2 border-[1px] border-gray-300 w-full"
          hidden
        ></vue-timepicker>
        <form id="scheduleNewHire">
          <table id="scheduleTable" class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  DOW
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  is day off?
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Event Type
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Start time
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  End time
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody
              v-if="!scheduleDays > 0"
              class="bg-white divide-y divide-gray-200"
            >
              <th colspan="7">Add days to the schedule</th>
            </tbody>
            <tbody
              v-else
              class="bg-white divide-y divide-gray-200 bodyTable"
            ></tbody>
          </table>
        </form>
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          <button
            :disabled="this.isClicked"
            @click="saveEmployee"
            class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded"
          >
            Save New Employee
          </button>
        </div>
        <div class="col"></div>
      </div>
    </form>
  </div>
</template>
<script>
import { required, helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[a-zA-Z]*$/);
const alphaWspace = helpers.regex("alpha", /^[a-z A-Z]*$/);
import { mapGetters, mapState, mapActions } from "vuex";
import { TheMask } from "vue-the-mask";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueTimepicker from "vue2-timepicker";
import loading from "@/views/mockups/loading";
export default {
  components: { Datepicker, TheMask, VueTimepicker, loading },
  data () {
    return {
      isClicked: false,
      isLoading: true,
      dowToAdd: null,
      scheduleDays: 0,
      eventToAdd: null,
      employee: {
        name: null,
        middle_name: null,
        first_lastName: null,
        second_lastName: null,
        maritalStatus: null,
        education: null,
        specialty: null,
        birthday: null,
        phoneOne: null,
        phoneTwo: null,
        addressOne: null,
        addressTwo: null,
        stateSelected: null,
        citySelected: null,
        zip: null,
        email: null,
        gender: null,
        isBilingual: null,
        titleSelected: null,
        location: null,
        departmentSelected: null,
        campaign: null,
        lob: null,
        reportTo: null,
        hired_date: null,
        cert_date: null,
        start_date: null,
        notes: null,
        po_id: null,
        scheduleInfo: [],
      },

      // City Select
      selectState: true,
      showLoadingCity: false,
      showSelectCity: false,
      // Campaign Select
      DisplayCampaignSelect: false,
      DisplayLabelCampaign: true,
      DisplayLoadingCampaign: false,
      //Title Select
      DisplayLoadingTitle: false,
      DisplayLabelTitle: true,
      DisplayTitleSelect: false,
      DisplayLoadingLOB: false,
      DisplayLabelLOB: true,
      DisplayLOBSelect: false,
      DisplayLoadingReport: false,
      DisplayLabelReport: true,
      DisplayReportSelect: false,

      submitted: false,
      defaultNull: null,
      title: "New Employees",
    };
  },
  validations: {
    employee: {
      name: { required, alpha },
      first_lastName: { required, alphaWspace },
      location: { required },
      departmentSelected: { required },
      campaign: { required },
      titleSelected: { required },
      lob: { required },
      hired_date: { required },
      cert_date: { required },
      start_date: { required },
      reportTo: { required },
      po_id: { required },
    },
  },
  computed: {
    ...mapState(["definitions", "employees", "cities", "states"]),
    ...mapGetters([
      "getCities",
      "getRoles",
      "getTitles",
      "getLob",
      "getLob",
      "getDepartments",
      "getTeams",
      "getCampaigns",
      "getReportTo",
      "stateList",
      "getNewEmployeeStatus",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchCitiesByState",
      "fetchRoles",
      "fetchTitlesByDepartment",
      "fetchCampaignsByDepartment",
      "fetchLobByCampaign",
      "fetchDepartments",
      "fetchTeams",
      "fetchReportToByCampaign",
      "fetchStates",
      "saveNewEmployee",
    ]),
    removeRow: function (RowID) {
      event.preventDefault();
      console.log(RowID);
      var tableBody = document
        .getElementById("scheduleTable")
        .getElementsByTagName("tbody")[0];
      tableBody.deleteRow(-1);
    },
    addScheduleRow: function () {
      if (!this.dowToAdd === null || !this.dowToAdd) {
        this.$fire({
          type: "warning",
          title: "Please Select a day of week before",
          showConfirmButton: true,
        });
        return false;
      }
      if (this.eventToAdd === null || !this.eventToAdd) {
        this.$fire({
          type: "warning",
          title: "Please Select an event before",
          showConfirmButton: true,
        });
        return false;
      }
      this.scheduleDays++;
      var tableBody = document
        .getElementById("scheduleTable")
        .getElementsByTagName("tbody")[0];
      var newRow =
        '<tr id="newRow"><td class="px-6 py-4 whitespace-nowrap">' +
        this.dowToAdd +
        '<input name="scheduleDay[]"  type="hidden" value="' +
        this.dowToAdd +
        '"></td><td class="px-6 py-4 whitespace-nowrap"><label><input type="checkbox" name="scheduleDayOff[]" value="true"> Is day off?</label></td><td class="px-6 py-4 whitespace-nowrap"><input name="scheduleEvent[]" type="hidden" value="' +
        this.eventToAdd +
        '"><label>' +
        this.eventToAdd +
        '</label></td><td class="px-6 py-4 whitespace-nowrap"><input name="startTime[]" type="time"></td><td class="px-6 py-4 whitespace-nowrap"><input name="endTime[]" type="time" ></td><td><a style="cursor:pointer" class="deleteRow" id="deleteRow' +
        this.scheduleDays +
        '" v-on:click="removeRow(' +
        this.scheduleDays +
        ')" >Remove</a></td></tr>';
      tableBody.insertRow(-1).innerHTML = newRow;
      document.querySelectorAll(".deleteRow").forEach((button) => {
        button.addEventListener("click", this.removeRow);
      });
    },
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    loadCities: function () {
      this.selectState = false;
      this.showLoadingCity = true;
      this.showSelectCity = false;
      this.fetchCitiesByState(this.employee.stateSelected.id).then(() => {
        this.showLoadingCity = false;
        this.showSelectCity = true;
      });
    },
    loadCampaigns: function () {
      // Clean variables
      this.employee.campaign = null;
      this.employee.titleSelected = null;

      this.employee.reportTo = null;
      this.DisplayLabelReport = true;
      this.DisplayReportSelect = false;

      this.employee.lob = null;
      this.DisplayLabelLOB = true;
      this.DisplayLOBSelect = false;
      // End Clean variables
      let department_id = this.employee.departmentSelected.id;
      this.loadTitle(department_id);
      this.DisplayLoadingCampaign = true;
      this.DisplayLabelCampaign = false;
      this.DisplayCampaignSelect = false;
      this.fetchCampaignsByDepartment(department_id).then(() => {
        this.DisplayLoadingCampaign = false;
        this.DisplayCampaignSelect = true;
      });
    },
    loadTitle: function (id) {
      this.employee.titleSelected = null;
      this.DisplayLabelTitle = false;
      this.DisplayLoadingTitle = true;
      this.DisplayTitleSelect = false;
      this.fetchTitlesByDepartment(id).then(() => {
        this.DisplayTitleSelect = true;
        this.DisplayLoadingTitle = false;
      });
    },
    loadLobs: function () {
      this.employee.lob = null;
      let campaign_id = this.employee.campaign.id;
      this.loadReportTo(campaign_id);
      this.DisplayLabelLOB = false;
      this.DisplayLoadingLOB = true;
      this.DisplayLOBSelect = false;
      this.fetchLobByCampaign(campaign_id).then(() => {
        this.DisplayLOBSelect = true;
        this.DisplayLoadingLOB = false;
      });
    },
    loadReportTo: function (id) {
      this.employee.reportTo = null;
      this.DisplayLoadingReport = true;
      this.DisplayLabelReport = false;
      this.DisplayReportSelect = false;
      this.fetchReportToByCampaign(id).then(() => {
        this.DisplayReportSelect = true;
        this.DisplayLoadingReport = false;
      });
    },
    saveEmployee: function () {
      event.preventDefault();
      this.submitted = true;
      let isThereAnError = null;
      let arraySchedule = new Array();
      var tableRows = document.querySelectorAll("#scheduleTable > tbody > tr");
      if (tableRows.length > 0) {
        tableRows.forEach(function (tableTR) {
          let insideElement = tableTR.getElementsByTagName("input");
          let arrayDay = new Array();
          let isDayOff = false;
          insideElement.forEach(function (tableInputs) {
            if (tableInputs.type == "checkbox") {
              arrayDay.push(tableInputs.checked);
              if (tableInputs.checked) {
                isDayOff = true;
              }
            } else {
              if (
                (tableInputs.getAttribute("name") === "startTime[]" ||
                  tableInputs.getAttribute("name") === "endTime[]") &&
                !isDayOff
              ) {
                if (
                  tableInputs.value === "" ||
                  tableInputs.value === undefined
                ) {
                  isThereAnError = true;
                  return false;
                }
              }
              arrayDay.push(tableInputs.value);
            }
          });
          arraySchedule.push(arrayDay);
        });
        this.employee.scheduleInfo = arraySchedule;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$fire({
          type: "error",
          title: "Please fill the missing fields.",
          showConfirmButton: true,
        });
        return "";
      }
      if (isThereAnError) {
        this.$fire({
          type: "warning",
          title: "Please, the start time and end time need to have values.",
          showConfirmButton: true,
        });
        return false;
      }
      this.isClicked = true;
      this.saveNewEmployee(this.employee)
        .then(() => {
          if (this.getNewEmployeeStatus == 201) {
            this.$fire({
              type: "success",
              title: "The employee has been created successfully",
              showConfirmButton: false,
              timer: 1300,
            }).then(() => {
              location.reload();
            });
          }
        })
        .catch(() => {
          this.$fire({
            type: "error",
            title: "Oops! Something went wrong",
            text: "please try again",
            showConfirmButton: false,
            timer: 1300,
          }).then(() => {
            this.isClicked = false;
          });
        });
    },
    readyStates: function () {},
  },
  created() {
    this.$store.dispatch("fetchDepartments").then(() => {
      this.isLoading = false;
    });
    this.fetchStates().then(() => {
      this.$emit("readyStatus", true);
    });
  },
  mounted() {
    this.$emit("set-title", this.title);
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
form {
  border: 2px solid #f7f7f9;
  padding: 2em;
  border-radius: 15px;
}
</style>