<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
<div class="flex md:w-7/12  mb-5 sm:w-full mx-auto shadow-lg rounded-sm relative mt-5 bg-white">    
    <div class="w-full overflow-auto border-b border-gray-50 sm:rounded-lg">            
        <h4 class="text-center text-md text-gray-600 w-full p-2">Adjustment Requests</h4>
    <table class="min-w-full divide-y divide-gray-50">
        <thead class="bg-gray-50">
        <tr>              
            <th scope="col" class="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ID
            </th>
            <th scope="col" class="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
            </th>
            <th scope="col" class="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Employee
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            L.O.B
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Campaign
            </th>               
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Report to
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Event
            </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Reason
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Duration
            </th>  
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Requested By
            </th>            
        </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-100" v-if="getPayrollRequests.length > 0">
            <tr v-for="(item,index) in getPayrollRequests" :key="index">
                 <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                {{item.Request_id}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                {{item.Event_date}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                {{item.Emp_id}}
                </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                {{item.LOB}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                {{item.Campaign}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                {{item.ReportsTo}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                {{item.Event_type}}
                </td>               
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                {{item.Reason}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                    {{item.Duration}}
                </td>   
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                {{item.RequestedBy}}
                </td>              
            </tr>

        <!-- More items... -->
        </tbody>
        <tbody class="bg-white divide-y divide-gray-100" v-else>
            <tr>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                    No data available.
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                   
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
               
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">                
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                   
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">                
                </td>
            </tr>

        <!-- More items... -->
        </tbody>
    </table>
    </div>
</div>

</template>

<script>
import {mapGetters,mapActions} from 'vuex';
export default {
    computed:{
        ...mapGetters(['getPayrollRequests'])
    },
    methods:{
        ...mapActions(['getPayrollRequestsByEmpID']),
        fetchRequests(){
            let data ={ 
                empId:this.$route.params.empId,
                nowParam:this.$route.params.nowParam
            }
                
            this.getPayrollRequestsByEmpID(data);
        }
    },
    beforeMount(){
        this.fetchRequests();
    },
}
</script>