<template>
  <form @submit.prevent="beforeSubmit" class="relative w-full h-full flex flex-col items-center justify-start overflow-y-scroll py-3 pt-5 pb-5">
        <div class="lg:w-1/3 md:w-1/2 sm:w-10/12 shadow-3xl bg-white">
        <div class="relative mx-auto bg-blue-200 w-16 h-16 rounded-full ring ring-blue-300 -mt-8 mb-3 flex items-center justify-center select-none">
           <span class="font-semibold text-white text-2xl">{{employee_initials}}</span>
       </div>
        <div>
            <h4>
                <span v-if="getCandidate.profile">
                    Edit {{(getCandidate.profile.name + ' '  + getCandidate.profile.first_last_name) }} 
                </span>
                    <span v-else>
                    Edit Candidate
                </span>
            </h4>
        </div>
            <div class="pb-3 px-4" >
        <div>
        
        <div class="flex flex-row flex-wrap">
                <!-- #RegisterForStart-->
                <div class="w-1/2 pr-2 text-left mt-2">
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Name:*</label>
                    <input v-model="form.name"  :class="$v.form.name.$invalid && $v.form.$dirty ? errorClass: ''" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                    <small class="text-red-400 ml-2 text-xs" v-if="$v.form.name.$invalid && $v.form.$dirty">This field is required.</small>
                </div>
                <div class="w-1/2 text-left pl-2 mt-2">
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Middle name:</label>
                    <input v-model="form.middle_name" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                
                </div>
                <div class="w-1/2 pr-2 text-left mt-2">
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">First Last Name:*</label>
                    <input :class="$v.form.first_last_name.$invalid && $v.form.$dirty ? errorClass: ''" v-model="form.first_last_name" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                    <small class="text-red-400 ml-2 text-xs" v-if="$v.form.name.$invalid && $v.form.$dirty">This field is required.</small>
                </div>
                <div class="w-1/2 pl-2 text-left mt-2">
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Second Last Name:</label>
                    <input v-model="form.second_last_name" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                </div>
                <div class="sm:w-full md:w-1/2 text-left mt-2 pr-2">
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Site:*</label>
                    <select :class="$v.form.site_id.$invalid && $v.form.$dirty ? errorClass: ''" class="w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400 "   v-model="form.site_id">
                        <option hidden value="">Select a location...</option>
                        <option value="MTY">Monterrey</option>}
                        <option value="QRO">Queretaro</option>
                        <option value="AGS">Aguascalientes</option>
                        <option value="LB">Longview</option>
                    </select>
                    <small class="text-red-400 ml-2 text-xs" v-if="$v.form.site_id.$invalid && $v.form.$dirty">This field is required.</small>
                </div>
                <div class="mt-2 sm:w-full md:w-1/2 text-left pl-2">
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Source:*</label>
                    <my-source :source="form.source_id"  :error="$v.form.site_id.$invalid && $v.form.$dirty" @change="setSource"/>
                    <small class="text-red-400 ml-2 text-xs" v-if="$v.form.source_id.$invalid && $v.form.$dirty">This field is required.</small>
                </div>
                <Transition name="fade">
            <div class="sm:w-full text-left mt-2"  v-show="hasReferral">
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Referral id:*</label>
                    
                    <multiselect :options="getTeamMembers" v-model="referralObject" label="name" track-by="id" :allow-empty="false"/>
                    <small class="text-red-400 ml-2 text-xs" v-if="$v.form.referral_id.$invalid && $v.form.$dirty">This field is required.</small>
                    
                </div>
                </Transition>
        </div>
        </div>
        
        
        </div>
            <div class="px-4 pb-3 flex flex-row flex-wrap">
            
                <div class="w-full pr-2 text-left mt-2" >
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Curp:*</label>
                    <input maxlength="18" :class="$v.form.curp.$invalid && $v.form.$dirty ? errorClass: ''" v-model="form.curp" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                    <small class="text-red-400 ml-2 text-xs" v-if="$v.form.curp.$invalid && $v.form.$dirty">Please provide a valid curp.</small>
                </div>


                <div class="w-1/2 pr-2 text-left mt-2" >
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Phone:*</label>
                    <input maxlength="10" :class="$v.form.phone.$invalid && $v.form.$dirty ? errorClass: ''" v-model="form.phone" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                    <small class="text-red-400 ml-2 text-xs" v-if="$v.form.phone.$invalid && $v.form.$dirty">Please provide a valid 10 digits phone.</small>
                </div>
                <div class="w-1/2 pr-2 text-left mt-2" >
                    <label class="px-2 text-gray-500 text-xs font-thin m-0">Email:*</label>
                    <input :class="$v.form.email.$invalid && $v.form.$dirty ? errorClass: ''" v-model="form.email" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                    <small class="text-red-400 ml-2 text-xs" v-if="$v.form.email.$invalid && $v.form.$dirty">Please provide a valid email.</small>
                </div>
                <button :disabled="loading" type="submit" class="w-full bg-gray-700 py-3 px-3 text-white rounded-sm mt-4 text-white">
                    
                    <span v-if="!loading">Update profile</span>
                    <span v-else>
                        Saving 
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                        <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                        </svg>
                    </span>
                </button>
            </div>
        </div>
        <div class="lg:w-1/3 md:w-1/2 sm:w-10/12 shadow-3xl mt-5">
            <delivery-details  :files="getCandidate.deliveries" v-if="getCandidate.profile"/>
            <div v-else class="animate-pulse">
                Loading files ...
            </div>
        </div>

        <div class="lg:w-1/3 md:w-1/2 sm:w-10/12 shadow-3xl mt-5">
            <terminate-candidate/>
        </div>
  </form>
</template>

<script>

import {required, requiredIf, email} from 'vuelidate/lib/validators'
import {mapGetters, mapActions} from 'vuex'
import MySource from '../../components/dropdowns/CandidateSource'
import DeliveryDetails from '../../components/misc/DeliveryDetails'
import TerminateCandidate from '../../components/partials/TerminateCandidate'
export default {
    components:{ MySource, DeliveryDetails, TerminateCandidate},
    data(){
        return {
            useCurp : true,
            loading: false, 
            form:{
                middle_name:'',
                second_last_name:'',
                name:'',
                first_last_name:'',
                site_id:'',
                source_id:'',
                recruiter_id:'',
                referral_id:'',
                curp:'',
                phone:'',
                email:'',
                id:''
            },
            referralObject:{}
        }
    },
    created(){
        this.$emit('set-title', 'Edit Candidate');
        this.getCandidateProfile(this.$route.params.id).then(()=>{
            this.form.name = this.getCandidate.profile.name;
            this.form.curp = this.getCandidate.profile.curp;
            this.form.phone = this.getCandidate.profile.phone;
            this.form.email = this.getCandidate.profile.email;
            this.form.middle_name = this.getCandidate.profile.middle_name;
            this.form.second_last_name = this.getCandidate.profile.second_last_name;
            this.form.first_last_name = this.getCandidate.profile.first_last_name;
            this.form.site_id = this.getCandidate.profile.site_id;
            this.form.source_id = this.getCandidate.profile.source_id;
            this.form.referral_id = this.getCandidate.profile.referral_id;
            this.form.first_last_name = this.getCandidate.profile.first_last_name;
            this.form.id = this.getCandidate.profile.id;
            this.referralObject =  this.getCandidate.profile.referral;
        }).catch(()=>{
            this.$fire({
                        type: 'warning',
                        title:`The selected candidate doesn't exist. If you think this is an error, please contact an administrator.`,
                        showConfirmButton: true,                
            }).then(()=>{
                 this.$router.push({name:'recruitment.candidates'});     
            });  
             
        });
        this.fetchRecruiters();
        this.fetchTeamMembers();
    },
    computed:{
        ...mapGetters(['getCandidate','getTeamMembers','getRecruitersList','employeeList']),
        activeClass:function(){
            return 'border-b-4 border-gray-700';
        },
        defaultClass:function(){
            return 'hover:underline';
        },
        employee_initials:function(){
            if(this.form.name !== '' && this.form.first_last_name !== ''){
                return  this.form['name']?.charAt(0) + this.form['first_last_name']?.charAt(0);
            }

            return '';
        },
        errorClass:function(){
            return 'bg-red-50 border-red-200';
        },
        hasReferral:function(){
            return this.form.source_id == 2;
        },
        isLoading:function(){
            return this.loadingReferrals || this.loadingRecruiters;
        },
    },
    methods:{
        ...mapActions(['registerEMSCandidate','getCandidateProfile','fetchRecruiters','fetchTeamMembers','updateCandidate']),
        beforeSubmit:function(){
            this.loading = true;

            this.$v.$touch();  

           if(this.$v.form.$invalid){
         
                return this.$fire({
                    type: 'error',
                    title: 'Fill the mandatory fields before submit.',
                    showConfirmButton: true
                    }).then(()=>{ this.loading = false;});   
            }
                 this.loading = true;
                 this.updateCandidate(this.form).then(()=>{

                    this.$fire({
                        type: 'success',
                        title:`The candidate has been stored successfully`,
                        showConfirmButton: true,                  
                    });  
                     this.loading = false;  
                    this.$router.push({name:'recruitment.candidates'});     

                })
                .catch((error)=>{
                    let message =  error.response.data.message ?? 'Something went wrong';
                    this.loading = false;  
                    this.$fire({
                        type: 'warning',
                        title:`Something went wrong.Error message: ${message}`,
                        showConfirmButton: true,                
                    });    
                    
                })
          
    
      },
        updateForm:function(form){
            this.form.name = form.name;
            this.form.first_last_name = form.first_last_name;
            this.form.second_last_name = form.second_last_name;
            this.form.middle_name = form.middle_name;
            this.form.source_id = form.source_id;
            this.form.middle_name = form.middle_name;
            this.form.referral_id = form.referral_id;
            this.form.recruiter_id = form.recruiter_id;  
            this.form.site_id = form.site_id;
        },
        getSwitchStyle:function(active = true){
            return active ? 'text-green-300  bg-green-100 ring-2 ring-green-200 rounded-sm px-2 text-xs' :'text-gray-400 text-xs cursor-pointer hover:text-gray-600'
        },
        setSource:function(value){
            this.form.source_id = value;
            if(value != 2){
                 this.form.referral_id = '';
                 this.referralObject = null;
            }
           
        }
    },
    validations:{
        form:{
            name:{required},
            first_last_name:{required},
            source_id:{required},
            site_id:{required},
            referral_id: {
                required: requiredIf(function() {
                    return this.form.source_id == 2 ;
                }) 
            },
            curp: {
                required: requiredIf(function() {
                    return this.form.phone == '' || this.form.email == '';
                }),
                format:function(){
                      let regex =/^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;

    
                    return (this.form.phone != '' && this.form.email != '') || this.form.curp?.length == 18 && regex.test(this.form.curp) ;
                }
            },
            phone:{
                required: requiredIf(function() {
                    return this.form.curp == '' ;
                }),
                length:function(){
                    return this.form.curp != '' || this.form.phone.length == 10;
                }
            },
            email:{
                required: requiredIf(function() {
                    return this.form.curp == '' ;
                }),
                format:function(value){
                    return this.form.curp != '' || email(value);
                }
            },

        }
    },
    watch:{
        'form.phone':function(){
            this.form.phone = this.form.phone?.replace(/[^0-9]+/g, '');  
        },
        'form.curp':function(){
            this.form.curp = this.form.curp?.toUpperCase();  
        },
        referralObject:function(newVal){
            this.form.referral_id = newVal?.id;
        }
    },
    
}
</script>

<style>
.shadow-fade{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
</style>