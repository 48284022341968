<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Adjustment Request</h3>
        </div>
      </div>
    </div>
    <div :class="{ hide: !isLoading}" class="row">
      <div class="col text-center">
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <div :class="{ hide: isLoading}">
      <div>
        <b-table stacked :items="tableItems"></b-table>
      </div>
      <div class="row">
        <div class="col">
          <button type="button" @click="cancelData" class="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded">Cancel Request</button>
          <button type="button" class="bg-gray-200 px-6 py-2 rounded">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      time: null,
      adjustment: {
        reason: null,
      },
      isLoading: true,
      tableItems: [],
    };
  },
  created(){
    let fetchObj = {
      'request_id': this.requestID.Request_id
    };
    console.log(this.requestID.Request_id);
    this.fetchRequestInfo(fetchObj).then(() =>{
      let itemsToDisplay = [
        { 
          request_id:   this.requestID.Request_id,
          employee_id:  this.getRequestInfo.Emp_id, 
          created_date: this.getRequestInfo.Event_date, 
          duration:     (this.getRequestInfo.Duration/60).toFixed(2) + ' Minutes',
          event:        this.getRequestInfo.Event_type,
        }
      ];
      this.tableItems = itemsToDisplay;
      this.isLoading = false;
    });
  },
  props: {
    requestID: Object,
  },
  computed: {
    ...mapState(["AccountManagerClocker"]),
    ...mapGetters(["loadingFlag","getRequestInfo"]),
    requestedItemId:function(){ return this.requestID.Request_id},
  },
  methods: {
    ...mapActions(['fetchRequestInfo','denyPayrollRequest']),
    cancelData: function () {
      this.$confirm("Do you want to continue?", "You're about to cancel a requests", "warning").then((result) => {
        if (result){
          this.denyPayrollRequest({idRequest:this.requestedItemId}).then(()=>{
            this.$fire({
              type: "success",
              title: "Request cancelled",
              showConfirmButton: false,
              timer: 1300,
            }).then(()=>{
              this.$router.go(0);                
            }); 
          });
        }
      })
    },
  },
};
</script>
<style lang="scss">
.display-time {
  border: none !important;
  cursor: pointer !important;
  height: auto !important;
  padding: 0 !important;
  width: 100% !important;
}
</style>