<template>
    <div class="todayActivity px-4 py-8">
        <h1 class="text-4xl font-thin mb-3 text-center">Clocker Activity {{employee}}</h1>
        <div v-if="isLoading" class="text-center">
          <div class="lds-ripple"><div></div><div></div></div>
        </div>
        <div v-else>
          <p class="text-base font-normal text-slate-600 m-0">Activity to start</p>
          <select class="w-full p-2 border-slate-100 border rounded-md shadow-sm mb-4" v-model="activitySelected" @change="isCommentRequired" >
            <option hidden :value="null">Choose an Activity...</option>
            <optgroup v-for="(elements, key, index) in activitiesDropdown" :key="index" :label="key" >
              <option v-for="(event,index) in elements" :value="event" :key="index">{{event}}</option>
            </optgroup>
          </select>
          <div v-if="this.getIsCommentRequired == 1 && this.commentReset" class="mb-4">
            <p class="text-base font-normal text-slate-600 m-0">Add a comment</p>
            <b-form-textarea class="w-full p-2 border-slate-100 border rounded-md shadow-sm resize-none" v-model="activityComment" placeholder="Please add a comment..." rows="3" max-rows="3"></b-form-textarea>
          </div>
          <div class="text-center">
            <button type="button" @click="saveNewActivity" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
          </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapState, mapActions } from 'vuex'
import { ClientJS }                       from 'clientjs';
import auth                               from "@/config/user"
export default {
  props:{
    employee: {
      type: String,
      default: () => auth.currentUser.EmpID
    },
    fromOrigin: {
      type: String,
      default: 'clocker'
    }
  },
  data(){
      return{
          isLoading: true,
          activitySelected: null,
          commentReset: false,
          activityComment: '',
          activitiesDropdown: []
      }
  },
  computed:{
    ...mapState([
      'clockerUtility'
    ]),
    ...mapGetters([
      'getActivitiesObject',
      'getIsCommentRequired',
      'getIP',
      'getAllEventsPerCampaign'
    ])
  },
  methods:{
    ...mapActions([
      'getDropdownActivities',
      'checkIsRequiredComment',
      'startNewActivity',
      'getIPFromClient',
      'fetchEventsPerCampaign'
    ]),
    saveNewActivity: function(){
      var client = new ClientJS();
      let activityObject = {};
      if(this.getIsCommentRequired === 1){
        if(this.activityComment !== ''){
          activityObject = {
            activity:           this.activitySelected,
            comment_request:    this.activityComment,
            EmpID:              this.employee.toUpperCase(),
            Location:           this.Location,
            ClientIP:           this.getIP,
            ActionTriggered:    this.activitySelected.toUpperCase(),
            UserAgent:          client.getUserAgent(),
            DeviceOS:           client.getOS(),
            DeviceFingerprint:  client.getFingerprint(),
          }
        }else{
          this.$fire({
            type: "error",
            title: "Oops! You need to add a comment",
            showConfirmButton: true,
          });
        }
      }else{
        activityObject = {
          activity: this.activitySelected,
          EmpID:    this.employee.toUpperCase(),
          Location: this.Location,
          ClientIP: this.getIP,
          ActionTriggered: this.activitySelected.toUpperCase(),
          UserAgent: client.getUserAgent(),
          DeviceOS: client.getOS(),
          DeviceFingerprint: client.getFingerprint(),
          url: window.location.pathname
        }
      }
      this.isLoading = true;
      this.startNewActivity(activityObject).then(()=>{
        this.$fire({
          type: "success",
          title: "New Activity started successfully",
          showConfirmButton: false,
          timer: 1300,
        }).then(()=>{
          this.$emit('toggle', true);
          this.commentReset = false;
          this.isLoading = false;
        })
      }).catch((error)=>{
        this.$fire({
          type: 'warning',
          title:`Something went wrong`,
          text: `Details: ${error.response.data.message ?? 'Unexpected error'}`,
          showConfirmButton: true,                
        }).then(()=>{
          this.$emit('toggle', true);
        });
      }); 
    },
    isCommentRequired: function(){
      this.isLoading = true;
      this.checkIsRequiredComment(this.activitySelected).then(()=>{
          this.isLoading = false;
          this.commentReset = true;
      });
    },
  },
  async created() {
    await this.fetchEventsPerCampaign(this.employee);
    await this.getDropdownActivities();
    if(this.fromOrigin === 'clocker'){
      this.activitiesDropdown = this.getActivitiesObject;
    } else {
      this.activitiesDropdown = this.getAllEventsPerCampaign;
    }
    this.isLoading = false;
    await this.getIPFromClient();
  }
}
</script>
<style scoped>
#todayActivities___BV_modal_outer_{
    overflow: hidden;
    z-index: 20001 !important; 
}
</style>
