<template>
    <NotFound>
         <template slot='headline'>
             Page not found
         </template>
          <template slot='subline'>
             <button class="text-blue-400 hover:text-blue-500 hover:underline" type="button" @click.prevent="$router.push('/')">
                 Go back to home page
             </button>
         </template>
    </NotFound>
</template>

<script>
import NotFound from '@/views/mockups/404'
export default {
    mounted(){
        this.$emit('set-title','');
    },
    components:{
        NotFound
    }
}
</script>