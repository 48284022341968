import axios from '@/config/axios'
import { API_URL } from '../actions/config'
const state = {
    clocker_users: [],
    hours: [],
    table_loading: true,
    modal_loading: false,
    myTotalHours: [],
    ApprovalHours: [],
    detailedHours: [],
    isOnReview: false,
    requestInfo: [],
    watchmenRequests: [],
    watchmenRequestsFiltered: [],
    currentApprovalStatus: '',
    infoByApprovalID: [],
    allEventsPerCampaign: [],
    HourReviewTable: [],
    HourReviewTableDetails: [],
    agentInfo: [],
    agentExists: false,
    reqIDExists: false,
    clockerTime: '',
    event_date: null,
    showModal:false,
    dayApprovalStatus: null,
    supervisorApprovalHours:[]
};
const getters = {
    getUsers:                       (state) => state.clocker_users,
    getLoadingModal:                (state) => state.modal_loading,
    getMyHours:                     (state) => state.hours,
    getMyTotalHours:                (state) => state.myTotalHours,
    getApprovalHours:               (state) => state.ApprovalHours,
    getDetailedHours:               (state) => state.detailedHours,
    getStatusReview:                (state) => state.isOnReview,
    getRequestInfo:                 (state) => state.requestInfo,
    getWatchmenRequests:            (state) => state.watchmenRequests,
    getWatchmenRequestsFiltered:    (state) => state.watchmenRequestsFiltered,
    getCurrentStatus:               (state) => state.currentApprovalStatus,
    getInfoByApprovalID:            (state) => state.infoByApprovalID,
    getAllEventsPerCampaign:        (state) => state.allEventsPerCampaign,
    getAgentHourReviewTable:        (state) => state.HourReviewTable,
    getAgentHourReviewTableDetails: (state) => state.HourReviewTableDetails,
    getAgentInfo:                   (state) => state.agentInfo,
    getAgentExist:                  (state) => state.agentExists,
    getReqIDExist:                  (state) => state.reqIDExist,
    getClockerTime:                 (state) => state.clockerTime,
    getEventDate:                   (state) => state.event_date,
    getModalVisibility:             (state) => state.showModal,
    getDayApprovalStatus:           (state) => state.dayApprovalStatus,
    getSAH:                         (state) => state.supervisorApprovalHours
};
const actions = {
    async fetchUsers({ commit }){
        commit('setLoadingTable', true);             
        const endpoint = API_URL + 'api/v1/clocker';
        await axios.get(endpoint).then( (response) =>{
            commit('clocker_users',response.data.data);
            commit('setLoadingTable',false);
        });
    },
    async fetchTotalHours({ commit }){
        commit('setLoadingTable', true);     
        const endpoint = API_URL + 'api/v1/employees/getMyTotalHours';
        await axios.get(endpoint).then( (response) =>{
            commit('setHours',response.data.events);
            commit('setLoadingTable',false);
        });
    },
    async fetchMyHours({ commit }, objectData){
        commit('setLoadingTable', true);     
        const endpoint = API_URL + 'api/v1/employees/getMyHours';
        await axios.post(endpoint, objectData).then( (response) =>{
            commit('setHours',response.data.events);
        });
        const totalHoursEndPoint = API_URL + 'api/v1/employees/getMyTotalHours';
        await axios.post(totalHoursEndPoint, objectData).then( (response) =>{
            commit('setMyTotalHours',response.data.events);
            commit('setLoadingTable',false);
        });
    },
        
    async fetchMyApprovalHoursByTeam({commit}, teamName){  
        commit('setLoadingTable', true);     
        const endpoint = API_URL + 'api/v1/clocker/supervisor/getApprovalHoursByTeams/'+ teamName;
        await axios.get(endpoint).then( (response) =>{   
            commit('setApprovalHours',response.data);
        });
    },

    async fetchMyApprovalHours({ commit }){
        commit('setLoadingTable', true);     
        const endpoint = API_URL + 'api/v1/clocker/supervisor/getApprovalHours';
        await axios.get(endpoint).then( (response) =>{
            commit('setLoadingTable', false);     
            commit('setApprovalHours',response.data);
        });
    },
    async fetchDetailedHours({commit}, employeeObj){
        commit('setLoadingTable', true);
        commit('setDayApprovalStatus', null)
        const endpoint = API_URL + 'api/v1/clocker/supervisor/getDetailedHours';
        await axios.post(endpoint,employeeObj).then( (response) =>{
            commit('setLoadingTable', false);     
            commit('setDetailedHours',response.data.eventsPerDay);
            commit('setEventDate',response.data.event_date);
            commit('setStatusReview',response.data.OnReview);
            commit('setDayApprovalStatus', response.data.dayStatus)
        });
    },
    async clockerSearch ({ commit }, nameEmployee ){
        commit('setLoadingTable',true);
        const endpoint = API_URL + 'api/v1/clocker/' + nameEmployee + '/search';
        await axios.get(endpoint).then( (res) =>{
            commit('setLoadingTable',false);
            if(res.status == 200 && res.data.data.length == 0 ){
                commit('clocker_users', []);
                return 'nodata';
            }
            if(res.status == 200 && res.data.data.length != 0 ){
                commit('clocker_users', res.data.data);
            }
           
        });
    },
    async setNewPassword({ commit }, passwordData ){
        commit('setLoadingModal', true);
        const endpoint = API_URL + 'api/v1/clocker/password/change';
        const response = await axios.post(endpoint,passwordData);
        commit('setLoadingModal', false);
        return response;
    },

    async setCheckPassword({ commit }, passwordData ){
        const endpoint = API_URL + 'api/v1/clocker/password/check';
        const response = await axios.post(endpoint,passwordData);
        commit('setLoadingModal', false);
        return response;
    },

    // No Data requests
    async sendNoDataReason({ commit }, NoReasonObj  ){
        commit('setLoadingTable', true);   
        const endpoint = API_URL + 'api/v1/clocker/supervisor/adjustmentRequest/SendNoDataReason';
        const response = await axios.post(endpoint,NoReasonObj);
        commit('setLoadingTable', false);
        return {
            status: response.status,
        };
    },
    //Request Actions
    async sendAdjustment({ commit }, adjustmentObj  ){
        commit('setLoadingTable', true);   
        const endpoint = API_URL + 'api/v1/clocker/supervisor/adjustmentRequest/new';
        const response = await axios.post(endpoint,adjustmentObj);
        commit('setLoadingTable', false);
        return {
            status: response.status,
        };
    },
    async fetchRequestInfo({ commit }, requestInfo  ){
        commit('setLoadingModal', true);
        const endpoint = API_URL + 'api/v1/clocker/supervisor/adjustmentRequest/getRequestInfo';
        const response = await axios.post( endpoint,requestInfo);
        commit('setRequestInfo', response.data.requestInfo);
    },
    async completeRequest( {commit}, requestInfo ){
        commit('setLoadingTable', true);   
        const endpoint = API_URL + 'api/v1/clocker/adjustmentRequest/changeRequestStatus';
        await axios.post( endpoint,requestInfo).then( (response) =>{
            commit('setLoadingTable', false);     
            return response;
        });
    },
    async cancelRequest( {commit}, requestInfo ){
        commit('setLoadingTable', true);   
        const endpoint = API_URL + 'api/v1/clocker/adjustmentRequest/changeRequestStatus';
        await axios.post( endpoint,requestInfo).then( (response) =>{
            commit('setLoadingTable', false);     
            return response;
        }); 
    },
    async undoRequestAction( {commit}, requestInfo ){
        commit('setLoadingTable', true);   
        const endpoint = API_URL + 'api/v1/clocker/adjustmentRequest/changeRequestStatus';
        await axios.post( endpoint,requestInfo).then( (response) =>{
            commit('setLoadingTable', false);     
            return response;
        }); 
    },
    async approveAllDayRequests({commit}, approval_id ){
        commit('setLoadingTable', true);   
        const endpoint = API_URL + 'api/v1/clocker/supervisor/adjustmentRequest/approveAllDay';
        const response = await axios.post( endpoint,approval_id).then(() =>{
            commit('setLoadingTable', false); 
        }); 
        return response;
 
    },
    async fetchCurrentSupervisorStatus({commit}, approval_id ){
        commit('setLoadingTable', true);   
        const endpoint = API_URL + 'api/v1/clocker/supervisor/adjustmentRequest/getSupervisorClockerApprovalStatus';
        const response = await  axios.post( endpoint,approval_id).then((res) =>{
            commit('setLoadingTable', false); 
            commit('setCurrentStatus', res.data.currentApprovalStatus); 
            return res;
        }); 
        return response;
    },
    //Watchmen Dashboard Filters
    async fetchWatchmentRequests({ commit }){
        commit('setLoadingTable', true);
        
        const endpoint = API_URL + 'api/v1/clocker/watchmen/getAdjustmentRequests';
        await axios.get( endpoint).then( (response) =>{
            commit('setLoadingTable', false);     
            commit('setWatchmenRequests', response.data.AdjustmentsRequests);
        });
        
    },
    async filterMyRequests({commit}, filteredBy ){
        commit('setLoadingTable', true);   
        const endpoint = API_URL + 'api/v1/clocker/watchmen/getAdjustmentRequests/filteredBy';
        await axios.post( endpoint,filteredBy).then((res) =>{
            commit('setLoadingTable', false); 
            commit('setWatchmenRequests', res.data.AdjustmentsRequestsFiltered); 
        }); 
    },
    //EventsClocker Actions
    async fetchInfoByApprovalID({commit}, approval_id ){
        const endpoint = API_URL + 'api/v1/clocker/'+approval_id+'/getClockerApprovalHours';
        await axios.get( endpoint).then((res) =>{
            commit('setinfoByApprovalID', res.data.infoByApprovalId); 
        }); 
    },
    async fetchAllEvents({commit}, dataObj){
        const endpoint = API_URL + 'api/v1/clocker/utilities/getEventsByEmployeeCampaign/'+dataObj;
        await axios.get( endpoint).then((res) =>{
            commit('setEventsPerCampaign', res.data.activitiesGrouped); 
        }); 
    },
    async addMissingEvent( {commit}, requestInfo ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/addMissingEvent';
        await axios.post( endpoint,requestInfo).then( (res) =>{
            commit('setLoadingTable', false);     
            return res.status;
        });
    },
    //Agent Hour Review view 1
    async fetchTableAgentHourReview( {commit} ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/loadTableMyHoursReview';
        await axios.get( endpoint).then( (res) =>{
            commit('setHourReviewTable', res.data.data);     
            return res.status;
        });
    },
    //Agent Hour Review view 2
    async fetchMyHoursDetailed( {commit}, data ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/loadTableMyHoursReviewDetailed';
        await axios.post( endpoint, data).then( (res) =>{
            commit('setHourReviewTableDetails', res.data.data);     
            return res.status;
        });
    },
    //Approve all day button view 2 Agent Hours Review
    async approveAllDayAgent( {commit}, data ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/approveAllDayAgent/'+ data;
        await axios.get(endpoint).then( (res) =>{
            commit('setHourReviewTableDetails', res.data.data);     
            return res.status;
        });
    },
    // Watchmen Adjustments and Corrections
    async isValidAgent( {commit}, empID ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/watchmen/'+empID+'/userExists';
        await axios.get(endpoint).then( (res) =>{
            commit('setAgentExists', res.data.userExists);     
        });
    },
    async isValidID( {commit}, ReqID ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/watchmen/'+ReqID+'/idExists';
        await axios.get(endpoint).then( (res) =>{
            commit('setReqIDExists', res.data.idExists);     
        });
    },
    async fetchAgentEventsPerEmpID( {commit}, data ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/watchmen/getAgentData';
        await axios.post(endpoint, data).then( (res) =>{
            commit('setAgentInfo', res.data.data);     
            return res.status;
        });
    },
    async saveCorrection( {commit}, data ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/watchmen/saveCorrection';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setLoadingTable', false);     
            return res;
        });
        return response;
    },
    async saveNewEvent( {commit}, data ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/watchmen/saveNewEvent';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setLoadingTable', false);     
            return res;
        });
        return response;
    },
    
    async fetchSAH( {commit},{id, previous} ){
        const endpoint = API_URL + 'api/v3/hours-management/supervisor-approval-hours/' +id;
        let response = await axios.get( endpoint, { params: {previous}}).then( (res) =>{
            commit('setSAH', res.data.hours);     
            return res;
        });
        return response;
    },

    async updateEmployeeSupervisorStatus( {commit}, formData ){
        const endpoint = API_URL + `api/v3/hours-management/employee/${formData.employee}/update-supervisor-status`;
        let response = await axios.post( endpoint, formData)
        commit('setSAH', response.data.hours);
        return response;
    },
    
    //Clocker Event utility
    async getUsersEventUtility( {commit}){
        const endpoint = API_URL + 'api/v1/clocker/utilities/clockerEventUtility/getUsers';
        let response = await axios.get(endpoint).then( (res) =>{
            commit('clocker_users', res.data.clockerUsers);
            return res;
        });
        return response;
    },

    async filterUtilityByUser( {commit}, EmployeeID){
        const endpoint = API_URL + 'api/v1/clocker/utilities/clockerEventUtility/getUsersFilteredByEmpID/' + EmployeeID;
        let response = await axios.get(endpoint).then( (res) =>{
            commit('clocker_users', res.data.clockerUsers);
            return res;
        });
        return response;
    },
    async fetchEventsPerCampaign({commit},campaign){
        const endpoint = API_URL + 'api/v1/clocker/utilities/clockerEventUtility/fetchEventsPerCampaign/' + campaign;
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setEventsPerCampaign', res.data.events);
            return res;
        });
        return response;
    },
    async agentHasShift( {commit}, EmpID ){
        const endpoint = API_URL + 'api/v1/clocker/utilities/clockerEventUtility/agentHasShift/'+EmpID;
        let response = await axios.get( endpoint).then( (res) =>{
            commit('setStatusReview', false);
            return res;
        });
        return response;
    },
    async getTime({commit}){
        const endpoint = API_URL + 'api/v1/clocker/utilities/getTime';
        await axios.get( endpoint).then((res) =>{
            commit('setClockerTime', res.data); 
        }); 
    },

    


};
const mutations = {
    clocker_users:                  (state, users)          => (state.clocker_users             = users),
    setLoadingTable:                (state, newFlag)        => (state.table_loading             = newFlag),
    setLoadingModal:                (state, newFlag)        => (state.modal_loading             = newFlag),
    setHours:                       (state, newHours)       => (state.hours                     = newHours),
    setMyTotalHours:                (state, totalHours)     => (state.myTotalHours              = totalHours),
    setApprovalHours:               (state, ApprovalHours)  => (state.ApprovalHours             = ApprovalHours),
    setDetailedHours:               (state, DetailedHours)  => (state.detailedHours             = DetailedHours),
    setStatusReview:                (state, paramStatus)    => (state.isOnReview                = paramStatus),
    setRequestInfo:                 (state, paramStatus)    => (state.requestInfo               = paramStatus),
    setWatchmenRequests:            (state, paramStatus)    => (state.watchmenRequests          = paramStatus),
    setWatchmenRequestsFiltered:    (state, paramStatus)    => (state.watchmenRequestsFiltered  = paramStatus),
    setCurrentStatus:               (state, paramStatus)    => (state.currentApprovalStatus     = paramStatus),
    setinfoByApprovalID:            (state, paramStatus)    => (state.infoByApprovalID          = paramStatus), 
    setEventsPerCampaign:           (state, paramStatus)    => (state.allEventsPerCampaign      = paramStatus), 
    setHourReviewTable:             (state, paramStatus)    => (state.HourReviewTable           = paramStatus), 
    setHourReviewTableDetails:      (state, paramStatus)    => (state.HourReviewTableDetails    = paramStatus), 
    setAgentInfo:                   (state, paramStatus)    => (state.agentInfo                 = paramStatus), 
    setAgentExists:                 (state, paramStatus)    => (state.agentExists               = paramStatus),
    setReqIDExists:                 (state, paramStatus)    => (state.reqIDExists               = paramStatus),
    setClockerTime:                 (state, paramStatus)    => (state.clockerTime               = paramStatus),
    setEventDate:                   (state, paramStatus)    => (state.event_date                = paramStatus),
    setModalVisibility:             (state, status)         => (state.showModal = status),
    setDayApprovalStatus:           (state, dayApprovalStatus) => (state.dayApprovalStatus = dayApprovalStatus),
    setSAH:           (state, supervisorApprovalHours) => (state.supervisorApprovalHours = supervisorApprovalHours)
};
export default {
    state,
    getters,
    actions,
    mutations
};