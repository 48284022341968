<template>
<div class="main">
    <!-- DISCONTINUED -->
  <b-container>
      <b-row>
          <b-col align-self="center" class="mt-2 rounded shadow-sm mb-3">
              <h1>Payroll Detailed Day</h1>            
          </b-col>
      </b-row>
      <div v-if="displayLoading" class="row justify-content-center"> 
          <div>
              <p class="font-italic font-weight-bold blink">"We're loading the detailed day information, be patient..."</p>
              <b-progress :max="maxProgressValue" :value="progressValue" :animated="true" variant="primary" :striped="true"></b-progress>
          </div>
      </div>
      <b-row class="row justify-content-center" v-else>
        <div class="flex text-center justify-content-center">
            <div class="bg-gradient-to-r from-red-300 bg-red-200 text-xs max-w-xl rounded-2xl" :class="{ 'p-2' : getMessage !== ''}">
                {{getMessage}}
            </div>
        </div>
        <div>
        <div class="d-flex flex-column bd-highlight mb-3">
            <div class="p-2 bd-highlight">
                <label>Current User: <b>{{ this.EmployeeID}}</b></label>
            </div>
        </div>
         <b-row>
            <b-col>
                <vs-button class="mb-2" color="#2B1A36" gradient-color-secondary="#55336B" v-b-modal="'reportMissingEvent'" type="gradient"
                :disabled="getDayApprovalStatus === 'Finished' || getDayApprovalStatus === 'Closed'" 
                :class="{'cursor-default' : getDayApprovalStatus === 'Finished' || getDayApprovalStatus === 'Closed'}">
                    Report Missing Event
                </vs-button>
                <b-modal :id="'reportMissingEvent'" hide-footer hide-header>
                    <div>
                        <missingEventPayroll :empId="this.EmployeeID" :eventDate="this.Event_Date"></missingEventPayroll> 
                    </div>
                </b-modal>
                <table class="table table-borderless table-hover">
                    <thead class="thead-dark">
                    <tr>
                        <th class="text-center" scope="col">Date</th>
                        <th class="text-center" scope="col">EmpID</th>
                        <th class="text-center" scope="col">L.O.B</th>
                        <th class="text-center" scope="col">Event</th>
                        <th class="text-center" scope="col">Comment</th>
                        <th class="text-center" scope="col">Standard Hours</th>
                        <th class="text-center" scope="col">Graveyard Hours</th>
                        <th class="text-center" scope="col">Sunday Hours</th>
                        <th class="text-center" scope="col">Billable</th>
                        <th class="text-center" scope="col">Payable</th>
                        <th class="text-center" scope="col">Total</th>
                        <th class="text-center" scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody v-if="getAMHRDetailedDay.length">
                    <tr :id="index" v-for="(currentRow, index) in getAMHRDetailedDay" :class="{ 'table-primary font-weight-bold': currentRow.Event === 'Subtotal' }" :key="index">
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.Event_date | moment("YYYY-MM-DD")}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.emp_id}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.LOB}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.Event}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.Comment ? currentRow.Comment : 'None' }}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.stdhours}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.gravhours}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.sundayhours}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.Billable}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.Payable}}</td>
                        <td style="vertical-align:middle;" class="text-capitalize">{{currentRow.Total}}</td>
                        <!-- Cambiar el boton si existe algun registro ya de este -->
                        <td style="vertical-align:middle;" class="text-capitalize">
                            <vs-tooltip text="Report an issue">
                                <vs-button v-b-modal="'payrollModal'+ index" v-if="currentRow.Event !== 'Subtotal'"  type="flat"  icon="info"  variant="primary" color="danger"
                                :disabled="getDayApprovalStatus === 'Finished' || getDayApprovalStatus === 'Closed'" 
                                :class="{'cursor-default' : getDayApprovalStatus === 'Finished' || getDayApprovalStatus === 'Closed'}">
                                </vs-button>
                            </vs-tooltip>
                            <b-modal :id="'payrollModal'+ index" hide-footer hide-header>
                                <div>
                                    <payrollRequestModal :objectData="currentRow"></payrollRequestModal>
                                </div>
                            </b-modal>
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <td colspan="12">No records found.</td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
        <div class="row" v-if="this.EmpID !== null">
            <current-requests :empId="EmployeeID" :date="Event_Date"/>
        </div>
        </div>
        </b-row>
  </b-container>

  
</div>
</template>
<script>
import payrollRequestModal    from '@/views/components/Clocker/modals/newRequestPayroll'
import missingEventPayroll    from '@/views/components/Clocker/modals/missingEventPayroll'
import CurrentRequests from '@/views/payroll/tables/AccountManagerAdjustmentRequests'
import {mapGetters, mapActions } from 'vuex'
export default {
  components: { payrollRequestModal, missingEventPayroll,CurrentRequests},
  data(){
      return{
          EmployeeID: 0,
          Event_Date: null,
            
          progressValue: 0,
          maxProgressValue: 120,
          displayLoading: true,
          EmpID: this.EmployeeID
      }
  },
  computed:{
      ...mapGetters(['getAMHRDetailedDay', 'getDayApprovalStatus']),
      getMessage: function(){
        var message = '';
        if(this.getDayApprovalStatus === 'Open'){
          message = ''
        }else if(this.getDayApprovalStatus === 'Closed'){
          message = 'This day has been closed, you can no longer request adjustment nor corrections'
        }else if(this.getDayApprovalStatus === 'Approved'){
          message = 'This day has been approved by the Account Manager, but you still can request changes'
        }else if(this.getDayApprovalStatus === 'Finished'){
          message = 'This day has been approved and closed, you can no longer request adjustment nor corrections'
        }
        return message;
      }
    },
    beforeMount(){
        if(this.$route.params.EmpID === null || this.$route.params.EmpID === undefined){
             this.$fire({
                type: "error",
                title: "Oops! Looks like something goes wrong, we're going to redirect you...",
                showConfirmButton: true,
              }).then(()=>{
                  this.$router.go(-1);
              });
        }else{
            this.EmployeeID = this.$route.params.EmpID;
            this.Event_Date = this.$route.params.currentDate;
            this.initLoading();
            let dataObj = {
                EmpID: this.EmployeeID,
                EventDate: this.Event_Date,
            }
            this.fetchAMHRDetailedDay(dataObj).then(()=>{
                this.displayLoading = false;
            });
        }
    },
    methods:{
        ...mapActions(['fetchAMHRDetailedDay']),
         initLoading: function(){
            let vm = this;
            let timer = setInterval(function() {
                vm.progressValue += 3;
                if (vm.progressValue >= 180) clearInterval(timer);
            }, 100);
        },
    },
}
</script>
<style scoped>

</style>