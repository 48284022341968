<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative flex flex-col w-full m-0 p-0 px-2 pb-5">
    <div class="-my-3 sm:-mx-6 lg:-mx-8">
      <div class="w-full py-3">
          <div class="mx-auto max-w-7xl my-2 pl-5 text-center text-gray-500 text-md font-medium">
            Fill the text input using the trainer employee id or select a location using the dropdown menu to filter the existing trainings.
          </div>
      </div>
      <div class="pb-2 align-middle sm:px-6 lg:px-8 mb-10">
        <div class="sm:rounded-lg">
          <div class="bg-white shadow-sm mx-auto rounded-md">
          <training-searchbar @toggle="filterTrainings"/>
          <div class="relative">
          <table class="divide-y-2 divide-gray-50 w-full" v-if="getTrainingList.length || loading">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                  #
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                  Location
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                  Trainer
                </th>
                <th scope="col" class="px-6 py-3 text-xs font-bolder text-center text-gray-500 uppercase tracking-wider">
                  Department
                </th>
                <th scope="col" class="px-6 py-3  text-center text-xs font-bolder text-gray-500 uppercase tracking-wider">
                  Campaign
                </th>
                <th scope="col" class="px-6 py-3  text-xs font-bolder text-gray-500 text-center uppercase tracking-wider">
                  LOB
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                  Start Date
                </th>
                
                <th scope="col" class="px-6 py-3 text-left text-xs font-bolder text-gray-500 uppercase tracking-wider">
                  End Date
                </th>  
                <th scope="col" class="relative px-6 py-3 text-center font-bolder text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
                
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-blue-200" >
              <tr v-for="(item,index) in getTrainingList" :key="index">
                <td class="px-6 py-3 whitespace-nowrap border-b-4 border-gray-50">                                  
                  <div class="text-sm font-medium text-gray-900 text-left">
                    {{item.id}}
                  </div>                                   
                </td>
                <td class="px-6 py-3 whitespace-nowrap border-b-4 border-gray-50">                                  
                  <div class="text-sm font-medium text-gray-900 text-left">
                    {{item.location}}
                  </div>                                   
                </td>
                <td class="px-6 py-3 whitespace-nowrap border-b-4 border-gray-50">                                  
                  <div class="text-sm font-medium text-gray-900 text-left">
                    {{item.trainer_empID}}
                  </div>                                   
                </td>
                
                <td class="px-6 py-4 whitespace-nowrap border-b-4 border-gray-50">                
                  <div class="text-sm text-gray-500 text-left text-center font-semibold">{{item.department}}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-left border-b-4 border-gray-50">
                  <div class="text-sm text-gray-500 text-left text-center font-semibold">{{item.campaign}}</div>                
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                  <div class="text-sm text-gray-500 font-semibold text-center uppercase">{{item.lob}}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                  <div class="text-sm text-gray-500 text-left font-semibold">{{item.start_date}}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                  <div class="text-sm text-gray-500 text-left font-semibold">{{item.end_date}}</div>
                </td> 
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium border-b-4 border-gray-50 text-left">       
                  <div class="flex flex-row">
                    <div class="whitespace-nowrap mx-auto text-sm font-medium relative group">
                      <button class="border-none py-1 rounded-sm flex items-center m-auto">
                        <span class="pr-1 font-semibold flex-1">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </span>
                      </button>

                      <div class="inline-block absolute -left-20">
                        <div class="bg-white shadow-md rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-200 ease-in-out origin-top w-40 -left-10 z-10">
                          <button  @click.prevent="$router.push({ path: '/recruitment/trainings/' + item.id})" 
                          class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2" title="Edit training">
                          <i class="bi bi-gear"></i>
                            Edit
                          </button>
                          <button @click.prevent="$router.push({ path:`/recruitment/trainings/${item.id}/attendance`})" 
                          class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2" title="Manage Daily Attendance">
                          <i class="bi bi-journal-check"></i>
                            Daily
                          </button>
                          <button @click.prevent="drop(item.id)"
                          class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2" title="Delete training">
                          <i class="bi bi-trash2"></i>
                            Delete
                          </button>
                          <button @click.prevent="$router.push({ path:`/recruitment/trainings/${item.id}/exams`})" 
                          class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2" title="Delete training">
                          <i class="bi bi-award"></i>
                            Exams
                          </button>
                          <button @click.prevent="$router.push({ path:`/recruitment/trainings/${item.id}/certifications`})" 
                          class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2" title="Delete training">
                          <i class="bi bi-mortarboard"></i>
                            Certifications
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <!-- More items... -->
            </tbody>
            <tbody class="bg-white divide-y divide-blue-200" v-if="loading">
              <tr   class="animate-pulse" v-for="item,index in 5" :key="'row'+index">
                <td class="px-6 py-3 whitespace-nowrap border-b-4 border-gray-50">                                  
                  <div class="text-sm font-medium text-gray-900 text-left">
                      <div class="relative w-full h-2 bg-gray-200 rounded-full"></div>
                  </div>                                   
                </td>
                <td class="px-6 py-3 whitespace-nowrap border-b-4 border-gray-50">                                  
                  <div class="text-sm font-medium text-gray-900 text-left">
                    <div class="relative w-full h-2 bg-gray-200 rounded-full"></div>
                  </div>                                   
                </td>
                
                <td class="px-6 py-4 whitespace-nowrap border-b-4 border-gray-50">                
                  <div class="text-sm text-gray-500 text-left text-center font-semibold"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-left border-b-4 border-gray-50">
                  <div class="text-sm text-gray-500 text-left text-center font-semibold"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></div>                
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                  <div class="text-sm text-gray-500 font-semibold text-center uppercase"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                  <div class="text-sm text-gray-500 text-left font-semibold"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b-4 border-gray-50">
                  <div class="text-sm text-gray-500 text-left font-semibold"><div class="relative w-full h-2 bg-gray-200 rounded-full"></div></div>
                </td> 
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium border-b-4 border-gray-50 text-center">       
                  <div class="flex flex-row">
                    <div class="px-5 py-3 bg-gray-200 rounded-sm shadow-sm mx-auto"  v-for="item,index in 3" :key="index"></div>
                  </div>
                </td>
              </tr>

              <!-- More items... -->
            </tbody>
            
          </table>
          </div>
          <not-found v-if="!getTrainingList.length && !loading">
            <template slot="headline">
                  We worked that hard that we are out of trainings.
            </template>  
            <template slot="subline">
                Come back later <span v-if="1==1">
                  or 
                  <a href="#" class="text-gray-700 text-left hover:text-gray-900" @click.prevent="$router.push({name:'trainings.create'})">
                    create a new training.
                  </a>
                </span>
            </template>  
          </not-found>   
          </div>
        
        </div>
      </div>
      <div v-if="loading"  class="mt-3">
        Loading data, please wait
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import NotFound from '@/views/mockups/404'
import TrainingSearchbar from '@/views/recruitment/components/searchbars/Trainings'
export default {
    components:{
      NotFound,
      TrainingSearchbar
    },
    computed:{
      ...mapGetters(['getTrainingList']),
    },
    methods:{
      ...mapActions(['fetchTrainingList','filterTrainingList','dropTraining']),
      filterTrainings:function(data){      
        this.filterTrainingList(data);        
      },
      drop:function(id){
          this.$confirm(
             `You're about to delete a training. This action cannot be reverted. Do you want to continue?`,
                "Delete training",
                
                  "warning"
                )
            .then(()=>{
                 this.dropTraining(id);
            }).then(()=>{
                this.$fire({
                  type: 'success',
                  title:`The training ${id} is no more`,
                  showConfirmButton: true,                
              });  
            }).catch(()=>{
              return;
            });
      }
    },
    beforeMount(){
        this.$emit('set-title','Training list');
        this.loading = true;
        this.fetchTrainingList().then(()=>{
          this.loading = false;
        });
    },
    data(){
      return{ loading :false}
    }
    
}
</script>