<template>
<div class="w-full mx-auto">
  <div v-if="hasError">
     <div class="bg-white border-1  mt-5 border-gray-100 shadow-sm rounded-md mx-auto p-2 px-3 md:w-1/3 lg:w-1/4">
        <div class="font-semibold text-gray-600 text-left text-md mb-2">
            Something went wrong
            <svg class="w-5 h-5 inline text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </div>
        <div class="text-gray-500 font-semibold">
          <p class="text-left text-sm">
            There are no hours available for this agent. Please try again later.
          </p>
          <div class="relative flex flex-row align-center justify-start mt-2">
            <a class="relative text-xs text-left" href="#" @click.prevent="$router.go(-1)">
                <svg class="w-4 h-4 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                Go back to previous page.
            </a>
          </div>
        </div>
      </div>
  </div>
  <div v-else>
    <div v-if="displayLoading" class="flex flex-row flex-wrap px-10 py-4 align-items-start justify-content-center"> 
      <div>
        <p class="font-italic font-weight-bold blink">"We're loading the detailed day information, be patient..."</p>
        <b-progress :max="maxProgressValue" :value="progressValue" :animated="true" variant="primary" :striped="true"></b-progress>
      </div>
    </div>
    <div v-else>
        <div class="flex flex-row flex-wrap px-10 py-4 align-items-start justify-content-center">
          <span>Current Status: <p class="font-black ring-4 ring-blue-500 ring-opacity-50 rounded-full">{{this.getCurrentStatus}} </p></span>
      </div>

      <div class="flex flex-row flex-wrap px-10  align-items-start justify-content-center">
        <button v-if="!this.getStatusReview" @click='approveAllDay' class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded">Approve all day</button>
        <button style="cursor: no-drop" v-else disabled @click='approveAllDay' :id="'approved'" class="bg-gray-200 px-6 py-2 rounded">Approve all day</button>
        <button class="bg-slate-600 hover:bg-slate-700 text-white px-6 py-2 rounded" v-b-modal="'reportMissingEvent'"
        :disabled="getDayApprovalStatus !== 'Open'" 
        :class="{'cursor-default' : getDayApprovalStatus !== 'Open'}" >
          Report Missing Event
        </button>
      </div>

      <div>
        <b-modal :id="'reportMissingEvent'" hide-footer hide-header>
          <div>
            <missingEventPayroll :empId="this.EmployeeID" :eventDate="getEventDate" :approvalId="this.approval_id"></missingEventPayroll> 
          </div>
        </b-modal>
      </div>

      <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-4 align-middle inline-block sm:px-6 lg:px-8 2xl:px-4">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">L.O.B</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Team</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Report To</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Event</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
              </thead>
              <tbody v-if="this.getDetailedHours.length" class="bg-white divide-y divide-gray-200">
                <tr :id="index" v-for="(currentRow, index) in getDetailedHours" :key="index">
                  <td class="px-6 py-4 whitespace-nowrap">{{currentRow.created_date | moment("YYYY-MM-DD")}}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{currentRow.emp_id}}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{currentRow.LOB}}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{currentRow.campaign}}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{currentRow.Team}}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{currentRow.reportTo}}</td>
                  <td class="px-6 py-4 whitespace-nowrap uppercase">{{currentRow.event}}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{hhmmss(currentRow.Duration)}}</td>
                  <td class="px-6 py-4 whitespace-nowrap" style="cursor:pointer">
                    <div v-if="currentRow.Status == 'In_revision'">
                      <button  v-b-modal="'cancelRequest'+index" class="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded">Cancel Request</button>
                      <b-modal :id="'cancelRequest'+index" hide-footer hide-header>
                        <div>
                          <cancelRequestModal :id="'cancelRequest'+index" :requestID="currentRow"></cancelRequestModal> 
                        </div>
                      </b-modal>
                    </div>
                    <div v-else-if="currentRow.Status == 'complete'" >
                      <button class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded" >Complete</button>
                    </div>
                    <div v-else>
                      <button :id="'changeRequest'+index" class="bg-amber-600 hover:bg-amber-700 px-6 py-2 rounded" v-b-modal="'changeRequest'+index"
                      :disabled="getDayApprovalStatus !== 'Open'"
                      :class="{'cursor-default' : getDayApprovalStatus !== 'Open'}">
                        Change Request!
                      </button>
                      <b-modal :id="'changeRequest'+index" hide-footer hide-header>
                        <div>
                          <changeRequestModal :id="'changeRequest'+index" :objectData="currentRow"></changeRequestModal> 
                        </div>
                      </b-modal>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr><td colspan="9">No records found.</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
</template>
<script>
  import {mapGetters,mapState, mapActions } from 'vuex'
  import changeRequestModal from '@/views/components/Clocker/modals/newRequestPayrollSup.vue';
  import cancelRequestModal from '@/views/components/Clocker/modals/cancelRequest.vue';
  import missingEventPayroll    from '@/views/components/Clocker/modals/missingEventPayrollSup';
  export default {
    data() {
      return {
        EmployeeID: null,
        nowValue: 'now_1',
        displayLoading: true,
        isDisabled: true,
        currentStatus: '',
        approval_id: '',
        progressValue: 0,
        maxProgressValue: 120,
        error:false
      }
    },
    components: {
      changeRequestModal,
      cancelRequestModal,
      missingEventPayroll
    },
    computed:{
      ...mapState(['clockerUsers']),
      ...mapGetters([
        'getDetailedHours',
        'getLoadingTable',
        'getStatusReview',
        'getCurrentStatus',
        'getEventDate',
        'getDayApprovalStatus'
      ]),
      hasError:function(){
        return this.error;
      }
    },
    created(){
        this.setValues();
        this.fetchDetailedHours({'emp_id': this.EmployeeID,'nowParameter': this.nowValue}).then(()=>{
          if(this.getDetailedHours.length > 0){
            this.approval_id = this.getDetailedHours[0].approval_id;
            this.loadCurrentStatus();
          }else{
            this.error = true;
          }
        }).catch(()=>{
          this.error = true;
        });
    },
    methods:{
        ...mapActions([
          'fetchDetailedHours',
          'fetchAdjustmentsPerEvent',
          'approveAllDayRequests',
          'fetchCurrentSupervisorStatus'
        ]),
        setValues: function(){
          this.EmployeeID = this.$route.params.empId;
          this.nowValue   = this.$route.params.nowParam;
        },
        initLoading: function(){
            let vm = this;
            let timer = setInterval(function() {
                vm.progressValue += 3;
                if (vm.maxProgressValue >= 100) clearInterval(timer);
            }, 100);
        },
        loadCurrentStatus: function(){
          this.fetchCurrentSupervisorStatus({ approval_id: this.approval_id}).then(()=>{
            this.displayLoading = false;
           
          }).catch((err)=>{
            this.displayLoading = false;
            this.error = true;
            console.error(err);
          });
        },
        approveAllDay: function(){
          let approvalId = {
             approvalId: this.approval_id,
             newStatus: 'approved'
          }
          this.approveAllDayRequests(approvalId).then(()=>{
            this.$fire({
              type: 'success',
              title: 'All day has been approved',
              showConfirmButton: false,
              timer: 1500
            }).then(()=>{ this.$router.go(0); }); 
          });
        },
        cancelRequest: function(){

        },

        pad: function(num) {
            return ("0"+num).slice(-2);
        },
        hhmmss: function(secs) {
          var minutes = Math.floor(secs / 60);
          secs = secs%60;
          var hours = Math.floor(minutes/60)
          minutes = minutes%60;
          return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
        }
    },
  }

</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
  .underline{
      background-color: #007bff !important;
      color: white;
  }
</style>