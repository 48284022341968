<template>
<div>
  <loading :active.sync="getLoadingTable" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
  <div id="EditTable" class="userTable">
    <b-container fluid class="TableSearch">
      <b-row>
        <b-col align-self="center">
          <table class="table-sm table table-striped">
            <thead class="thead-dark">
              <tr>
                <th class="text-center" scope="col">Date</th>
                <th class="text-center" scope="col">Event</th>
                <th class="text-center" scope="col">Payable Standard Time</th>
                <th class="text-center" scope="col">Payable Graveyard Time</th>
                <th class="text-center" scope="col">Total</th>
              </tr>
            </thead>
            <tbody v-if="getMyHours.length">
              <tr :class="{ hline: currentRow.Event == 'Subtotal' }" :id="currentRow.rec_id" v-for="(currentRow, index) in getMyHours" :key="index">
                  <td class="text-capitalize">{{currentRow.createdAt}}</td>
                  <td class="text-capitalize">{{currentRow.Event}}</td>
                  <td class="text-capitalize">{{(Math.round(((currentRow.PayableStandardTime/3600) + Number.EPSILON) * 100) / 100)}}</td>
                  <td class="text-capitalize">{{(Math.round(((currentRow.PayableGraveyardTime/3600) + Number.EPSILON) * 100) / 100)}}</td>
                  <td class="text-capitalize">{{(Math.round(((currentRow.Total_Pay/3600) + Number.EPSILON) * 100) / 100)}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">No records found.</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <b-row v-if="getMyHours" class="bg-secondary text-white font-weight-bold">
        <b-col cols='6 mt-2'>
          <label>Estimated Payable Standard Time: </label>
          <label>{{ (Math.round(((this.getMyTotalHours[0].PayableStandardTime/3600) + Number.EPSILON) * 100) / 100) }}</label>
        </b-col>
        <b-col cols='6 mt-2'>
          <label >Estimated Payable Graveyard Time: </label>
          <label>{{ (Math.round(((this.getMyTotalHours[0].PayableGraveyardTime/3600) + Number.EPSILON) * 100) / 100) }}</label>
        </b-col>
      </b-row>

      <b-row v-if="getMyHours" class="mb-5 bg-dark text-white font-weight-bold">
        <b-col class="mt-2">
          <label>Estimated Total Payable Time: </label>
          <label>{{ (Math.round(((this.getMyTotalHours[0].Total/3600) + Number.EPSILON) * 100) / 100) }} </label>
        </b-col>
      </b-row>
    </b-container>
  </div>

</div>
</template>
<script>
  import {mapActions, mapGetters,mapState } from 'vuex'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
        keyword: '',
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',
        isDisabled: true,
        PayStandartTime: 0,
        GydStandartTime: 0,
        TotalTime: 0,
      }
    },
    props:['week'],
    components: {
      Loading
    },
    computed:{
      ...mapState(['clockerUsers']),
      ...mapGetters(['getMyHours','getLoadingTable','getMyTotalHours'])
    },
    created(){
      let objectData = {
            week: this.week
        };
      this.fetchMyHours(objectData).then(()=>{
        this.PayStandartTime = (Math.round(( parseInt(this.getMyTotalHours[0].PayableStandardTime) + Number.EPSILON) * 100) / 100); //this.getMyTotalHours[0].PayableStandardTime.toFixed(2); // 
        this.GydStandartTime = (Math.round(( parseInt(this.getMyTotalHours[0].PayableGraveyardTime) + Number.EPSILON) * 100) / 100); //this.getMyTotalHours[0].PayableGraveyardTime.toFixed(2); // 
        this.TotalTime = (Math.round(( parseInt(this.getMyTotalHours[0].Total) + Number.EPSILON) * 100) / 100); //this.getMyTotalHours[0].Total.toFixed(2); // 
      });
      
    },
    methods:{
      ...mapActions(['fetchMyHours']),
    },
  }

</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 1em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
  .hline{
      background-color: #007bff !important;
      color: white;
  }
</style>
