<template>
<!-- DISCONTINUED -->
  <div ref="ModalNewRequest" id="ModalNewRequest" class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Report a Payroll Issue</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <label>
          Approval ID: <b>{{ this.objectData.approval_id }}</b>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <label>
          Current User: <b>{{ this.objectData.emp_id }}</b>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <label v-if="this.objectData.Event_date">Date: <b>{{ this.objectData.Event_date | moment("YYYY-MM-DD") }}</b></label>
        <label v-else>Date: <b>{{ this.objectData.created_date | moment("YYYY-MM-DD") }}</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <label v-if="this.objectData.Event">Event: <b>{{ this.objectData.Event }}</b></label>
        <label v-else>Event: <b>{{this.objectData.event}}</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Reason:</label>
          <select v-model="adjustment.reason" class="form-control" id="exampleFormControlSelect1">
            <option value="null">Select a Reason</option>
            <option value="misuse">Agent tool misuse</option>
            <option value="internet">Internet issue</option>
            <option value="clocker">Issue with clocker</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="adjustment.reason == 'other'" class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">
            <a class="text-danger">*</a>Write the reason:
          </label>
          <input class="form-control" type="text" v-model="adjustment.otherReason" placeholder="Write the reason" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Total Event Duration:</label>
          <vue-timepicker v-model="adjustment.starndarduration" class="form-control z-20" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
      </div>
    </div>
    
    <!--div v-if="fileNameSet" class="flex flex-row mb-3 justify-content-center text-center bg-green-200 p-3 rounded-md ml-4 mr-4">
      Proof confirmed <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
    </div-->
    
    <div class="w-full">
      <div class="row">
        <div class="mx-2 px-3">
          <label class="inline-flex items-center">
           <ToggleButton :active="requestProof" @toggle="clearProof" :id="1"/>
            <span class="ml-2">Include proof</span>
          </label>
        </div> 
      </div>      
      
      <div class="w-full">
        <div v-if="requestProof" class="w-full px-3">
          <label for="fileName" class="ml-3 text-gray-800">Proof:</label>
          <drag-and-drop class="" @clear="resetFileModal" :key="modalResetKey" @fileSelected="setFileName"/>
        </div>

        <div v-else class="w-full pl-4 py-2">
          <label for="fileName" class="ml-1 py-1 text-gray-800">No proof reason:</label>
          <textarea class="resize-none w-full mx-auto rounded-md border-dashed border-2 border-gray-300 p-2" rows="4" required v-model="adjustment.noProofReason">        
          </textarea>
        </div>
      </div>     
    </div>

    <div class="row">
      <div class="col">
        <button type="button" @click="sendRequest" class="px-3 py-2 bg-gray-700 text-white w-full mt-3  rounded-sm">
          <span v-if="loading">
            Saving request  
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
              <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
              <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
            </svg>
          </span>
          <span v-else>
            Save
          </span>
        </button>
        <!-- <button type="button" class="bg-gray-200 px-6 py-2 rounded">Cancel</button> -->
      </div>
    </div>
  </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker'
import { mapActions, mapGetters, mapState } from "vuex";
import DragAndDrop from '@/views/Utilities/SupervisorDragAndDrop'
import ToggleButton from '@/modules/hours-management/roles-management/components/toggle'
export default {
  components: { 
    VueTimepicker,
    DragAndDrop,
    ToggleButton,     
   },
  data() {
    return {
      time: null,
      adjustment: {
        reason: null,
        proof:null,     
        fileName:null,
        noProofReason:null
      },
      requestProof: true,
      modalResetKey:0,
      loading:false
  };
  },
  props: {
    objectData: Object,
  },
  computed: {
   ...mapState(['AccountManagerClocker']),
   ...mapGetters([
    ]),
    mustProvideEvidence:function () {
      return !!this.requestProof;
    },
    fileNameSet:function(){
      return this.fileName !== null &&  this.fileName !== ""
    },
    fileName:function(){
      return this.adjustment.proof;
    }
  },  
  methods: {
    ...mapActions(['storeNewPayrollRequest','uploadImgRequestProof']),
    sendRequest:async function () {
      if(this.adjustment.proof == null && this.adjustment.noProofReason == null){
          this.$fire({
              type: "warning",
              title: "Oops! Provide a proof or a no proof reason",
              showConfirmButton: true,
                });
      }
      else{
        if(this.adjustment.reason == null)
        {
          this.$fire({
              type: "warning",
              title: "Oops! Reason not selected",
              showConfirmButton: true,
                });
        }
        else{
          if(this.adjustment.graveyardDuration == null && this.adjustment.starndarduration == null){
            this.$fire({
                type: "warning",
                title: "Oops! Time not specified",
                showConfirmButton: true,
                  });
          }else{
            //Ajustes para detailedDay
            let eventDate = this.objectData.Event_date ?? this.objectData.created_date;
            let eventType = this.objectData.Event ?? this.objectData.event;

            let newRequestData = {
              EmpID: this.objectData.emp_id,
              eventDate: eventDate,
              eventType: eventType,
              gvyDuration: this.adjustment.graveyardDuration ?? { HH: "00", mm: "00" },
              stdDuration:  this.adjustment.starndarduration ?? { HH: "00", mm: "00" },
              reason: this.adjustment.reason,
              otherReason: this.adjustment.otherReason,
              PayrollID: this.objectData.Payroll_id,
              ApprovalID: this.objectData.approval_id,
              proof_id : this.adjustment.proof,
              noProofReason : this.adjustment.noProofReason,
              Request_type: 'adjustment'
            }
            // Validation not less than 5 mins
            let totalSeconds = (this.objectData.Duration/60).toFixed(0) * 60;
            let totalAdjustment = (newRequestData.gvyDuration.HH * 3600 + newRequestData.gvyDuration.mm * 60) + (newRequestData.stdDuration.HH * 3600 + newRequestData.stdDuration.mm * 60);
            let diff = totalSeconds - totalAdjustment;

            if(diff < 300 && diff > -300){
              this.$fire({
                    type: "warning",
                    title: "Difference must not be less than 5 minutes",
                    showConfirmButton: true,
                      });
            }else{
              if(newRequestData.proof_id instanceof  File){
                  try{
                      let form = new FormData()
                      form.append("image", newRequestData.proof_id);
                      const imageHash = await this.uploadImgRequestProof(form);
                      newRequestData['proof_id']= imageHash.data.img;
                      console.log(newRequestData);
                  }catch(error){
                    console.log(error);
                   
                   return this.$fire({
                      type: "warning",
                      title: "Something went wrong. If error persist please contact an admin.",
                      showConfirmButton: true,
                    });
                    
                  }
           
              }
           
               
              this.storeNewPayrollRequest(newRequestData).then(()=>{
                this.$fire({
                    type: "success",
                    title: "The issue has been reported successfully",
                    showConfirmButton: false,
                    timer: 1600,
                  }).then(()=>{
                    //this.$router.go(-1)
                    });
              });
            }
          }
        }
      }
    },
     resetFileModal(){
       this.modalResetKey ++;
       this.adjustment.proof = null;
     },
     setFileName(file){
       this.adjustment.proof = file;
     },
     clearProof({value}){
       this.adjustment.proof = null;
       this.adjustment.noProofReason =null;
       this.requestProof = value;
     }
  },
};
</script>
<style lang="scss">
  @import '~vue2-timepicker/dist/VueTimepicker.css';
  .display-time {
    border: none !important;
    cursor: pointer !important;
    height: auto !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>