<template>
<div class="main overflow-x-hidden w-full h-full">
  <!-- DISCONTINUED -->
    <div class="flex text-center justify-content-center">
      <div class="bg-gradient-to-r from-red-300 bg-red-200 text-xs max-w-xl rounded-2xl" :class="{ 'p-2' : getMessage !== ''}">
        {{getMessage}}
      </div>
    </div>
    <div class="row">
      <detailedDayTable></detailedDayTable> 
    </div> 
    <div class="row m-0">       
      <current-requests/>
    </div>
  </div>
</template>
<script>
import detailedDayTable from '@/views/components/Clocker/tables/detailedDayTable';
import CurrentRequests from '@/views/payroll/tables/CurrentUserAdjustmentRequests'
import {mapGetters} from 'vuex'

export default {
  name: '',
  components: { detailedDayTable, CurrentRequests },
  data() {
    return {
      title:'Agent Detailed Hours',
      }
    },
    mounted(){
      this.$emit('set-title',this.title);
    },
    computed: {
      ...mapGetters(['getDayApprovalStatus']),
      getMessage: function(){
        var message = '';
        if(this.getDayApprovalStatus === 'Open'){
          message = ''
        }else if(this.getDayApprovalStatus === 'Closed'){
          message = 'This day has been closed, you can no longer request adjustment nor corrections'
        }else if(this.getDayApprovalStatus === 'Approved'){
          message = 'This day has been approved by the Account Manager, you can no longer request adjustment nor corrections'
        }else if(this.getDayApprovalStatus === 'Finished'){
          message = 'This day has been approved and closed, no updates available'
        }
        return message;
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
