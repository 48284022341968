<template>    
  <div>
    <attrition-risk-modal :trainee="selected_trainee" @toggle="attritionRiskHandler" v-if="show_attrition_risk_modal"/>
    <div class="relative flex flex-column" v-if="getTrainingDaily !== null">
      <training-details class="mb-4"/>
      <trainees-searchbar @toggle="addTrainee" :key="searchbarKey" v-if="!dailySummaryIsCompleted"/>
      <div class="text-center text-slate-500 text-md font-medium leading-none my-3"  v-if="!dailySummaryIsCompleted" >
        Select a trainee from the list and press the  "Add trainee" button in order to add a new trainee. You need the trainee's curp to complete the process.
      </div>
      <div class="bg-white shadow-sm overflow-hidden rounded-lg">

        <div class="p-2 flex">
          <training-notes-dropdown :disabled="dailySummaryIsCompleted"/>
        </div>

        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
              <tr>
                <th>
                  
                </th>
                <th scope="col" class="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Full Name
                </th>
                <th scope="col" class="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Employee Id
                </th>
                <th scope="col" class="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                </th>
                <th scope="col" class="relative px-3 py-3 text-right">
                    <button title="Start a group activity for all the logged in users" v-if="!dailySummaryIsCompleted" @click.prevent="lauchActivity" class="bg-purple-500 hover:bg-purple-700 ml-auto text-white rounded-sm shadow-sm py-2 px-2" role="menuitem" tabindex="-1" id="menu-item-0">
                      Start Activity
                    </button>  
                </th>
              </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200" v-if="getTrainingDaily.length > 0">
              <tr v-for="(person,index) in getTrainingDaily" :key="index">
                <td class="pl-3 py-4 whitespace-nowrap">
                  <button @click="showAttritionRiskModal(person)"
                  class="text-gray-300 rounded-full p-2 flex-shrink-0"
                  :class="{'hover:text-red-200' : !person.attrition_flag}"
                  :disabled="person.attrition_flag || dailySummaryIsCompleted">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    class="bi bi-flag-fill" viewBox="0 0 16 16"
                    :class="{'text-red-500' : person.attrition_flag}">
                      <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
                    </svg>
                  </button>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center gap-2">
                      <div class="flex-shrink-0 h-10 w-10">
                          <img class="h-10 w-10 rounded-full" :src="'http://placekitten.com/' + (index % 2 == 0 ? 'g/200/300':'200/300')">
                      </div>
                      <div> 
                          <div class="text-sm text-gray-500 text-left">{{ person.employee }}</div>
                      </div>
                      <trainee-notes-dropdown :employee_id="person.trainee_id" :disabled ="dailySummaryIsCompleted"/>
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-500 text-left">{{ person.trainee_id}}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500 uppercase">
                  <!-- {{person.currentActivity}} -->
                  <button :title="'Press to clock-in ' + person.employee" href="#" class="bg-gray-200 text-gray-600 text-sm px-3 py-2 rounded-sm flex gap-2 uppercase" @click="clockIn(person.trainee_id)"
                      :disabled ="person.loginStatus || dailySummaryIsCompleted" v-if="!person.loginStatus">
                      {{ person.isAbsent ? 'Absent' : 'Clocked out' }}
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24"  stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                      </svg>
                    </button>
                  <button href="#" :title="'Press to clock-out ' + person.employee" class="bg-gray-700 text-white text-sm px-3 py-2 rounded-sm flex gap-2 shadow-sm uppercase font-semibold" @click="clockOut(person.trainee_id)"
                    :disabled ="!person.loginStatus" v-else >
                    {{person.currentActivity}}
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                  </button>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <div class="flex flex-row items-center justify-end" v-if="!dailySummaryIsCompleted">
                  <button @click="removeFromTraining(person.trainee_id)" :title="'Press to remove ' + person.employee + ' from training.'" class="w-10 h-10 hover:bg-red-400  hover:text-white rounded-full hover:shadow">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                  </button>
                  </div>
                </td>
              </tr>
          </tbody>
          <tbody class="bg-white divide-y divide-gray-200" v-else>
              <tr>
              <td class="px-6 py-4 whitespace-nowrap">
                No trainees found. Add a new training to the list.
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              </td>
              </tr>
          </tbody>
        </table>
          <div class=" mt-3" v-if="dailySummaryIsCompleted">
          Daily summary completed, you can not interact with this daily for today.
          </div>
      <div class="flex flex-row align-center justify-start p-2 pl-3 text-gray-400">
          {{getTrainingDaily.length}} out of {{getCurrentTraining.trainees_number}} 
          <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-4 w-4 inline" viewBox="0 0 20 20" fill="currentColor">
            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
          </svg>
          
          <button title="Press to start the daily summary." v-if="!dailySummaryIsCompleted"  class="bg-gray-700 hover:bg-gray-900 ml-auto text-white rounded-sm shadow-sm py-1 px-2" @click.prevent="$router.push({ name:`daily.attendance`})">
            Daily Summary
          </button>
      </div>
      </div>
    </div>
    <div class="flex flex-row align-center justify-center p-2" v-else>
      <loader class="mx-auto my-5" />
    </div>
  </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
import TrainingDetails from '../../components/misc/trainings/TrainingDetails.vue';
import TraineesSearchbar  from '@/views/recruitment/components/searchbars/DailyList'
import Loader from '@/views/mockups/MaterialLoader'
import permissions from '@/config/mixins'
import debounce from 'debounce'
import TrainingNotesDropdown from '@/views/recruitment/pages/trainings/components/TrainingNotesDropdown';
import TraineeNotesDropdown from '@/views/recruitment/pages/trainings/components/TraineeNotesDropdown';
import AttritionRiskModal from '@/views/recruitment/pages/trainings/modals/TraineeAttritionRiskModal';
export default {
  mixins:[permissions],
  components:{
    TrainingDetails,
    TraineesSearchbar,
    Loader,
    TrainingNotesDropdown,
    TraineeNotesDropdown,
    AttritionRiskModal
  },
  data(){
    return {
      searchbarKey:0,
      open:false,

      show_attrition_risk_modal: false,
      selected_trainee: null
    }
  },
  computed:{
      ...mapGetters(['getCurrentTraining','getTrainingStatus','getTrainingDaily','getTrainingEvents','getTrainingDailyStatus']),
      isActive:function(){
        return true;
      },

      dailySummaryIsCompleted () {
        return this.getTrainingDailyStatus ==='completed';
      }
  },
  methods:{
    ...mapActions(['fetchTrainingAttendance','deleteFromTraining','fetchTrainingById','linkTraineeToDaily','fetchDailyAssist','clockInTrainee','clockOutTrainee','fetchTrainingEvents','startEvent','checkTrainingStatus','sendTrainingNote']),

    addTrainee:function(trainee){
        if(this.getTrainingDaily.length >= this.getCurrentTraining.trainees_number){
          return this.$fire({
            title:'This training is full. You can not add more trainees to the list.', 
            type:'warning'
          });
        }
      this.$fire({
        title: 'Provide the trainee curp',
        html:'<input id="swal-input1" class="swal2-input uppercase">',
        focusConfirm: false,
        preConfirm: () =>    {
          let curp = document.getElementById('swal-input1').value;
          if(curp.toUpperCase() !== trainee.curp){
            
            return this.$fire({
              'type':'error',
              'title':'The provided curp doesnt match the trainee curp.'
            });
          }
          let dataset ={
              curp: trainee.curp,
              training_class_id: this.$route.params.id,
              trainee_id:trainee.id,
              trainee_type: trainee.trainee_type,
              lob: this.getCurrentTraining.lob,
              campaing: this.getCurrentTraining.campaign,
              department: this.getCurrentTraining.department,
              location: this.getCurrentTraining.location
          };
          this.linkTraineeToDaily(
            dataset
          ).then(()=>{            
              this.$fire({
                type:"success",
                title: 'The trainee has been successfully registered.',
                showConfirmButton: false,
                timer: 2500
              }).then(() => {
                this.searchbarKey++;
                this.fetchDailyAssist(this.$route.params.id);
              })
          }).catch((error)=>{
            let message = error.response.data.message;
            this.$fire({
                type:"error",
                title: 'Something went wrong. Error message: ' + message
              }).then(()=>{
                this.$router.push();
              });
          });
          }
      });
      
    },

    clockIn:async function(id){
      let {value:time} = await this.$fire({
        title: 'You are starting a shift. Select the clock-in time',
        html:'<input id="clockInTime" class="swal2-input" type="time" value="08:00">',
        focusConfirm: false,
        preConfirm: () => document.getElementById('clockInTime').value
      });
      if(time){
        this.clockInTrainee({
          empID:id,
          time: time
        }).then(()=>{
          this.$fire({
            'type':'success',
            'title':'Clock-in succesful'
          });
          this.fetchDailyAssist(this.$route.params.id);
        });
      }
    },

    clockOut:async function(id){
      let {value: confirmation} = await this.$fire({
        title: 'Do you want to clock-out this trainee?',
        showCancelButton: true,
        confirmButtonText: `Confirm`,
        denyButtonText: `Cancel`,
      });
      if(confirmation){
        this.clockOutTrainee(id).then(()=>{
          this.$fire({
            type:'success',
            'title':'Clocked out successfully.'
          });
          this.fetchDailyAssist(this.$route.params.id);
        });
      }
      
    },

    lauchActivity: async function(){
      this.open = false;
      let { value: event } = await this.$fire({
        title: 'Select the desired activity',
        input: 'select',
        inputOptions:this.getTrainingEvents,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to choose an event!'
          }
        }
      })
      if (event) {
        let form  ={
          event:this.getTrainingEvents[event],
          training_class_id:this.$route.params.id
        };
        this.startEvent(form).then(()=>{
          this.$fire({
            type:'success',
            title:'Event started successfully.'
          });
          location.reload();
        });
      }
    },

    showStepperComponent: debounce( async function(){
      const needsToClockOutTrainees = await this.needsToClockOutTrainees();
      if(needsToClockOutTrainees){
        return this.$fire({
          type:'warning',
          title:'Clock out every trainee before proceeding to fill the daily summary.'
        })
      }
      // this.showStepper = true;
    
    },750),
    
    needsToClockOutTrainees: function(){
      return this.checkTrainingStatus(
        this.$route.params.id
      ).then((res)=>{
          return res.data.status;
      });
    },

    removeFromTraining:async function(id){
      
      const {value} = await this.$fire({title:'Are you sure you want to remove this trainee from the list?',type:'info',showCancelButton:true, showConfirmButton:true});
        
      if(!value){
          return;
      }
      this.deleteFromTraining({training_id:this.$route.params.id, employee_id:id}).then(()=>{
          this.$fire('The trainee has been removed from list. Reloading page in 3s.').then(()=>{
              setTimeout(() => {
                this.$router.go(0)
              }, 3000);
          });
      });
      
    },

    showAttritionRiskModal (trainee) {
      this.selected_trainee = trainee;
      this.show_attrition_risk_modal = true;
    },

    attritionRiskHandler (data) {
      if (!data.confirmation) {
        this.show_attrition_risk_modal = false;
        return;
      }

      this.sendTrainingNote(data).then(() => {
        this.show_attrition_risk_modal = false;
        location.reload();
      })
    }
  },
  created(){
    this.fetchTrainingById(this.$route.params.id).then(()=>{
      let start = this.$moment(
          this.getCurrentTraining.start_date, 'YYYY/MM/DD'
      ).format('Y-MM-DD'),
      end = this.$moment(
        this.getCurrentTraining.end_date,'YYYY/MM/DD'
      ).format('Y-MM-DD'),
      now = this.$moment().format('Y-MM-DD')
      if(!this.$moment(now).isBetween(start, end,null,'[]')){
        this.$fire({
          'type':'warning',
          'title': "Oops, there's no access!",
          'text': "Seems this training is no longer available or the registry time is over."
        }).then(()=>{
            this.$router.push({name:'trainings.index'})
        });
      }
      this.fetchTrainingEvents({
        lob:this.getCurrentTraining.lob,
        campaign:this.getCurrentTraining.campaign
      });
    })
    .catch((error)=>{    
      let message = error.code == 404 ? 'Training not found' : error;       
      this.$fire({
        type: 'error',
        title:`Something went wrong. Error message: ${message}`,
        showConfirmButton: false,
        timer: 2500
      })  
    });  
    this.fetchDailyAssist(
      this.$route.params.id
    );
    this.$emit('set-title', `Trainings - Daily`);
  },
  beforeMount(){
    if(!this.isGranted('SystemAdministrator|Trainer')){
      this.$router.push({name:'trainings.index'});
    } 
  }
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>