<template>               
    <div>
        <div class="py-2 mx-1">
            <form class="border-b border-gray-200 rounded-sm" @submit.prevent="addHours">
                <div v-if="getTrainingStatus" class="w-full flex">
                    <div class="p-2 m-auto py-4">
                        Loading...  
                    </div>
                </div>

                <div v-else class="px-3 py-2 space-y-2">
                    <p class="text-center text-gray-500 space-x-2"><i class="bi bi-exclamation-circle-fill"></i>Only those who attended the training will have the selected schedule added to their records.</p>
                    <table class="light-table shadow-none drop-shadow-none overflow-hidden">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-5/12">
                                    Event
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-5/12">
                                    Duration Hours
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
                                    Category
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider w-1/12">
                                    act
                                </th>
                            </tr>
                        </thead>
                    
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-if="requestQuantity < 1">
                                <td colspan="4" class="p-2">
                                    <span class="m-auto text-gray-700 font-medium text-xs">
                                        No events registered yet
                                    </span>
                                </td>
                            </tr>
                            <tr class="text-center border-l-2 border-r-2" :id="index" v-for="(currentRow, index) in temporalTable" :key="index">
                                <td class="p-2 text-xs text-gray-700 w-48" v-if="!(currentRow.Status === 'canceled')">
                                    <select v-if="currentRow.Event === null" class="p-2 border-b-2 border-blue-300 w-full" v-model="temporalTable[index].Event" @change="updateEvent(index, $event)" required>
                                        <option :value="null" hidden>Choose an event...</option>
                                        <optgroup v-for="(elements, key, index) in filteredEvents" :key="index" :label="key" >
                                            <option v-for="(event,index) in elements" :billable="event.Billable" :payable="event.Payable" :value="event._break" :key="index" class="uppercase">
                                                {{event._break}}
                                            </option>
                                        </optgroup>
                                    </select>
                                    <span class="uppercase text-xs" v-else>{{currentRow.Event}}</span>
                                </td>
    
                                <td class="p-2 w-64" v-if="!(currentRow.Status === 'canceled')">
                                    <div>
                                        <span>
                                            <vue-timepicker :value="secondsToTime(temporalTable[index].Duration)" :class="{'text-gray-700' : !durationIsZero(index), 'text-red-500' : durationIsZero(index)}"
                                            class="outline-none rounded-lg shadow-md p-2 text-bold bg-white" 
                                            drop-direction="auto" lazy @change="updateDuration(index, $event)" :id="'timePicker'+[index]" 
                                            format="HH:mm" hide-clear-button close-on-complete></vue-timepicker>
                                        </span>
                                    </div>
                                </td>
                                <td class="p-2 text-center justify-content-center m-auto flex flex-col" v-if="!(currentRow.Status === 'canceled')">
                                    <span v-if="currentRow.Billable">
                                        <vs-tooltip position="left" title="Billable" text="Company can bill this time" class="flex text-center justify-content-center">
                                            <div class="uppercase font-semibold text-xs py-1 leading-tight inset-0 bg-opacity-50 rounded-full flex text-center justify-content-center w-16 mb-1"
                                            :class="{'text-green-900 bg-green-200' : currentRow.Billable === 'TRUE',
                                            'text-red-900 bg-red-200' : currentRow.Billable === 'FALSE'}">
                                                Bill
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </vs-tooltip>
                                    </span>
                                    <span v-else>
                                        <vs-tooltip position="left" title="Not Billable" text="Company can not bill the time spent on this activity" class="flex text-center justify-content-center">
                                            <div class="uppercase font-semibold text-xs py-1 leading-tight inset-0 bg-opacity-50 rounded-full flex text-center justify-content-center w-16 mb-1"
                                            :class="{'text-green-900 bg-green-200' : currentRow.Billable === 'TRUE',
                                            'text-red-900 bg-red-200' : currentRow.Billable === 'FALSE'}">
                                                Bill
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </vs-tooltip>
                                    </span>
    
                                    <span v-if="currentRow.Payable">
                                        <vs-tooltip position="left" title="Payable" text="Employee earns money during doing this activities" class="flex text-center justify-content-center">
                                            <div class="uppercase font-semibold text-xs py-1 leading-tight inset-0 bg-opacity-50 rounded-full flex text-center justify-content-center w-16 mb-1"
                                            :class="{'text-green-900 bg-green-200' : currentRow.Payable === 'TRUE',
                                            'text-red-900 bg-red-200' : currentRow.Payable === 'FALSE'}">
                                                Pay
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </vs-tooltip>
                                    </span>
                                    <span v-else>
                                        <vs-tooltip position="left" title="Not Payable" text="Employee does not get paid for this time" class="flex text-center justify-content-center">
                                            <div class="uppercase font-semibold text-xs py-1 leading-tight inset-0 bg-opacity-50 rounded-full flex text-center justify-content-center w-16 mb-1"
                                            :class="{'text-green-900 bg-green-200' : currentRow.Payable === 'TRUE',
                                            'text-red-900 bg-red-200' : currentRow.Payable === 'FALSE'}">
                                                Pay
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </vs-tooltip>
                                    </span>
                                </td>
                                <td class="p-2" v-if="!(currentRow.Status === 'canceled')">
                                    <vs-tooltip text="Discard event created" class="text-center justify-content-center flex" v-if="requestQuantity > 1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" @click="deleteRow(index)">
                                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                        </svg>
                                    </vs-tooltip >
                                </td>
                            </tr>
    
                            <tr class="text-center" colspan="4">
                                <td colspan="4" class="p-2 bg-gray-50 rounded-b-lg">
                                    <button class="flex flex-row mx-auto my-1" type="button" @click.prevent="addNewEvent()">
                                        <svg class="w-5 h-5 my-auto mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg>
                                        <span class="m-auto text-gray-700 font-medium uppercase text-xs">
                                            Add a new Event
                                        </span>
                                    </button>
                                </td>
                            </tr>
    
                            <tr class="text-center" colspan="4">
                                <td colspan="4" class="px-6 py-4">
                                    <div class="flex flex-col">
                                        <span class="text-xl font-sans font-semibold flex flex-row mx-auto" :class="{'text-red-500' : (remainingTime < 0)}">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 my-auto">
                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clip-rule="evenodd" />
                                            </svg>
                                            {{secondsToTime(remainingTime)}}
                                        </span>
                                        <span class="text-sm font-sans font-medium my-auto">
                                            Available Time
                                        </span>
                                    </div>
                                </td>
                            </tr>
    
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="px-6 py-4 whitespace-nowrap flex flex-row align-center justify-end">
                                    <button class="bg-blue-500 text-white px-3 py-1 rounded-sm whitespace-nowrap" type="submit" :disabled="remainingTime < 0">
                                        Save
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <!-- <div class="w-full bg-gray-50 flex flex-row items-center justify-end py-2">
                    <button class=" mr-2 px-3 py-1 hover:bg-gray-300 text-gray-400 rounded-sm" @click.prevent="$emit('previous')">
                        Prev
                    </button>
                    <button class=" mr-2 px-3 py-1 bg-gray-700 hover:bg-gray-900 text-white rounded-sm" @click.prevent="$emit('next-page')">
                        Next
                    </button>
                </div> -->
            </form>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
import VueTimepicker from 'vue2-timepicker';
import auth   from "@/config/user"
export default {
    components:{
        VueTimepicker
    },
    data(){
        return {
            staff:null,
            key:0,

            filteredEvents: [],
            existingEvents: [],
            temporalTable: [],
        }
    },
    computed:{
        ...mapGetters([
            'getTrainingHours',
            'getTrainingStatus', 
            'getCurrentTraining', 
            'getTrainingClockerEvents'
        ]),
        
        isLoading:function(){
            return this.getTrainingStatus ==='savingTrainingStaff' ; 
        },

        requestQuantity: function(){
            return this.temporalTable?.length;
        },

        remainingTime: function(){
            let shift = 0;
            let activities = 0;

            [].forEach.call(this.temporalTable, element =>{
                if(element.Status !== 'canceled'){
                    if(element.Event?.toLowerCase() === 'shift'){
                        shift = shift + element.Duration;
                    }else{
                        activities = activities + element.Duration;
                    }
                }
            })
            return (shift - activities);
        },

    },
    methods:{
        ...mapActions([
            'fetchTrainingHours',
            'saveTrainingHours', 
            'fetchTrainingClockerEvents',
        ]),

        addHours:function(){
            event.preventDefault();
            if(this.remainingTime < 0) return;
            
            let form = {
                training_id: this.$route.params.id,
                requests : this.temporalTable
            }
            this.saveTrainingHours(form).then(() =>{
                this.$fire({
                    type:'success',
                    title:'Hours Saved.'
                }).then(()=>{
                    this.renderComponent();
                })
            }).catch((error) => {
                console.log(error);
            })
            
        },

        // helpers

        timeToSeconds: function(t){
            let parts = t.split(':');
            let seconds = (parts[0] * 60 * 60) + (parts[1] * 60)
            if (seconds === null || seconds === 'null') seconds = 0;
            return seconds;
        },

        secondsToTime(seconds){
            if(isNaN(seconds)) return;
            let realSecs = seconds;
            let realSign = '';
            if(seconds < 0) {
                realSecs = 86400 - seconds;
                realSign = '-'; 
            }
            return realSign + new Date(realSecs * 1000).toISOString().substr(11, 5);
        },

        durationIsZero: function(i){
            return this.temporalTable[i]?.Duration === 0;
        },

        // end helpers

        filterEventsDropdown: function(){
            const result = Object.entries(this.getTrainingClockerEvents).map(([key, value]) => {
                let filtered_events = value.filter((event) =>
                    !this.existingEvents.includes(event._break.toLowerCase())
                )
                return {[0]: key, [1]: filtered_events}
            });
            let entries = Object.fromEntries(result);
            const newFilterd = Object.entries(entries).filter(([key]) => (!this.existingEvents.includes(key.toLowerCase())));
            this.filteredEvents = Object.fromEntries(newFilterd);
        },

        addNewEvent: function(){
            this.temporalTable.push({ 
                Training_id: this.$route.params.id,
                Site: this.getCurrentTraining.location,
                Event: null,
                Billable: '',
                Payable: '',
                EventDate: new Date().toISOString().slice(0, 10),
                Duration: 0,
                Status: 'pending',
                CreatedBy: auth.currentUser.EmpID,
                UpdatedBy: auth.currentUser.EmpID
            });
        },

        deleteRow: function(index){
            this.$confirm("Do you want to delete this event?", "Discard Event Creation", "question").then((result) => {
                if (result){         
                    if(this.temporalTable[index].Id){
                        this.temporalTable[index].Status = 'canceled';
                    }else{
                        this.temporalTable.splice(index, 1);
                    }
                    this.existingEvents.splice(index, 1);
                    this.filterEventsDropdown();
                }
            })
        },

        updateEvent: function(i, event){
            this.existingEvents.push(this.temporalTable[i].Event.toLowerCase());
            this.filterEventsDropdown();
            let billSign = event.target.selectedOptions[0].getAttribute("billable");
            let paySign  = event.target.selectedOptions[0].getAttribute("payable");
            this.temporalTable[i].Payable = paySign;
            this.temporalTable[i].Billable = billSign;
            this.temporalTable[i].Duration  = 0;
        },

        updateDuration: function(index, value){
            if(this.temporalTable[index].Event === null) return;
            let seconds = this.timeToSeconds(value.displayTime);
            this.temporalTable[index].Duration = seconds;
        },

        renderComponent: function(){
            this.fetchTrainingClockerEvents(this.$route.params.id).then(()=>{
                this.filterEventsDropdown();
            });
            this.fetchTrainingHours(this.$route.params.id).then(() =>{
                this.temporalTable = this.getTrainingHours;
                [].forEach.call(this.temporalTable, element => {
                    this.existingEvents.push(element.Event.toLowerCase());
                });
            }).catch((error) =>{
                console.log(error)
            });
        },
    },
    created(){
        this.renderComponent();
    }
}
</script>