<template>
    <div class="basis-1/3">
        <div class="bg-gradient-to-b from-cyan-500 to-blue-700 text-white shadow-sm rounded-[10px] px-8 py-4">
        <i class="text-[250%] leading-none opacity-40 bi " :class="iconName"></i>
        <div class="mt-2">
            <h6 class="mb-0"> {{indicatorName}}</h6>
            <h2 class="text-3xl tracking-tighter mb-0">{{indicatorValue}}</h2>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
      indicatorName: {
        type: String,
        required: true
      },
      indicatorValue:{
        type: String,
        required: true
      },
      iconName:{
        type: String, 
        required: true,
      }
    }
}
</script>
<style scoped>

</style>