<template>
  <div class="container-fluid">
    <!-- Payroll Adjustments & Corrections, correction moda, /payroll/watchmen/adjustmentRequests/adjustmentsPayroll -->
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Payroll Correction</h3>
        </div>
      </div>
    </div>
    <div>
      <loading :active.sync="isLoaderActive" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
      <div class="row">
        <div class="col text-center">
          <label for="exampleFormControlSelect1">Event to Report:</label>
          <select :disabled="disableEvent" class="custom-select d-block w-100" v-model="eventSelected">
            <option hidden :value="null">Choose an event...</option>
            <optgroup v-for="(elements, key, index) in getAllEventsPerCampaign" :key="index" :label="key" >
                <option v-for="(event,index) in elements" :value="event._break" :key="index">{{event._break}}</option>
            </optgroup>
          </select>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col">
            <b-progress height="2rem" :precision="2" class="mt-2" :max="maxDuration" show-value>
              <b-progress-bar :value="this.newTotalTime" variant="warning">
                <b style="color:black">Total Duration: {{this.newTotalTime}} Hours</b>
              </b-progress-bar>
            </b-progress>
          </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col">
                <label>Standard Time</label>
                <vue-timepicker v-if="!timerDisabled"  v-model="newStdTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                <vue-timepicker v-else disabled v-model="newStdTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
            <div class="col">
                <label>Graveyard Time</label>
                <vue-timepicker  v-if="!timerDisabled" v-model="newGrvTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
                <vue-timepicker v-else disabled v-model="newGrvTime" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
            </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <b-form-textarea :disabled="disableComment" v-model="eventComment" placeholder="Please add a comment..." rows="3" max-rows="3" ></b-form-textarea>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <button type="button" @click="calculateTime" class="bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded">Calculate</button>
          </div>
          <div class="col">
            <button
              v-if="canFix"
              type="button"
              @click="fixTime"
              class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded"
            >Fix Time</button>
            <button v-else disabled type="button" class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded">Fix Time</button>
          </div>
          <div class="col">
            <button type="button" @click="clearTime" class="bg-gray-200 px-6 py-2 rounded">Clear</button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              v-if="canSave"
              type="button"
              @click="saveTime"
              class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded"
            >Save</button>
            <button v-else type="button" disabled class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="col">
            <button type="button" class="bg-gray-200 px-6 py-2 rounded">Cancel</button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueTimepicker from "vue2-timepicker";
// import moment from "moment";
import Loading          from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: { VueTimepicker,Loading },
  data() {
    return {
      isLoading: true,
      maxDuration:    null,
      newStdTime:     null,
      newGrvTime:     null,
      newTotalTime:   0,
      newStandard:    0,
      newGraveyard:   0,
      newDuration:    0,
      eventComment:   null,
      canSave:        false,
      timerDisabled:  false,
      canFix:         false,
      eventSelected:  null,
      disableEvent:   false,
      disableComment: false,
      //Loader Data
      isLoaderActive: false,
      fullPage: true,
      color: "#F2F2F2",
      bgcolor: "#000000",
      loader: 'dots',

      //No Negative shifts
      shiftStdTime: 0,
      shiftGrvTime: 0,
      activitiesStdTime: 0,
      activitiesGrvTime: 0
    };
  },
  props: {
    EmployeeID: String,
    eventDate: String,
  },
  computed: {
    ...mapState(["clockerUsers", "payroll"]),
    ...mapGetters(["getInfoByApprovalID", "getAllEventsPerCampaign"]),
  },
  created(){
    this.fetchAllEvents(this.EmployeeID).then(() =>{
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions(["savePayrollCorrection", "fetchAllEvents", "shiftValidation"]),
    saveTime: function () {
      this.isLoaderActive = true;
      let dataObj = {
        EmpID:          this.EmployeeID,
        eventDate:      this.eventDate,
        eventName:      this.eventSelected,
        newStdTime:     this.newStandard,
        newGrvTime:     this.newGraveyard,
        eventDuration:  this.newDuration,
        eventComment:   this.eventComment,
      };
      this.savePayrollCorrection(dataObj).then((res)=>{
        this.isLoaderActive = false;
        if (res.status == 200){
            this.$fire({
                type: 'success',
                title: 'The correction has been created successfully',
                showConfirmButton: false,
                timer: 1500
              }).then(()=>{ 
                this.$emit('closeModal', true);
                  // this.$router.go(0);                 
              }); 
          }
      });
    },
    calculateTime: function () {
      // To Create a moment object only for time we need to add a fake Date
      var newStandard     = (this.newStdTime.HH*60) + (this.newStdTime.mm*1);
      var newGraveyard    = (this.newGrvTime.HH*60) + (this.newGrvTime.mm*1);
      var mins = newGraveyard + newStandard;
      let useEvent = this.eventSelected;
      let useComment = this.eventComment;

      if (useEvent == null){
          this.$fire({
          type: "error",
          title: "Oops! Select a valid event",
          showConfirmButton: true,
        });
      }
      else{
        if(useComment == null || useComment == '') {
          this.$fire({
          type: "error",
          title: "Comment is mandatory",
          showConfirmButton: true,
        });
      }else{
        // Here we're checking if the shift is not less than the other events
        if(this.eventSelected.toLowerCase() == 'shift'){
          this.shiftStdTime = newStandard * 60;
          this.shiftGrvTime = newGraveyard * 60;
        }else{
          this.activitiesStdTime = newStandard * 60;
          this.activitiesGrvTime = newGraveyard * 60;
        }
        let dataObj = {
        shiftStdTime:       this.shiftStdTime,
        shiftGrvTime:       this.shiftGrvTime,
        activitiesStdTime:  this.activitiesStdTime,
        activitiesGrvTime:  this.activitiesGrvTime,
        EmployeeID:         this.EmployeeID,
        eventDate:          this.eventDate,
        rec_id:             '0',
        eventSelected:      this.eventSelected
        };
        this.isLoaderActive = true;
        this.shiftValidation(dataObj).then((res)=>{
          this.isLoaderActive = false;
          if(res.data.msg[0].total >= 0 && (res.data.msg[0].Standard < 0 || res.data.msg[0].Graveyard < 0)){
                this.$fire({
                type: 'warning',
                title: 'Oops! Standard and Graveyard time do not match',
                text: 'Please review the available time in Standard and Graveyard',
                showConfirmButton: true
                }).then(()=>{
                  this.activitiesStdTime = 0;
                  this.activitiesGrvTime = 0;
                  this.shiftStdTime = 0;
                  this.shiftGrvTime = 0;
                  this.canFix = false;   
                })
          }else{
              if(res.data.msg[0].total < 0){
                  this.$fire({
                  type: 'warning',
                  title: 'Oops! The activities are bigger than the shift',
                  text: 'Please make sure to reduce the activities, or increase the shift first',
                  showConfirmButton: true
                  }).then(()=>{
                    this.activitiesStdTime = 0;
                    this.activitiesGrvTime = 0;
                    this.shiftStdTime = 0;
                    this.shiftGrvTime = 0;
                    this.canFix = false;   
                  })
              }else{
                this.activitiesStdTime = 0;
                this.activitiesGrvTime = 0;
                this.shiftStdTime = 0;
                this.shiftGrvTime = 0;
                this.canFix = true;   
              }
          }
        }).catch(() => {
          this.isLoaderActive = false;
            this.$fire({
              type: 'warning',
              title: 'Duplicated Event',
              text: 'Seems you are trying to add an existing event, try to adjust that event instead!',
              showConfirmButton: true
            })
        })
        
        this.newTotalTime   = Math.round(mins / 60 * 100) / 100; // total value of new event
        this.maxDuration    = this.newTotalTime;
        this.newDuration    = mins * 60;
        this.newStandard    = newStandard * 60;
        this.newGraveyard   = newGraveyard * 60;
          }
        }
    },
    fixTime: function () {
      this.timerDisabled = true;
      this.canSave = true;
      this.disableEvent = true;
      this.disableComment = true;
    },
    clearTime: function () {
      this.newStdTime = { HH: "", mm: "" };
      this.newGrvTime = { HH: "", mm: "" };
      this.newTotalTime = null;
      this.timerDisabled = false;
      this.canSave = false;
      this.disableEvent = false;
      this.disableComment = false;
      this.eventComment = null;
    },
  },
};
</script>
<style lang="scss">
</style>