<template>
    <div class="todayActivity px-0 py-8">
        <h1 class="text-4xl font-thin mb-3 text-center">Start your Shift</h1>
        <div v-if="is_loading" class="text-center">
            <!-- Do not delete, something on app.vue -->
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
        <div v-else>
            <div class="absolute -bottom-8 -left-6 text-[1200%] leading-none text-gray-100 z-0">
                <i class="bi bi-house-door" v-if="this.Where=='HOME'"></i>
                <i class="bi bi-building leading-[1.1]" v-else></i>
            </div>
            <div class="relative text-center">
                <h5 class="text-xl font-normal mb-3">You will start your shift from:</h5>
                <h1 class="text-6xl font-normal my-6 text-c-primary">{{this.Where}}</h1>
                <div class="mt-2">
                    <input v-model="home_switch" type="checkbox" class="checkbox-custom mr-2" id="customSwitch1" @change="changeLocation()">
                    <label v-if="home_switch" class="m-0" for="customSwitch1">I am working from HOME.</label>
                    <label v-else class="m-0" for="customSwitch1">I am working from SITE.</label>
                </div>
                <label v-if="home_switch" for="customSwitch1">
                    <small class="text-muted"><i>*Use the checkbox if you're working from site</i></small>
                </label>
                <label v-else for="customSwitch1">
                    <small class="text-muted">
                        <i>*Use the checkbox if you're working from home</i>
                    </small>
                </label>

                <div class="text-center mt-3">
                    <button v-if="home_switch == null" disabled type="button" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">
                        <i class="bi bi-stopwatch"></i> Clock In
                    </button>
                    <button v-else type="button" @click="startShift" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">
                        <i class="bi bi-stopwatch"></i> Clock In
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ClientJS }         from 'clientjs';
import {mapGetters,mapState, mapActions } from 'vuex'
import auth   from "@/config/user"
export default {
    data(){
        return{
            is_loading: true,
            employee_location: null,
            home_switch: false,
            Where: 'SITE'
        }
    },
    computed:{
        ...mapState([
            'clockerUtility', 'employees'
        ]),
        ...mapGetters([
            'infoEmployee','getIP'
        ])
    },
    methods:{
        ...mapActions([
            'clockInAgent', 
            'loadMyProfileByEmpId',
            'getIPFromClient'
        ]),
        startShift: function(){
            var client = new ClientJS();
            let objectData = {
                EmpID:    auth.currentUser.EmpID.toUpperCase(),
                Location: this.employee_location,
                ClientIP: this.getIP,
                ActionTriggered: 'ClockIn',
                UserAgent: client.getUserAgent(),
                DeviceOS: client.getOS(),
                DeviceFingerprint: client.getFingerprint(),
            };
            this.is_loading = true;

            if(this.employee_location == null){
                this.is_loading = false;
                return;
            }

            this.clockInAgent(objectData).then(()=>{
                this.$fire({
                    type: "success",
                    title: "A shift has started",
                    showConfirmButton: false,
                    timer: 1300,
                }).then(()=>{
                    this.$emit('closeClockInModal', true);
                    this.is_loading = false;
                });
            }).catch((error)=>{
                this.is_loading = false;
                this.$fire({
                    type: 'warning',
                    title:`Something went wrong`,
                    text: `Details: ${error.response.data.message ?? 'Unexpected error'}`,
                    showConfirmButton: true,                
                }).then(()=>{
                    this.$emit('closeClockInModal', true);
                    this.is_loading = false;
                });
            })
        },

        loadInfoEmployee(){
            let idEmployee = auth.currentUser.EmpID;
            this.loadMyProfileByEmpId(idEmployee).then(() => {
                this.employee_location = this.infoEmployee.Location;
                this.is_loading = false;
            }, error => {
                console.error("Error fetching user: "+ error)
            });
        },

        changeLocation(){
            if(this.home_switch){
                this.employee_location = this.infoEmployee.Location+'-HOME';
                this.Where = 'HOME';
            }else{
                this.employee_location = this.infoEmployee.Location;
                this.Where = 'SITE';
            }
        }
    },
    created() {
        this.getIPFromClient();
        this.loadInfoEmployee()
    }
}
</script>
<style scoped>
#todayActivities___BV_modal_outer_{
    overflow: hidden;
    z-index: 20001 !important; 
}
.checkbox-custom {
    width: 20px;
    height: 20px;
    color: dodgerblue;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #CCD3D8;
    transition: background 300ms;
    cursor: pointer;
}
.checkbox-custom::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
}
.checkbox-custom:checked::before {
    box-shadow: none;
    background-color: dodgerblue;
}
</style>
