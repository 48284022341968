<template>
<div>
  <div id="EditTable" class="userTable">
    <loading :active.sync="isLoaderActive" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
    <b-container fluid class="TableSearch">
        <b-row>
            <b-col>
                <label class="mr-2"  for="inline-form-custom-select-pref">Search Employee:</label>
                <b-input-group v-if="!displayLoadingEmployee">
                    <b-form-input v-if="!agentExist"  v-model="employeeID" @keyup.enter="filterSearch" placeholder="Search by Employee ID" id='filterKeyword' type="text"></b-form-input>
                </b-input-group>
                <div v-if="!agentExist">
                    <div v-if="!displayLoadingEmployee">
                        <label>Please, type an user, press "Enter" to search</label>
                    </div>
                    <div v-if="displayLoadingEmployee" class="mt-3">
                        <div class="d-flex align-items-center ">
                            <b-spinner class="ml-5"></b-spinner><strong class="ml-2">Searching Employee...</strong>
                        </div>
                    </div>
                </div>
                <div v-if="agentExist">
                    <label><strong>Employee Selected:</strong> {{ this. employeeID}}</label>
                </div>
            </b-col>
            <b-col align-self="center">
                <label class="mr-2"  for="inline-form-custom-select-pref">Event Date:</label>
                <b-form-datepicker v-if="agentExist" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :max='max' today-button reset-button close-button v-model="dateFilter" @context="getAgentData" class="mb-2"></b-form-datepicker>
                <b-form-datepicker v-else size="sm" disabled></b-form-datepicker>
                 <div v-if="!agentExist">
                     <label>Search an Employee First</label>
                 </div>
            </b-col>
        </b-row>
        <b-row class="ml-3 mt-3 mb-3">
            <b-col align-self="center" cols="6">
                <button @click="clearFilters" class="bg-c-primary text-white px-6 py-2 rounded">Clear Filters</button>
            </b-col>   
             <b-col v-if="employeeLoaded">
                <button class="bg-slate-600 hover:bg-slate-700 text-white px-6 py-2 rounded" v-b-modal="'newEventModal'" >Correction</button>
                <b-modal :id="'newEventModal'" ref="newEvent" hide-footer hide-header>
                  <div>
                    <newEventModal @closeModal="closeModal" :EmployeeID="this.employeeID" :eventDate="this.dateFilter"></newEventModal> 
                  </div>
                </b-modal>
            </b-col>         
        </b-row>
        <b-row v-if="employeeLoaded">
            <b-col align-self="center" class="mt-2 rounded shadow-sm" md="12" lg="12" xl="12">
            <table class="table-sm table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="text-center" scope="col">Activity</th>
                        <th class="text-center" scope="col">Duration</th>
                        <th class="text-center" scope="col">Start Time</th>
                        <th class="text-center" scope="col">End Time</th>
                        <th class="text-center" scope="col">After Adjustment</th>
                        <th class="text-center" scope="col">Comment</th>
                        <th class="text-center" scope="col">Updated At</th>
                        <th class="text-center" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody v-if="getAgentInfo.length">
                <tr :id="index" v-for="(currentRow, index) in getAgentInfo" :key="index">
                    <td class="text-capitalize">{{currentRow.Event}}</td>
                    <td class="text-capitalize">{{transformDuration(currentRow.Event_Duration)}}</td>
                    <td class="text-capitalize">{{currentRow.Event_start_time | moment("YYYY-MM-DD HH:mm:ss")}}</td>
                    <td class="text-capitalize">{{currentRow.Event_end_time ? (currentRow.Event_end_time) : 'ON GOING'}}</td>
                    <td class="text-capitalize">{{calculateNewDuration(currentRow.Event_Duration,currentRow.Adjustment)}}</td>
                    <td class="text-capitalize">{{currentRow.Comment ? currentRow.Comment : 'No Comment'}}</td>
                    <td class="text-capitalize">{{currentRow.UpdatedAt ? currentRow.UpdatedAt : 'ON GOING'}}</td>
                    <td class="text-capitalize">
                        <div>
                            <button :disabled="!currentRow.Event_end_time" :id="'correctionRequest'+index" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded w-full" v-b-modal="'correctionRequest'+index">Adjustment</button>
                            <b-modal  :id="'correctionRequest'+index" hide-footer hide-header>
                                <div>
                                    <correctionModal :id="'correctionRequest'+index" :dataEvent="currentRow"></correctionModal> 
                                </div>
                            </b-modal>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="7">No records found.</td>
                </tr>
                </tbody>
            </table>
            </b-col>
        </b-row>
    </b-container>
  </div>
</div>
</template>
<script>
  import {mapGetters,mapState, mapActions } from 'vuex'
  import Loading          from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import correctionModal  from '@/views/components/Clocker/modals/correctionModal';
  import newEventModal    from '@/views/components/Clocker/modals/newEventModal';
  
  export default {
    data() {
      // const now = new Date()
      // const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // const minDate = new Date()
      // minDate.setDate(today.getDate()-15)
      return {

        isLoaderActive: false,
        fullPage: true,
        color: "#F2F2F2",
        bgcolor: "#000000",
        loader: 'dots',

        employeeID: null,
        employeeLoaded: false,
        displayLoadingEmployee: false,
        employeeFound: false,
        dateFilter: null,
        agentExist: false,
        // min: minDate,
        max: new Date()
      }
    },
    components: { Loading, correctionModal, newEventModal },
    computed:{
      ...mapState([
          'clockerUsers',
        ]),
      ...mapGetters([
          'employeeList',
          'getAgentExist',
          'getAgentInfo',
        ])
    },
    methods:{
      ...mapActions([
          'fetchAgentEventsPerEmpID',
          'isValidAgent']),
      closeModal(){
        this.$refs['newEvent'].hide();
        this.getAgentData();
      },
      filterSearch() {
          this.displayLoadingEmployee = true;
          this.isValidAgent(this.employeeID).then(()=>{
              this.displayLoadingEmployee = false;
              this.agentExist = true;
              if(this.getAgentExist == false){
                this.agentExist = false;
                this.$fire({
                    type: "error",
                    title: "Oops! We did not found the Employee, try again",
                    showConfirmButton: true,
                });
              }
          } );
      },
      transformDuration: function(Event_Duration){

        let hrs = parseInt(Math.floor(Event_Duration/3600));
        let mins = parseInt(Math.floor((Event_Duration - (hrs * 3600))/60));
        let secs = parseInt(Math.floor(Event_Duration % 60));

        let dHours = (hrs > 9 ? hrs : '0' + hrs);
        let dMins = (mins > 9 ? mins : '0' + mins);
        let dSecs = (secs > 9 ? secs : '0' + secs);

        return dHours + ":" + dMins + ":" + dSecs;
      
      },
      calculateNewDuration: function(Event_Duration, Adjustment){

        let adjusted = (Event_Duration + Adjustment);
        let hrs = parseInt(Math.floor(adjusted/3600));
        let mins = parseInt(Math.floor((adjusted - (hrs * 3600))/60));
        let secs = parseInt(Math.floor(adjusted % 60));

        let dHours = (hrs > 9 ? hrs : '0' + hrs);
        let dMins = (mins > 9 ? mins : '0' + mins);
        let dSecs = (secs > 9 ? secs : '0' + secs);

        return dHours + ":" + dMins + ":" + dSecs;
      
      },
      getAgentData: function(){
        if(this.dateFilter !== null){
          this.isLoaderActive = true;
          let eventInfo = {
            EmpID: this.employeeID,
            date: this.dateFilter
          }
          this.fetchAgentEventsPerEmpID(eventInfo).then( ()=>{
              this.employeeLoaded = true;
              this.isLoaderActive = false;
          });
        }
      },
      clearFilters(){
          this.employeeID               = null;
          this.employeeLoaded           = false;
          this.displayLoadingEmployee   = false;
          this.employeeFound            = false;
          this.dateFilter               = null;
          this.agentExist               = false;
      },
    },
  }

</script>
<style lang="scss" scoped>
  .EditTable{
    display:none;
  }
  .md-field {
    max-width: 300px;
  }
  .TableSearch{
    margin: 2em 0 0 0!important;
  }
  .material-icons.viewIcon {
    font-size: 2em;
    cursor: pointer;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: no-drop;
  }
</style>